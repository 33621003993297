import logoNasscomMinistry from 'assets/images/pg-diploma/logo-nasscom-ministry-removebg.png'
import daFresher from 'assets/images/svgs/userWithBg.svg'
import seniorDA from 'assets/images/svgs/presentation.svg'
import daIV from 'assets/images/svgs/searchAnalyst.svg'


const bannerRecordGurgaon = {
  courseID: 66,
  name: 'Data Science 360 Course',
  bannerType: null,
  robotImage: 'image/course/thumbnail-1616501509.svg',
  courseDetailsImage: 'image/course/thumbnail-1616501509-1660822396508.svg',
  introURL: '',
  bannerFileName: 'image/course/bg-banner-1644823150421.png',
  hoursCount: '500',
  classCount: '46',
}

const toolsList =[
  {
    "id": 1,
    "title": "Excel",
    "link": null,
    "largeImage": "image/toolsImage/excel-1601020530983.png"

  },
  {
    "id": 2,
    "title": "PowerBi",
    "link": null,
    "largeImage": "image/toolsImage/PowerBI-1662105782661.jpg"

  },
  {
    "id": 3,
    "title": "SQL",
    "link": null,
    "largeImage": "image/toolsImage/sql-1601021568250.png"

  },
  {
    "id": 4,
    "title": "Python",
    "link": null,
    "largeImage": "image/toolsImage/tool-lg1-1596020742035.png"

  },
  {
    "id": 5,
    "title": "Jupyter Notebook",
    "link": null,
    "largeImage": "image/toolsImage/1200px-Jupyter_logo-1707735380727.png"

  },
  {
    "id": 6,
    "title": "GIT",
    "link": null,
    "largeImage": "image/toolsImage/Git-logo-1707482493040.png"

  },
  {
    "id": 7,
    "title": "Statsmodel",
    "link": null,
    "largeImage": "image/toolsImage/images-1707482464267.png"

  },
  {
    "id": 8,
    "title": "Seaborn",
    "link": null,
    "largeImage": "image/toolsImage/Seaborn-1601022428840.png"

  },
  {
    "id": 9,
    "title": "Pandas",
    "link": null,
    "largeImage": "image/toolsImage/pandas-1601020776154.jpg"

  },
  {
    "id": 10,
    "title": "NumPy",
    "link": null,
    "largeImage": "image/toolsImage/asset2tools-1595857644274.png"

  },
  {
    "id": 11,
    "title": "Matplotlib",
    "link": null,
    "largeImage": "image/toolsImage/Matplotlib-1601031916549.png"

  },
]

const faqsGurgaon = {
  faqTitle: 'FAQs',
  faqDescription: null,
  faqsData: [
    {
      FaqID: 1,
      title: 'What are the prerequisites for the course?',
      description:
        `<p>Our data analytics courses in Gurgaon don't require in-depth pre-examination of concepts. A foundational understanding of mathematics, statistics, and some critical thinking is sufficient; our experts will assist you in mastering all the necessary concepts. </p><p>Core requirements include: </p><ul class="pg-lg-ul-r-20"><li><p>Strong analytical thinking and problem-solving skills are crucial for success in data analysis. This field involves identifying patterns, analyzing information, and drawing meaningful conclusions from data.</p></li><li><p>Curiosity and Passion: Curiosity and a genuine interest in learning are invaluable assets as you embark on your data analytics journey. A passion for exploring data and uncovering insights will fuel your motivation and perseverance.</p></li></ul><p>To kickstart your preparation, you can consult the article provided below:</p><ul class="pg-lg-ul-r-20">
        <li><p><a class="ct-lg-linkUpdate" target="_blank" href="https://www.analytixlabs.co.in/blog/data-analytics-application/">Data Analytics: Applications</a></p></li>
        <li><p><a class="ct-lg-linkUpdate" target="_blank" href="https://www.analytixlabs.co.in/blog/data-analytics-project-for-students/">Top 10 Data Analytics Projects in 2024</a></p></li>
        <li><p><a class="ct-lg-linkUpdate" target="_blank" href="https://www.analytixlabs.co.in/blog/career-in-data-analytics/">Data Analyst Career Guide 2024</a></p></li>
        </ul>`,
    },
    {
      FaqID: 2,
      title: 'What topics are covered in the course?',
      description:
      `<p>With a duration of 5 months, our data analyst course in Gurgaon module will contain the following topics:</p>
      <ul class="pg-lg-ul-r-20">
          <li>
              <p><b>Foundations of Mathematics &amp; Statistics</b></p>
              <ul>
                  <li>
                      <p>Master key mathematical and statistical concepts for data analysis.</p>
                  </li>
                  <li>
                      <p>Develop the skills of probability, hypothesis testing, and regression analysis techniques.</p>
                  </li>
              </ul>
          </li>

          <li>
              <p><b>Fundamentals of Programming</b></p>
              <ul>
                  <li>
                      <p>Acquire proficiency in programming languages like Python, R, or SQL to manipulate and analyze data.</p>
                  </li>
                  <li>
                      <p>Learn programming fundamentals such as variables, loops, functions, and conditional statements.</p>
                  </li>
              </ul>
          </li>

          <li>
              <p><b>Business Intelligence, Analytics &amp; Data Visualization</b></p>
              <ul>
                  <li>
                      <p>Explore tools and techniques for transforming raw data into actionable insights.</p>
                  </li>
                  <li>
                      <p>Gain expertise in data visualization tools like Tableau &amp; Power BI to create compelling visualizations that facilitate decision-making.</p>
                  </li>
              </ul>
          </li>

          <li>
              <p><b>Exploratory Data Analysis &amp; Data Management</b></p>
              <ul>
                  <li>
                      <p>Develop data cleaning, preprocessing, and transformation skills to ensure data quality and usability for analysis.</p>
                  </li>
                  <li>
                      <p>Identify patterns and anomalies to extract meaningful insights.</p>
                  </li>
              </ul>
          </li>

          <li>
              <p><b>Python for Analytics</b></p>
              <ul>
                  <li>
                      <p>Gain expertise in Python libraries like Pandas, NumPy, and Matplotlib.</p>
                  </li>
                  <li>
                      <p>Master Python programming for data analysis and manipulation tasks.</p>
                  </li>
              </ul>
          </li>

          <li>
              <p><b>Data Analytics in Practice</b></p>
              <ul>
                  <li>
                      <p>Apply analytical skills to real-world projects across industries.</p>
                  </li>
                  <li>
                      <p>Learn to collaborate with peers to solve complex analytical problems and deliver stakeholder insights.</p>
                  </li>
              </ul>
          </li>
      </ul>`,
    },
    {
      FaqID: 3,
      title: 'Does the course offer different levels (beginner, intermediate, advanced)?',
      description:
        `<p>The Data Analytics Certification Course in Gurgaon by AnalytixLabs has been thoughtfully designed for beginners. This thorough program ensures a solid grasp of fundamental concepts, provides hands-on experience and offers in-depth insights. <p></p>
        Emphasizing practical applications, the course is organized to equip you with a strong understanding of data analytics, making it an ideal entry point for individuals new to the field. We've carefully managed the difficulty level to ensure a smooth and accessible learning journey for all participants. As you progress through each subsequent module, you can anticipate a gradual increase in technical complexity, accompanied by a wealth of valuable skills to enhance your proficiency.
        </p>`,
    },
    {
      FaqID: 4,
      title: 'Is there a focus on specific tools and technologies (e.g., Python, R, SQL)?',
      description:
        `<p>The data analytics certification course in Gurgaon encompasses an extensive array of tools and techniques, providing a comprehensive learning experience. Throughout the program, you will explore hands-on skills involving a diverse range of industry-specific and in-demand tools. These include but are not limited to Excel, Python, SQL, R, Tableau, Jupyter Notebook, Panda, Matplotlib, and others. </p><p>
        The inclusion of these tools ensures a well-rounded and practical understanding of the data analytics landscape, empowering you with a versatile skill set that is highly valued in today's dynamic and competitive professional environment.
        </p>`,
    },
    {
      FaqID: 5,
      title: 'Do you provide course materials and assignments?',
      description:
        `<p>Certainly, learners enrolled in our program will receive meticulously curated, in-depth class notes crafted by industry experts. In addition to these valuable resources, we provide assignments directly relevant to current industry trends and practices. </p><p>
        These assignments allow students to gain hands-on experience with real-world scenarios they may encounter in their future roles. By aligning coursework with industry developments, we ensure our students are well-prepared to tackle challenges and excel in their professional endeavors upon program completion.
        </p>`,
    },
    {
      FaqID: 6,
      title: 'Is there a project component to the course?',
      description:
        `<p>Yes, our data analytics course in Gurgaon strongly emphasizes acquiring knowledge through practical means. Notable among these projects are the Data Analytics End-to-End Pipeline, Business Requirement Gathering—problem-solving, and Documentation Practices.</p><p>
        These capstone projects are designed to provide students with a comprehensive understanding of real-world scenarios and challenges they might encounter in their professional journeys. By engaging in these practical exercises, students enhance their technical skills and develop problem-solving acumen, ensuring they are well-prepared for the dynamic demands of the data analytics field.
        </p>`,
    },
    {
      FaqID: 7,
      title: 'Is there a certificate of completion offered upon finishing the course?',
      description:
        `<p>AnalytixLabs provides certification upon completing a thorough evaluation process that integrates mandatory case studies, assignments, multiple-choice questions (MCQs), and viva assessments into the course curriculum.</p><p>
        It is imperative to complete the certification within one year of course registration. This requirement ensures a consistent and steady progression in learning and allows participants to demonstrate proficiency in the acquired skills and knowledge within the specified timeframe.
        </p>`,
    },
    {
      FaqID: 8,
      title: 'What are the career opportunities for data analysts after completing the course?',
      description:
        `<p>After completing our data analytics certification course with placement in Gurgaon, you can explore multiple roles, such as data architect, business analyst, and data analyst. Career options are not limited to the above roles. The industry demands continuous evolution, merging more than one role into a new one with more widespread applicability in business. Focus on learning and mastering the core data analytics concepts to prepare yourself for any role the market demands. </p><p>
        Our placement team remains relevant to industry shifts, and our instructors will train you for placement, resume building, profiling, and interviews.
        </p>`,
    },
    {
      FaqID: 9,
      title: 'Is data analytics and data science the same?',
      description:
        `<p>Although they may overlap at certain junctures, they are broadly two concepts. The difference between data science and data analytics is slightly difficult to discern as the two seem similar from a layman’s perspective. </p><p>
        The simplest explanation for data science vs. data analytics is that the former is a bigger picture and covers a large field that mines any massive data pool.</p><p>
        Conversely, the latter is a more subject-focused concept that could be considered part of the bigger picture, that is, data science.</p><p>
        To understand how these two are different (and similar), read our blog on <a class="ct-lg-link-blockquote" href="https://www.analytixlabs.co.in/blog/difference-between-data-science-and-data-analytics/" target="_blank">Data Analytics vs. Data Science.</a> </p>`,
    },
    {
      FaqID: 10,
      title: 'Are business analytics and data analytics the same?',
      description:
        `<p>Business and data analytics often overlap, prompting many to consider these two synonymous. However, the two have differences that become more evident when considering practical implementations. </p><p>
        Data analytics refers to analyzing databases to determine their data content. Data analytical techniques allow you to take raw data and discover patterns to derive useful insights.</p><p>
        Business Analytics is the real-time application of statistical analysis that provides actionable advice. Analysts concentrate on implementing the information they obtain from data in this area.</p><p>
        Business Analytics vs. Data Analytics involves multiple factors, such as requirements, operation type, attached project, and data. They have separate or complementary applications in individual fields of operation and fundamental requirements for specific data management purposes. Read our <a class="ct-lg-linkUpdate" target="_blank" href="https://www.analytixlabs.co.in/blog/business-analytics-vs-data-analytics/">Data Analytics vs. Business Analytics</a> blog to learn the differences and overlaps.
        </p>`,
    },
  ],
}

const toolsRecordGurgaon = [
  {
    title: 'Industry-Aligned<br/>Bootcamp Certification',
    description:
      'Aligned with Industry Standards and Designed for practical, job-oriented learning. Cost-effective program with high ROI, making it worth every penny you invest.',
  },
  {
    title: 'For Technical &<br/>Non-technical Candidates',
    description:
      'Industry-first approach to maximize the job prospects for beginner-to-mid career candidates from a technical or non-technical background.',
  },
  {
    title: 'Classroom & Online<br/>Blended',
    description:
      'Choose from 100% online or classroom-blended, hands-on learning with extensive student support and mentorship by seasoned industry experts.',
  },
]

const courseDetailsGurgaon = {
  courseFullName: 'DATA ANALYST COURSE IN GURGAON',
  slug: 'data-analyst-training-course-in-Gurgaon',
  shortDescription: 'Certified Data Analytics Course with Placements',
  highHighRegulatory: {
    title: 'Learn high in-demand job skills',
    images: [
      {
        imageUrl: logoNasscomMinistry,
        height: '90%',
        width: '90%',
        col: '4',
      },
    ],
  },
  hoursCount: '500',
  classCount: '46',
  classHours: '+ 74 hrs e-learning',
  projectCount: '18',
  ratingValue: 4.73,
  bestRating: 5,
  worstRating: 2,
  ratingCount: 4860,
  metaTitle: 'Data Analyst Course in Gurgaon : Launch your Data Analytics Career',
  metaDescription:
    'Aspire to be a Data Analyst in Gurgaon? Explore a range of top-rated courses & institutes offering in-demand Data Analytics skills.',
  metaKeywords:
    'data science course, data analytics certificate, data analytics certification, data analytics online course, data analytics course in India, data analytics course in Gurgaon, data analytics training in Bangalore, data analytics course in Gurgaon',
  selfStudyHoursCount: '260',
  classCountSubline: ' (8-10 hrs/ week)',
  hoursPerClassCount: '46 x 3 = 138 hrs',
  CourseLearningModes: {
    amountINR: 38000,
    maxAmountINR: 48000,
  },
  taxPercentage: '18',
  LMSCourse: 'ckepznduu00attqjl6qni9nwp',
  courseID: 9997,
}

const PGdetailsGurgaon = [
  {
    mainTitle: 'Introduction to Data Analytics Training in Gurgaon',
    longDescription: `<p>Gurgaon is a thriving hub of industries and corporate giants in India. This city has a robust IT infrastructure, data centers, and a bustling business ecosystem, creating an unparalleled learning environment for aspiring data analysts. Gurgaon keeps expanding its horizons, with major Fortune 500 companies establishing their footholds in this city. The city is an excellent location for data analysts looking to build a lucrative career. </p><p>The data industry is seeing a seismic shift in the demand for skilled analysts. Research estimates that the Indian big data market will reach a valuation of <a class="ct-lg-linkUpdate" target="_blank" href="https://www.mordorintelligence.com/industry-reports/investment-opportunities-of-big-data-technology-in-india/market-size">$3.38 billion by 2029</a>. Consequently, the Indian job market is estimated to surge in demand for skilled analysts within the same timeline, with tier 1 cities like Delhi, Gurgaon, Bangalore, and Pune taking the lead. </p><p>
    Data has become the linchpin of innovation and strategic management, and our data analytics training course in Gurgaon encompasses every new trend, technology, and most-talked-about skill. You will learn the art of informed decision-making, uncover meaningful insights from huge datasets, and position yourself as the head of strategic planning and performance optimization. </p><p>
    Companies are looking for analysts with skills relevant to the present and the versatility to adapt to ongoing changes. Our data analytics course in Gurgaon will prepare you for today, tomorrow, and the unpredictable future—you will not just be an analyst but a pro analyst who can learn new technologies like a cakewalk. </p><p>
    Our data analytics certification training course in Gurgaon offers a comprehensive curriculum. Our learning materials follow a problem-solving approach and enable you to learn and implement the latest technological advancements. We integrate industry knowledge with Gurgaon's vibrant business ecosystem to offer a fulfilling learning module. </p><p>
    For more updates on the industry, <a class="ct-lg-linkUpdate" target="_blank" href="https://www.analytixlabs.co.in/free-resources">download our free industry reports</a> on industry insights, salary, and learning paths. For more advanced learning, explore our <a class="ct-lg-linkUpdate" target="_blank" href="https://www.analytixlabs.co.in/pg-in-data-science">PG courses in Data Science</a> [offered in both 1-year and 6-month advanced courses].</p><p>
    Through AnalytixLabs' data analytics training in Gurgaon, you'll engage with a comprehensive educational approach crafted by industry experts to navigate the dynamic field of data analytics. Our course seamlessly integrates state-of-the-art learning tools with practical, hands-on methodologies, ensuring your readiness for the industry.</p><p>
    Our data analytics course in Gurgaon is designed for fresh graduates, experienced professionals, and anyone interested in building a career in data analytics. Our course curriculum covers all the basics of analytics before delving into the advanced segments. So, if you are a novice, you can still enroll and start learning. No prior knowledge is required. </p><p>
    Enroll in our data analytics course and embrace the power of data visualization, analytics, and predictive modeling.
    </p>`,
    needHelp: true,
    subDetails: [
      {
        subBulletsPointDescription: '<p>The Learning Objectives for our data analytics training in Gurgaon are as follows:</p>' ,
        SubBulletPoint:[
          {
            title: '<b>Master core tools and skillsets to build an analytical approach to various business problems.</b> You will start with the foundations of mathematics, statistics, and programming basics before moving on to statistical analysis and modeling in Python and visualization tools like Excel and PowerBI. Our data analyst course in Gurgaon covers all the foundational knowledge and gradually moves into advanced learning.',
          },
          {
            title: `<b>This course aims to equip you with soft skills for wholesome learning.</b> You will learn the art of analytical thinking and problem-solving and how to present your findings in layman's terms. Additionally, we will help you develop the right attitude toward any business problem, create strategies independently, and make business decisions effectively.`,
          },
          {
            title: `<b>Become a Pro Analytics professional.</b> The last objective of this data analytics certification course in Gurgaon is to help you become a pro analytics professional. You will gain experience constructing robust data models, learn to leverage various analytical tools, and empower your organization with smart and informed strategies to become a game changer.
            `,
            description: `<p class='mt-3'>Completing this course will increase your confidence in articulating your analytical findings while aligning them with the core business goals and values. </p>`,
          },
        ]
      },
    ],
  },
  {
    title: `What is Data Analytics?`,
    longDescription: `<p><span style="font-weight: 400;">
    Data analytics involves four core stages of analyzing large datasets to uncover meaningful insights - inspect, transform, model, and clean raw data. It involves various techniques and tools to uncover patterns, trends, and correlations, leading to deriving meaningful business insights.</p>
    <p><span style="font-weight: 400;">Data analytics involves inspecting, transforming, modeling, and cleaning raw data to obtain valuable insights for decision-making. It employs various techniques and tools to analyze large datasets and uncover patterns, trends, and correlations, ultimately deriving meaningful information. </span></p>
    <p><span style="font-weight: 400;">Data analytics encompasses various methods, including descriptive analytics for summarizing and describing data, diagnostic analytics for identifying the causes of past events, predictive analytics for forecasting future trends, and prescriptive analytics for recommending actions based on analysis results.</span></p>
    <p><span style="font-weight: 400;">Organizations leverage data analytics to enhance decision-making, optimize processes, improve efficiency, and gain a competitive edge across various industries. This multidisciplinary field combines elements of statistics, mathematics, computer science, and domain-specific knowledge to extract actionable insights from data.</span></p>
    <p><span style="font-weight: 400;">Mastering these skills through a data analytics course in Gurgaon will enable you to transform raw data into actionable intelligence, empowering you to shape the future by uncovering hidden patterns in the data.</span></p>`,
    bulletsPointDescription: `<h3 class="h3 light-blue-color mt-4 mb-3"><b>Types of Data Analytics</b></h3>` ,
    noShowArrow: true,
    subDetails: [
      {
        SubBulletPoint:[
          {
            title: `<h4>Descriptive Analytics</h4>`,
            description: `<p>This foundational type addresses the question, "What happened?" It involves summarizing and organizing data to uncover basic trends, patterns, and statistics. Think of it as painting a picture of the past.</p>`,
            notShowArrow: true,
          },
          {
            title: `<h4>Diagnostic Analytics</h4>`,
            description: `<p>This type seeks to answer, "Why did it happen?" It delves into the root causes behind trends and identifies factors influencing them. Consider it akin to detective work for data!</p>`,
            notShowArrow: true,
          },
          {
            title: `<h4>Predictive Analytics</h4>`,
            description: `<p>Looking ahead, this type asks, "What will happen?" It utilizes statistical models and machine learning to forecast trends and anticipate potential outcomes. It's like having a crystal ball powered by data.</p>`,
            notShowArrow: true,
          },
          {
            title: `<h4>Prescriptive Analytics</h4>`,
            description: `<p>Going beyond predictions, this type asks, "What should we do?" It recommends optimal actions based on insights from all other types, guiding future decisions. Think of it as having a data-driven advisor at your fingertips.</p>`,
            notShowArrow: true,
          },
          {
            title: ``,
            description: `<h5 class="mt-5 ct-pg-font-h5"><blockquote>
            <p class="ct-ml-60 ct-pg-blockquote-color" >For more insights, you may also read: <a class="ct-lg-link-blockquote" href="https://www.analytixlabs.co.in/blog/types-of-business-analytics/" target="_blank">Types of Data Analytics</a></p>
            </blockquote></h5>`,
            notShowArrow: true,
          },
        ]
      },
    ],

  },
  {
    title: `Course Syllabus of Data Analytics Course in Gurgaon`,
    BTNTitleShow: true,
    longDescription: `<p>Our data analyst training in Gurgaon is well-suited for individuals with varied backgrounds, including Finance, Mathematics, Engineering, and Business Management, who aspire to enhance their skills and pursue a career in Analytics.</p><p>
    AnalytixLab's data analyst course in Gurgaon is thoughtfully designed to accommodate college graduates' and working professionals' diverse needs and career aspirations. The program offers flexibility, allowing participants to choose specialized modules, select different learning intensities, and customize the curriculum to align with their specific learning styles and career objectives.</p><p>
    The course at AnalytixLabs is organized into three distinct terms, ensuring a structured and comprehensive learning journey. This intentional division enables participants to delve deeply into each aspect of the curriculum, thoroughly grasp complex concepts, and effectively apply their newfound skills.
    </p>
    `,
    bulletPoint:[
      {
        title: `TERM 1`,
        description: `<p>This term is designed to equip participants with a robust foundation in structured problem-solving. It encompasses fundamental concepts in mathematics, statistics, and programming, utilizing a combination of eLearning methodologies.</p><p>
        The curriculum integrates business intelligence, analytics, and data visualization utilizing tools such as Excel and PowerBI. Participants will also acquire proficiency in exploratory data analysis, data management, and SQL for both traditional and cloud environments. This holistic approach ensures that participants gain essential skills and knowledge necessary to excel in the field of data analytics.
        </p><ul>
          <li class='mb-2'>Orientation - Setting up for success</li>
          <li class='mb-2'>Problem-Solving - A structured approach to problem-solving</li>
          <li class='mb-2'>Building Blocks - Foundations of Mathematics & Statistics, Fundamentals of Programming (Blended eLearning)</li>
          <li class='mb-2'>Business Intelligence, Analytics & Data Visualization (using Excel & <a class='ct-lg-linkUpdate' href="https://powerbi.microsoft.com/en-us/" target="_blank">PowerBI</a>)</li>
          <li class='mb-2'>Exploratory Data Analysis & Data Management (RDBMS - ETL - <a class='ct-lg-linkUpdate' href="https://www.analytixlabs.co.in/blog/sql-in-data-science/" target="_blank">SQL</a> for Data Science - SQL on Cloud)
          </li>
        </ul>
        `,
      },
      {
        title: `TERM 2`,
        description: `<p>The second term of the course emphasizes "Python for Analytics," encompassing core Python skills, data analysis, statistical methods, and predictive modeling. The curriculum is meticulously structured to cover crucial aspects such as Python foundations, exploratory data analysis, statistical analysis, as well as linear and logistic regression modeling.</p><p>
        Furthermore, the curriculum delves into the practical applications of data analytics across diverse industries, including marketing, operations, risk analytics, retail, eCommerce, banking, insurance, and telecom.</p><p>
        To reinforce practical skills, the term includes practice bootcamps with weekday sessions, providing participants with hands-on experience and enhancing their understanding of data analytics in real-world scenarios.
        </p><h4 class="h5 light-blue-color mt-3 mb-3"><b>Python for Analytics - Statistical Analysis and Modelling using Python :</b></h4><ul>
        <li class='mb-2'><a class='ct-lg-linkUpdate' href="https://www.analytixlabs.co.in/deep-learning-with-python" target="_blank">Python</a> Foundation (Core Python) - Python Packages  </li>
        <li class='mb-2'>Exploratory Data Analysis - Data Visualization</li>
        <li class='mb-2'>Statistical Analysis (Basic Statistics - Statistical Methods)</li>
        <li class='mb-2'>Predictive Modeling using Linear & Logistic Regression</li>
        </ul>
        <h4 class="h5 light-blue-color mt-3 mb-3"><b>Data Analytics Applications - Industries & Functions (24 hrs eLearning)</b></h4><ul>
        <li class='mt-2'>Marketing Analytics</li>
        <li class='mt-2'>Operations Analytics</li>
        <li class='mt-2'>Risk Analytics</li>
        <li class='mt-2'>Industry Application of Analytics in Retail, eCommerce, Banking, Insurance, Telecom, etc.</li>
        <li class='mt-2'>Practice Bootcamps (Weekday sessions)</li>
        </ul>
        `,
      },
      {
        title: `TERM 3`,
        description: `<p>This term emphasizes the practical application of data analytics through an industry capstone project. This project encompasses the entire pipeline development process, including business requirement gathering and comprehensive documentation. The curriculum also integrates principles of analytics project management and strongly emphasizes refining problem-solving skills.</p><p>
        Moreover, Term 3 significantly focuses on career preparation with a comprehensive approach. This includes readiness for interviews, active participation in mock interviews, placement assistance, and engagement in a success accelerator program covering resume building and interview preparation.</p><p>
        The overarching goal is to equip students with technical expertise and the essential skills and tools necessary for a successful and seamless transition into the professional realm of data analytics.
        </p><h4 class="h5 light-blue-color mt-3 mb-3"><b>Data Analytics in Practice - Industry Capstone Project work - Final Viva</b></h4><ul>
        <li class='mb-2'>Data Analytics Project - End-to-End Pipeline</li><li class='mb-2'>Business Requirement Gathering - Problem-Solving
        </li><li class='mb-2'>Documentation - best practices
        </li><li class='mb-2'>Analytics Project Management</li>
        </ul><h4 class="h5 light-blue-color mt-3 mb-3"><b>Placement Preparation - Interview Preparation - Mock Interviews</b></h4>
        <ul>
        <li class='mb-2'>Placement Readiness Programme</li>
        <li class='mb-2'>Success Accelerator</li>
        <li class='mb-2'>Career Assistance (Resume, Mock Sessions, Interview prep, etc.)</li>
        </ul>`,
      },
    ],
  },
  {
    mainTitle: `Data Analytics Certification Details`,
    longDescription: `<p>The AnalytixLabs Certification holds a prestigious significance across the globe. We take pride in our extensive domain expertise and esteemed clientele. As one of the leading institutes for data analysis courses in Gurgaon, we prioritize maintaining the integrity of our certification process.</p>`,
  },
  {
    title: `Benefits of our certification include:`,
    subDetails : [
      {
        SubBulletPoint:[
          {
            title: `Certification is conferred after a comprehensive evaluation process, incorporating mandatory case studies, assignments, multiple-choice questions (MCQs), and viva assessments seamlessly integrated into the course curriculum.`,

          },
          {
            title: `Timely completion of certification within one year of course registration is mandatory to ensure steady progression and proficiency.`,
          },
          {
            title: `Trainees encountering challenges with assignments and projects are encouraged to seek assistance and support for improvement. However, it is imperative to emphasize that academic integrity is non-negotiable, and any form of plagiarism will not be tolerated during the evaluation process.`,
            description: `<p class='mt-3'>These measures are meticulously implemented to maintain our certification's credibility and authentically showcase our certified professionals' genuine capabilities.</p>
            `
          },
        ]
      },
    ],
  },
  {
    title: `Capstone Projects Included in Data Analytics Gurgaon Course`,
    longDescription: `<p>Our data analyst course in Gurgaon also includes the following assignments and projects for self-study and hands-on learning.</p>`,
    needHelp: true,
    bulletPoint:[
      {
        title: `Data Analytics Project - End-to-End Pipeline`,
        description: `<p>Our Data Analytics Project features a resilient end-to-end pipeline seamlessly managing data from collection to cleaning, transformation, and analysis. We diligently gathered and purified various datasets, employed advanced transformation techniques, and utilized state-of-the-art tools for insightful analytics.</p><p>
        This efficient pipeline improves overall productivity, ensuring results of high-quality and actionable insights. This comprehensive approach highlights our commitment to excellence in data analytics, empowering well-informed decision-making.
        </p>`,
      },
      {
        title: `Business Requirement Gathering - Problem-Solving`,
        description: `<p>When addressing business challenges, our journey in problem-solving begins with the careful task of defining the business problem. This first step sets the groundwork for subsequent phases, starting with outlining the requirements.</p><p>
        Our methodical approach to problem-solving progresses from defining the business problem to implementing solutions. It starts with scoping requirements, setting analytics objectives, and thoroughly collecting and preparing data.</p><p>
        The journey advances through solution development, validation, and implementation, concluding with crucial stages of tracking and fine-tuning. This holistic methodology guarantees a strategic and efficient approach to tackling business challenges.
        </p>`
      },
      {
        title: `Documentation - Best Practices`,
        description: `<p>Effective documentation is the foundation of successful projects, ensuring clarity, continuity, and collaboration. Adhering to best practices in documentation streamlines processes and boosts overall project efficiency.</p><p>
        The focus is on achieving clarity, coherence, and academic rigor, specifically emphasizing integrating data analytics into practical applications. This underscores the contemporary significance of data analytics in research methodologies, ensuring that the documentation meets academic standards and makes a meaningful contribution to the field of study.</p><p>
        The curriculum is strategically crafted to progressively build upon the foundation established in each preceding term, providing students with a seamless and evolving educational experience. The program extends four days a week, accommodating weekdays and weekends.</p><p>
        Participants can actively participate in interactive learning through live online sessions or attend classroom sessions in Gurgaon, Bangalore, and Noida. The program adopts a blended e-learning approach, offering live doubt support and practice bootcamps. Furthermore, students will enjoy access to session recordings for future reference and self-study, enhancing the overall learning experience.
        </p>`
      },
    ],
  },
]

const PGdetailsGurgaonSecondSections = [
  {
    title: `Admission and Application Process`,
    needHelp: true,
    isShowRequestACallback: true,
    longDescription: `<p>We have a personalized admission process for all our courses. To enroll in our data analytics certification course in Gurgaon, you must express your interest via our inquiry form, phone, or email. Once we receive your interest, one of our learning experts will contact you within 24 working hours. </p><p>
    Our learning advisor will contact you directly to understand what you are looking for in this course and your end goal. Simultaneously, he/she will understand your academic background and domain knowledge. If you are a novice, do not worry. Based on the details you provide, our learning advisor will help you understand the benefits of enrolling in this course. </p><p>
    Our courses have offline and online sessions and classroom and self-paced learning modes. Our learning expert will communicate every aspect of the course and also answer any query that you may have regarding our teaching modules, payment process, certifications, placements, and curriculum. Our expert will guide you with the perfect learning mode based on your academics and professional know-how. </p><p>
    This will also include understanding your choice of location, time, and learning mode since we have classroom sessions across four major cities - Bangalore, Delhi, Noida, and Gurgaon. </p><p>
    We want to ensure that you get the most out of our courses, and we do that by understanding your expectations. Our learning advisors will thoroughly and transparently guide you through course details, job scenarios, location details, and learning preferences. </p><p>
    Once you and your learning advisor are on the same page, you may pay the admission fees to reserve your spot for the course for the next upcoming batch.
    </p>`,
  },
  {
    title: `Data Analytics Course Eligibility`,
    longDescription: `<p>The Data Analytics certification course is tailored to suit beginners, recent graduates, and early-stage professionals looking to boost their skills. Our certification programs are crafted for learners with a basic understanding of data analytics, providing a roadmap to progress into more advanced roles.</p><p>
    Upon finishing this course, participants can opt for any of our diploma courses based on their chosen specialization.</p><p>
    Our Data Analytics certification training course in Gurgaon doesn't require any prerequisites in terms of prior knowledge or experience in data analytics. It's uniquely designed to welcome individuals from various educational backgrounds eager to delve into data.
    </p>`,
    bulletsPointDescription: `<h3 style='color:#013368'>General requisites, but not mandatory: </h3>` ,
    bulletPoint:[
      {
        title: `Bachelor's degree`,
        description: `(Preferred in relevant fields like statistics, computer science, or mathematics) While not obligatory, having a bachelor's degree in quantitative fields is a bonus, particularly for university-level programs.
        <p class='mt-3'>Additional requirements that can be an advantage for you while learning this course - </p>`,
      },
      {
        title: `Basic Understanding of Statistics and Mathematics:`,
        description: ` A foundational understanding of statistics and mathematics is beneficial because most data analytics courses involve working with data.`,
      },
      {
        title: `Programming Skills:`,
        description: `<p>Proficiency in programming languages like Python or R is advantageous, as many data analysis tasks may require such skills. Some courses may require prior programming knowledge, while others offer introductory lessons for newcomers.</p>
        <p class='mt-4'>
        <b>Note:</b> Our data analytics course will <strong><u>cover all the foundational basics</u></strong> of programming, problem-solving, and data management. If you don’t have any prior knowledge or experience in the data field, you can learn the same in our first set of learning sessions.
        </p>`,
      },
    ],
  },
  {
    mainTitle: `Online Data Analytics Course in Gurgaon- Learning Methodology`,
    longDescription: `<p>AnalytixLabs has welcomed data aspirants since 2011 and is one of Gurgaon's leading institutes for data analytics. Our data analytics certification course in Gurgaon delivers a personalized learning experience. We offer targeted, hands-on learning to equip you for the current job market.
    </p>
    <p><b>The core USP of this course includes: </b></p>
    <ul class="pg-lg-ul-r-20">
      <li>
        <p>Up-to-date curriculum</p>
      </li>
      <li>
        <p>Professionally designed projects</p>
      </li>
      <li>
        <p>Three flexible teaching modes to suit students' preferences</p>
      </li>
      <li>
        <p>Weekday and weekend classes to meet students' learning flexibility</p>
      </li>
    </ul>
    <p>Our faculty members in the data analytics training in Gurgaon bring extensive global analytics expertise, having collaborated with corporations and possessing corporate-level training proficiency. Drawing from real-world experiences, their insights ensure students stay informed about ongoing industry trends.</p><p>
    As students embark on their journey through our data analytics course in Gurgaon, they can choose from various learning modes, including classroom boot camps, interactive live online sessions, and self-paced e-learning modules. Throughout the course, all students have access to a cutting-edge learning system that is continually updated to meet industry demands.
    </p>`,
    bulletPoint:[
      {
        title: `Self-paced eLearning`,
        description: `<p>Engage in on-demand e-learning video sessions within your learning management system. Receive extensive doubt support through email, online platforms, and in-person assistance.</p>`,
      },
      {
        title: `Classroom & Bootcamp`,
        description: `<p>Experience hands-on learning with in-person mentorship. We offer data analytics courses in Gurgaon on weekdays, weekends, and evenings.</p>`,
      },
      {
        title: `Interactive online classes`,
        description: `<p>Participate in live online data analytics courses in Gurgaon from any location. These fully interactive sessions facilitate real-time discussions and question-and-answer sessions.</p>`,
      },
    ],
    subDetails: [
      {
        longDescription: `<p>In our data analytics training in Gurgaon, we prioritize a personalized approach with small batch sizes and individualized counseling, fostering high dedication and personal attention.</p><p>The curriculum is crafted to incorporate practical exercises and real-world business datasets into assignments, ensuring comprehensive and practical knowledge retention.</p>
        `,
      },
    ],
  },
  {
    title: `Learning Benefits with AnalytixLabs`,
    longDescription: `<p>Our program adopts an experiential learning approach, elevating the value of your education and instilling confidence in your abilities as a proficient data analyst. With included placement assistance, this data analytics course stands out as one of the premier options in Gurgaon.</p>`,
    subDetails: [
      {
        subBulletsPointDescription: ``,
        SubBulletPoint:[
          {
            title: `<strong>Industry-focused curriculum: </strong><span>Our courses cater to all career goals, whether you are looking for general data analysis skills, machine learning tools, or specific industry-based learning. We offer tailored programs designed to keep you industry relevant at all times.</span>`,
          },
          {
            title: `<strong>Experienced instructors: </strong><span>Our instructors' panel includes experienced data professionals who bring practical insights. Our instructors combine their experiences with the course syllabus to offer learning experiences beyond theoretical know-how.</span>`,
          },
          {
            title: `<strong>Hands-on learning: </strong><span>We offer hands-on learning experience and help you prepare for real problem-solving through elaborate case studies, assignments, and projects. Learning by doing is crucial in data analytics, which is the core of our learning module. </span>`,
          },
          {
            title: `<strong>Career support: </strong><span>We offer personalized career guidance, from resume-building to interview preparation. Our instructors will help you streamline your job search, job application, interview, and final consultation to help you choose the right job offer.</span>`,
          },
          {
            title: `<strong>Active community: </strong><span>AnalytixLabs has an active and thriving community of novice and experienced data enthusiasts. Connect with peers and get real-time help in data, learning, and implementation. Our students and instructors foster an active learning community where you can contribute forever.</span>`,
          }
        ],
      },
    ],
  },
  {
    title: `Skills You Will Learn in this Course`,
    longDescription: `<p>A comprehensive set of tools and library packages is included in the data analytics certification course in Gurgaon, including but not limited to:</p>
    `,
    needHelp: true,
    subDetails: [
      {
        subBulletsPointDescription: `<h3 class="h3 light-blue-color mt-4 mb-3"><b>Tools Included</b></h3>` ,
        SubBulletPoint:[
          {
            title: `<strong>Excel</strong><span> - </span><span>Widely utilized spreadsheet software for organizing and analyzing data.</span>`,
          },
          {
            title: `<strong>PowerBI</strong><span> - </span><span>A user-friendly data analysis tool developed by Microsoft for efficient data exploration.</span>`,
          },
          {
            title: `<strong>SQL Server:</strong><span> - </span><span>A cloud-based SQL server designed for managing relational databases.</span>`,
          },
          {
            title: `<strong>Python</strong><span> - </span><span>A versatile programming language used in web development, data analysis, and machine learning.</span>`,
          },
          {
            title: `<strong>Jupyter Notebook</strong><span> - </span><span>An interactive web-based platform for creating and sharing documents with live code.</span>`,
          },
          {
            title: `<strong>GIT</strong><span> - </span><span>A version control system facilitating tracking changes in source code during software development.</span>`,
          },
          {
            title: `<strong>MS PowerPoint</strong><span> - </span><span>Presentation software for crafting slideshows and visual content.</span>`,
          },
          {
            title: `<strong>Statsmodel</strong><span> - </span><span>A Python library for data analysis, data science, and statistical modeling.</span>`,
          },
          {
            title: `<strong>Seaborn</strong><span> - </span><span>A Python library for generating statistical visualizations built on Matplotlib</span>`,
          },
          {
            title: `<strong>Pandas</strong><span> - </span><span>A Python library specializing in data analysis, particularly data manipulation and cleaning.</span>`,
          },
          {
            title: `<strong>NumPy</strong><span> - </span><span>A versatile array processing package for efficient scientific computations in Python.</span>`,
          },
          {
            title: `<strong>Matplotlib</strong><span> - </span><span>A Python library for 2D plotting graphics, offering diverse data visualization capabilities.</span>`,
          },
          {
            title: `<strong>DAX</strong><span> - </span><span>Data Analysis Expressions is a formula language for data analysis commonly associated with Microsoft Excel and PowerBI.</span>`,
            description: `<p class='mt-3'>Moreover, you will develop soft skills like business acumen and business analysis and the ability to articulate data findings in a way everyone understands, enhancing the comprehensibility of your predictions.</p><p>The course focuses on fostering two pivotal skill sets, namely:</p>`,
          },
        ],
      },
      {
        longDescription: `<h3 class="h3 light-blue-color mt-4 mb-3"><b>Technical Skills</b></h3><p>This course encompasses instruction on many key technical languages and tools, including</p>`,
        SubBulletPoint:[
          {
            title: `<h4>Core Python</h4>`,
            description: `<p>Core Python comprises the foundational and essential elements of the Python programming language. This encompasses basic syntax, data types, control structures (like loops and conditionals), functions, and essential libraries inherent to Python.

            Mastery of core Python is crucial for building a strong foundation in programming. It provides developers with the necessary skills to tackle various tasks, from basic scripting to more complex software development. Serving as the fundamental framework of the language, proficiency in core Python concepts is indispensable for anyone aiming to excel in Python programming.
            </p>`
          },
          {
            title: `<h4>JIRA</h4>`,
            description: `<p><a class='ct-lg-linkUpdate' href="https://www.atlassian.com/software/jira/guides/getting-started/introduction" target="_blank"><b>JIRA</b></a> is a crucial business analytics tool for assessing team performance and generating detailed, visually enriched data reports. Particularly beneficial in operational analytics, JIRA functions as a comprehensive database for tracking and managing various facets of projects.</p>`
          },
          {
            title: `<h4>Power BI</h4>`,
            description: `<p><a class='ct-lg-linkUpdate' href="https://powerbi.microsoft.com/en-us/" target="_blank">PowerBI</a>, developed by Microsoft, stands out as a potent business analytics tool, offering extensive and interactive data visualization and analysis capabilities. It encompasses a rich set of features for data preparation, modeling, and creating visually compelling reports and dashboards.</p><p>
            Focusing on facilitating data-driven decision-making, Power BI features a user-friendly interface and robust tools that empower users to explore and comprehend their data efficiently. Whether dealing with structured or unstructured data, Power BI enables users to convert raw information into meaningful insights, positioning it as a valuable asset for businesses seeking to leverage their data strategically.</p><p>
            Power BI's versatility and functionality have made it a popular choice for organizations that want to enhance their analytical capabilities and derive actionable insights from their data.
            </p>`
          },
          {
            title: `<h4>Microsoft Excel</h4>`,
            description: `<p>Microsoft Excel is a highly popular spreadsheet software within the Microsoft Office suite. It is designed for various data-related tasks and offers a user-friendly interface for creating, editing, and manipulating spreadsheets containing numerical and text data.</p><p>
            One of Excel's notable features is the inclusion of pivot tables, which empower users to analyze and summarize large datasets efficiently. Pivot tables provide a dynamic way to organize and interpret data, facilitating insightful data analysis.</p><p>
            Excel is widely used for data entry, calculation, charting, and financial analysis tasks. It is a versatile tool suitable for personal and professional use. Its capabilities make it an integral part of many workplaces and an essential tool for individuals managing and analyzing data.
            </p>`
          },
          {
            title: `<h4>Microsoft PowerPoint</h4>`,
            description: `<p>PowerPoint, a Microsoft Office suite component, is a valuable tool for data visualization, particularly when communicating data insights to non-technical team members and stakeholders. When used in conjunction with other Office tools, it operates as part of an analytical suite.
            </p>`
          },
          {
            title: `<h4>Azure (Cloud Computing)</h4>`,
            description: `<p><a class='ct-lg-linkUpdate' href="https://azure.microsoft.com/en-in/" target="_blank">Azure</a> Analysis Services offers cloud-based, enterprise-grade data models. These models allow data assembly, refinement, and security from multiple sources in a tabular semantic data model. They contribute to cloud-based data analytics and management.</p>`
          },
          {
            title: `<h4>Tableau (Optional)</h4>`,
            description: `<p>An end-to-end data analytics platform designed to prepare data for analysis. You can collaborate and share your data insights with this tool. The visual analysis in Tableau is highly powerful and is easily shared across organizations.</p>`
          },
        ]
      },
      {
        longDescription: `<h3 class="h3 light-blue-color mt-4 mb-3"><b>Soft Skills</b></h3><p>Professionals' success in diverse fields relies heavily on soft skills, and the realm of data analysis is no different. A skilled data analyst showcases technical expertise and excels in crucial soft skills, elevating their effectiveness in data-driven environments' dynamic and collaborative settings.</p><p>Let's explore the fundamental soft skills that set apart a proficient data analyst:</p>`,
        SubBulletPoint:[
          {
            title: `<h4>Communication Skills</h4>`,
            description: `<p>It is paramount to convey intricate technical information effectively to non-technical stakeholders. Clear and concise communication plays a pivotal role in sharing insights and recommendations.</p>`
          },
          {
            title: `<h4>Critical Thinking Ability</h4>`,
            description: `<p>Vital to the role is the capacity to critically and objectively analyze data. Data analysts must evaluate information, identify trends, and draw meaningful conclusions to support informed decision-making.</p>`
          },
          {
            title: `<h4>Problem-Solving Aptitude</h4>`,
            description: `<p>Data analysts often encounter challenges such as data quality issues, incomplete datasets, or ambiguous requirements. Robust problem-solving skills empower analysts to navigate obstacles and devise effective solutions.</p>`
          },
          {
            title: `<h4>Attention to Detail</h4>`,
            description: `<p>Precision is a cornerstone in data analysis. Data analysts must meticulously attend to details, ensuring accuracy in tasks ranging from cleaning and processing data to creating visualizations.</p>`
          },
          {
            title: `<h4>Time Management Skills</h4>`,
            description: `<p>Given the time-sensitive nature of data projects, efficient time management is imperative. Successfully meeting deadlines hinges on prioritizing tasks and effectively balancing workload.</p>`
          },
          {
            title: `<h4>Adaptability</h4>`,
            description: `<p>The field of data analysis is dynamic and marked by evolving technologies and methodologies. Data analysts must remain adaptable, embracing new tools and techniques to stay abreast of current trends and effectively address emerging challenges in the data landscape.</p>`
          },
        ]
      },
    ],

  },
]

const MarketingAnalyticsGurgaon = [
  {
    title: `Top Job Roles You Can Explore in Gurgaon`,
    bulletsPointDescription: `<p>Upon completing the course, seize the opportunity to explore a range of career paths aligned with your acquired skills and knowledge. This exploration enables you to apply your expertise in diverse professional settings, discovering the most suitable and fulfilling career opportunities for your unique skill set.</p>`,
    bulletPoint:[
      {
        title: `Data Analyst`,
        description: `<p>Responsibilities: Data Analysts review and analyze data to extract meaningful insights, enhancing overall business performance. This role involves interpreting patterns, identifying trends, and providing actionable recommendations for strategic decisions.</p>`,
      },
      {
        title: `Analytics Consultant`,
        description: `<p>Responsibilities: They design and deploy robust data infrastructure, ensuring accessibility for analytics and technological consumption. Their expertise lies in constructing a framework that facilitates seamless data flow and empowers data-driven decision-making processes.</p>`,
      },
      {
        title: `Business Analyst`,
        description: `<p>Responsibilities: Business Analysts bridge the gap between data and business strategy. This role requires a comprehensive understanding of business processes, identifying areas for improvement, efficiency enhancement, and strategic alignment. Extracted insights guide decision-makers toward informed choices.</p>`,
      },
      {
        title: `Business Intelligence Analyst`,
        description: `<p>Responsibilities: In the dynamic field of Business Intelligence, a BI Analyst translates raw data into actionable insights. Prime responsibilities include transforming complex information into comprehensible reports and dashboards, impacting internal and external stakeholders for informed decision-making.</p><p>
        Become part of our vibrant community and elevate your data analytics journey. Opt for our data analytics training in Gurgaon to unlock your potential in the thrilling realm of data!
        </p>`,
      },
    ],
  },
]

const PlacementAndSupportGurgaon = [
  {
    isShowRequestACallbackOnlyCTA: true,
    title: `Data Analytics Course with Placement Support`,
    longDescription: `<p>Upon completing data analytics training at our institute in Gurgaon, we are dedicated to facilitating placements for our students in well-established companies spanning diverse industries, including Accenture, American Express, and McKinsey.</p><p>
    Our primary objective is equipping learners with fundamental and advanced analytics skills, positioning them as highly proficient professionals prepared for successful careers.</p><p>
    Our educational philosophy places job-relevant skills at its core, shaping a comprehensive data analyst course in Gurgaon with a strong emphasis on placement opportunities. The experienced faculty members in the data analytics training course provide ongoing guidance, covering critical aspects such as job applications, interview preparation, and the option for mock interviews if deemed necessary.</p><p>
    Additionally, we actively support our students by referring their resumes to various companies whenever suitable opportunities arise.
    </p>
    <p>AnalytixLabs is committed to helping you launch your career successfully. Our default placement guidance program focuses on developing job-relevant skills. You'll receive expert guidance on resume building, interview preparation and how to apply for relevant job roles. For those seeking an even greater advantage, our optional Placement Readiness Program (PRP) offers bouquet of benefits:</p>
    `,
    bulletPoint:[
      {
        title : `No Upfront Fees`,
        description: `<p>We believe in shared success. That's why we offer a unique model which comes with zero upfront costs. Instead, you'll only pay for the Placement Readiness Program (PRP) if you secure a job with a minimum salary of INR 7 Lakhs per annum within six months of completing PRP.</p>`,
      },
      {
        title : `Placement Readiness Program`,
        description: `<p>An optional 2-month, industry-focused module designed to enhance technical and soft skills. It includes coaching, key topic recaps for interviews, practice tests, case studies, and simulated recruitment drives. The program also features mock interviews with an industry panel, providing one-to-one feedback and guidance.</p>`,
      },
      {
        title : `Flexible Opt-in`,
        description: `<p>After certification you can take upto 30 days to decide and opt in for the Placement Readiness Program. This option is ideal for those who are looking for upskilling or a coveted industry-recognized certificate, feel confident in their acquired skills, and wish to pursue job applications independently. These students will still be eligible for basic placement guidance.</p>`,
      },
      {
        title : `Continuous Support`,
        description: `<p>After you successfully complete PRP, we will continue offering ongoing support as long as you need it. Many of our students benefit from numerous interview calls and rewarding career prospects attributed to the skills acquired during the course.</p>`,
      },
    ],

  },
  {

    title: `Career Stories by AnalytixLabs Alumni`,
    longDescription: `<p>We take pride in our students and are humbled to see them excel in their field like a pro. We are thrilled to see our course graduates embracing data analytics concepts and implementing their learning in practical scenarios. </p><p>
    These success stories speak volumes of our student's confidence and the reliability we can offer each student until the end. We are happy that our students rely on us and form a personal bond with our learning experts—a bond that goes beyond courses and certifications. </p><p>
    We are indebted to our learning experts, who bring a gamut of knowledge and share it impartially with our students. They work daily to enable each student and guide them on the right path to success. We know each student is different, and our teaching methodology is designed to address your specific needs throughout the course span and beyond. </p><p>
    We encourage our students to participate in activities, complete assignments, and take advantage of one-on-one sessions with their mentors to benefit from the course. We are more than elated to see our approach transcending into successful careers for our students since 2011.
    </p>`,
  },

]
const UpcomingBatchesGurgaon = [
  {
    title: `Data Analytics Course in Gurgaon- Upcoming Batches`,
    longDescription: `<p>Our data analytics training course in Gurgaon is happening on multiple dates. Choose a date and time to start learning. </p>`,
    needHelp: false,
  },
]

const careerGraphGurgaon = {
  title: 'CAREER GRAPH [IN INR]',
  careerList: [
    {
      imageUrl: daFresher,
      upperContent: 'Data Analyst [Fresher]',
      lowerContent: '5-7 L/Year',
    },
    {
      imageUrl: seniorDA,
      upperContent: 'Senior Data Analyst',
      lowerContent: '12-20 L/Year',
    },
    {
      imageUrl: daIV,
      upperContent: 'Data Analyst IV',
      lowerContent: '20+ L/Year',
    },
  ],
}

const DataAnalyticsCourseFeeDataGurgaon = {
  mainTitle:  `Data Analytics Course in Gurgaon - Fees and Duration`,
  table: {
    title: `Data Analytics Course Fee and Duration`,
    mobileViewTable:
    `<div class='pg-table-width'>
    <table style="border-collapse: collapse; width: 100%; border: 1px solid #000000; padding: 10px 20px;">
    <tbody>
    <tr>
    <td style="border-right: 1px solid #000000; border-bottom: 1px solid #1c4587; padding: 25px 20px 10px; background-color: #1c4587; width: 40%;" rowspan="2">
    <p style="text-align:center"><span style="color: #ffffff;"><strong>Data Analytics Course in Gurgaon</strong></span></p>
    </td>
    <td style="border: 1px solid #000000; padding: 20px 20px 10px; background-color: #1c4587; text-align: center;">
    <p><span style="color: #ffffff;"><strong>Duration</strong></span></p>
    </td>
    <td style="border: 1px solid #000000; padding: 20px 20px 10px; background-color: #1c4587; text-align: center;">
    <p><span style="color: #ffffff;"><strong> Fees</strong></span></p>
    </td>
    </tr>
    <tr>
    <td style="border: 1px solid #000000; padding: 20px 20px 10px; background-color: #cfe2f3; width: 30%; text-align: center;">
    <p><span style="color: #1c458e;">5 months</span></p>
    </td>
    <td style="border: 1px solid #000000; padding: 20px 20px 10px; background-color: #cfe2f3; text-align: center;">
    <p><span style="color: #1c458e;">INR 52,000/- plus taxes</span></p>
    </td>
    </tr>
    </tbody>
    </table>
    </div>`
      ,
  },
  jobScope : [
    {
      longDescription: `<div class="menu-title-pg-diploma mb-3 mt-4 menu-title-pg-diploma-analytics"><h1>Data Analytics Career Graph in Gurgaon </h1></div></div><p>Gurgaon's tech scene makes it ideal for data analytics education. With its cutting-edge infrastructure, Gurgaon is India's prominent tech hub. Major tech companies like TCS, INFOSYS, AIRTEL, ICICI, HDFC, and others actively recruit business analytics professionals. </p><p>
      A recent report underscores the substantial growth anticipated in the data analytics industry, projecting the creation of over <a class="ct-lg-linkUpdate" target="_blank" href="https://www.ibef.org/blogs/scope-of-data-analytics-in-india-and-future">11 million</a> jobs by 2026.</p><p>
      Enrolling in a data analytics course in Gurgaon offers numerous advantages. You can network and collaborate with many tech companies and startups, particularly those specializing in data analytics. </p><p>
      This presents an opportunity to gain invaluable access to industry resources and expertise right at your doorstep. Imagine learning alongside potential colleagues and exploring internship opportunities within this dynamic ecosystem.</p><p>
      Data analysts have an average compensation of INR 4 to 10 LPA in Gurgaon, depending on their industry & experience. For more information, refer to our <a class="ct-lg-linkUpdate" target="_blank" href="https://www.analytixlabs.co.in/blog/data-science-101-guide/">Data Science Salary Report 2023</a>.</p><p>
      Overall, the career graph for a data analyst in Gurgaon looks like this:
      </p>`,
    },
  ],
  otherDescription: `<p class='mt-3'><b>*</b> The above numbers are the annual base pay for the said role</p>`,
  jobScopeOtherPart: [
  ]
}

const  DataAnalyticsGurgaonTable = {
  title: `Data Analytics Course in Gurgaon- Fees and Duration`,
  mobileViewTable:
  `<div class='pg-table-width'  style="border-collapse: collapse; width: 80%;">
  <table style="border-collapse: collapse; width: 100%; border: 1px solid #000000; padding: 10px 20px;">
  <tbody>
  <tr>
  <td style="border-right: 1px solid #000000; border-bottom: 1px solid #1c4587; padding: 25px 20px 10px; background-color: #1c4587; width: 35%;" rowspan="2">
  <p style="text-align:center"><span style="color: #ffffff;"><strong>Data Analytics Course in Gurgaon</strong></span></p>
  </td>
  <td style="border: 1px solid #000000; padding: 20px 20px 10px; background-color: #1c4587; text-align: center;">
  <p><span style="color: #ffffff;"><strong>Duration</strong></span></p>
  </td>
  <td style="border: 1px solid #000000; padding: 20px 20px 10px; background-color: #1c4587; text-align: center;">
  <p><span style="color: #ffffff;"><strong> Fees</strong></span></p>
  </td>
  </tr>
  <tr>
  <td style="border: 1px solid #000000; padding: 20px 20px 10px; background-color: #cfe2f3; width: 30%; text-align: center;">
  <p><span style="color: #1c458e;">5 months</span></p>
  </td>
  <td style="border: 1px solid #000000; padding: 20px 20px 10px; background-color: #cfe2f3; text-align: center;">
  <p><span style="color: #1c458e;">INR 1,19,000/- Plus GST</span></p>
  <p className="fee-ttl ct-font-size-12" style="font-size:14px; color: #1c458e;" >(Save up to 40% on fees*)</p>
  </td>
  </tr>
  </tbody>
  </table>
  </div>`
    ,
  longDescription: `<p class='mt-3'><span style="font-weight: 400;">Our data analytics course in Gurgaon has industry-standard fees. The course duration is <b>5 months</b> or <b>850 learning hours</b>, and the total fee is <b>INR 1,19,000</b><span style="font-weight: 400;"> Plus GST.</span></p>`,
}

const skillsAndToolsGurgaon = {
  title: 'Industries Covered & Functions',
  longDescription:
    '<p>Data analytics training Gurgaon explores real-time data analytics applications across diverse industries, including:</p>',
  descWithBg: [
    {
      label:
        'BFSI (Finance and Insurance), Healthcare, Telecom, Hospitality, Manufacturing, Retail/eCommerce',
    }
  ],
  LastDescription:
    `<p>Our data analytics course in Gurgaon encompasses various industries to prepare you for every business challenge. The course offers a cohesive learning of the various functions that require data analytics professionals, be it any industry you are in.<p>`,
}

const PGskillsAndToolsGurgaon = [
  {
    bulletPoint:[
      {
        title: `Marketing Analytics`,
        description: `<p>Data analytics is pivotal in understanding customer behavior, revealing the motivations driving their actions, and maximizing the overall return on investment (ROI) for marketing endeavors.</p><p>
        In this course, you will learn the techniques and methodologies for analyzing marketing data, identifying trends and patterns, and adopting an analytical approach to refining marketing campaigns. You will better understand user behavior and be able to facilitate smart marketing strategies and cultivate customer loyalty.
        </p>`,
      },
      {
        title: `Operational Analytics`,
        description: `<p>Operational analytics is designed to improve the efficiency of business operations and forms a subset of business analytics. It underscores the synchronization of data from the warehouse into various business tools, making it accessible to all employees, regardless of their technical proficiency.</p><p>
        This course will help you learn to monitor current and real-time operations to improve productivity and streamline daily business activities.
        </p>`,
      },
      {
        title: `Risk Analytics`,
        description: `<p>Risk analytics empowers individuals with the capability to quantify and predict risks precisely. Participants in this program will acquire the skills to leverage various technologies for exploring business data, creating scenarios driven by risk, and accurately predicting potential future risks.</p><p>
        The application of data analytics in risk management aids businesses in identifying, analyzing, and evaluating risks, thereby preventing large-scale debacles.
        </p>`,
      }
    ],
  },
]

const demoBrochureGurgaon = {
  header: 'DEMO & BROCHURE',
}
const feesRecordPGGurgaon = '70,000';

export {
  toolsList,
  toolsRecordGurgaon,
  courseDetailsGurgaon,
  bannerRecordGurgaon,
  faqsGurgaon,
  PGdetailsGurgaon,
  careerGraphGurgaon,
  DataAnalyticsCourseFeeDataGurgaon,
  skillsAndToolsGurgaon,
  MarketingAnalyticsGurgaon,
  PlacementAndSupportGurgaon,
  DataAnalyticsGurgaonTable,
  PGskillsAndToolsGurgaon,
  PGdetailsGurgaonSecondSections,
  demoBrochureGurgaon,
  feesRecordPGGurgaon,
  UpcomingBatchesGurgaon
}

// TODO: SampleArrayObject use in this file.

// const SampleArrayObject = {
//   mainTitle : ``,
//   title: ``,
//   needHelp: boolean,
//   BTNTitleShow: boolean,
//   longDescription: `<p></p>`,
//   bulletsPointTitle : ``,
//   bulletsPointDescription: `` ,
//   bulletPoint:[
//     {
//       title : ``,
//       description: ``,
//     }
//   ],
//   subDetails : [
//     {
//       title: ``,
//       longDescription: ``,
//       subBulletsPointTitle :``,
//       subBulletsPointDescription: `` ,
//       SubBulletPoint:[
//         {
//           title : ``,
//           description: ``
//         },
//       ]
//     },
//   ],
// }
// <a class="ct-lg-linkUpdate" target="_blank" href="">
