/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from "react";
import onlinePc from "assets/images/online-pc.svg";
import phone from "assets/images/footer/phone.png";
import {
  checkPPCPage,
  getDownloadBrochureModal,
  onCallUsClick
} from "../../../shared/function";
import { useModalTypeContext } from "context/modalTypeContext";
import download from "assets/images/svgs/download.png";
import { contactNumber10 } from "data/ppcData";
import { getSlugAfterFromUrlByIndex, typeForPPCBing } from "../../../constants";
import {pgDiploma} from 'views/upcomingBatche/data';
import {feesRecordPGDelhi} from 'data/pgDiplomaAnalyticsDelhi';
import UpComingBatches from '../UpComingBatches/UpComingBatches';
import {useRouteMatch} from 'react-router-dom/cjs/react-router-dom.min';
import {staticContentAllPages} from 'data/courseStaticPage';
// import {EMITextCourse} from 'data/courseStaticPage';


const CourseTimePlace = memo(() => {
  const route = useRouteMatch();
  const staticObject = staticContentAllPages.find(item=> item.slug === route?.url)
  const isStaticDataShow = staticObject?.slug === route?.url;
  var contactNumber = checkPPCPage()
    ? typeForPPCBing.includes(getSlugAfterFromUrlByIndex(2))
      ? contactNumber10.bingContactNumber
      : contactNumber10.ppcContactNumber
    : contactNumber10.otherContactNumber

  const { setModalType } = useModalTypeContext()
  let imagesList = [];

  imagesList.push(
    <div className="learning-modes-container">
      <a href="javascript:void(0);" aria-label="Offline Classroom">
        <img
          src={onlinePc}
          alt=""
          className=" ls-is-cached lazyloaded"
          style={{ width: 50, height: 50 }}
        />
      </a>
      <div className="learning-modes-container-amount ct-fnt-title-ai-static">
        {isStaticDataShow ? staticObject?.EMICourse : feesRecordPGDelhi}
      </div>
      {/* <p className="thin-fnt-title"
        dangerouslySetInnerHTML={{ __html:EMITextCourse}}
      >
      </p> */}
    </div>,
  )

  return (
    <section className="counting-sec mt-5 mb-5 pt-3">
      <div className="container mt-5  ">
        <div className="row">
          <div className="col-md-12 col-lg-4 mb-2 teblet-pb-1 center-text">
            {pgDiploma?.CourseBatches && pgDiploma?.CourseBatches.length > 0 && (
              <div className="count-li">
                <h5 className="date-ttl text-center">Upcoming batches</h5>
                <div className="col center-text d-flex justify-content-center">
                <UpComingBatches/>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-8 col-lg-4 mb-3">
            <div className="count-li">
              <h5 className="date-ttl date-ttl-center ct-mb-rem">PROGRAM FEE</h5>
              <div className="learning-modes-outer Pt-2">{imagesList}</div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 d-flex justify-content-center mb-3">
            <div className="count-li">
              <div className="right-ft ct-lg-btn-fontsize">
                <h5 className='ct-mb-rem ct-lg-font-size-26'>
                  Need help? <span>Call us</span>
                </h5>
                <a
                  href={`tel:+${contactNumber.otherContactNumber}`}
                  className="ft-call pt-2 ct-lg-btn-mt"
                  onClick={onCallUsClick}
                >
                  <img
                    className="lazyload"
                    src={phone}
                    width="15"
                    height="15"
                    alt=""
                  />
                  &nbsp;&nbsp;&nbsp;{`+91 ${contactNumber10?.otherContactNumber}`}
                </a>
                <div
                  className="dnld-btn ct-lg-downloadBTN ct-lg-btn-fontsize15"
                >
                  <a
                    className="ctc-btn teblet-down ft-call ct-lg-btn-download"
                    data-toggle="modal"
                    data-target="#download_brochure"
                    onClick={() =>
                      setModalType(
                        getDownloadBrochureModal(
                          'downloadBrochure',
                          'downloadBrochure',
                          'downloadBrochure',
                          'downloadBrochure',
                          'ppcBingDownloadBrochure',
                          'ppcBrandDownloadBrochure',
                          'ppcGhBlrDownloadBrochure',
                          'ppcGhNcrDownloadBrochure',
                          'ppcDsBlrDownloadBrochure',
                          'downloadBrochure',
                        ),
                      )
                    }
                  >
                    <img
                      className="lazyloaded"
                      src={download}
                      width="15"
                      height="15"
                      alt=""
                    />{' '}
                       Download Brochure
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
);
})
export default CourseTimePlace;
