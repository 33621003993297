export const GenerativeAICourseData = {
  dummy: false,
  bannerRecord: {
    courseID: 73,
    name: "Generative AI course",
    bannerType: null,
    robotImage: "image/course/iitg-web-icon-logo-17144720123276.webp",
    courseDetailsImage: "image/course/iitg-web-icon-logo-17144720123276.webp",
    introURL: "",
    bannerFileName: "image/course/iitg-web-banner-17141983594556.webp",
    hoursCount: "16",
    classCount: "63"
  },
  toolsRecord: [
    {
      toolID: 3,
      Tool: {
        toolID: 3,
        title: "Python",
        smallImage: "image/toolsImage/asset12tools-1596020742031.png",
        link: null,
        largeImage: "image/toolsImage/tool-lg1-1596020742035.png"
      }
    },
    {
      toolID: 3,
      Tool: {
        toolID: 3,
        title: "NumPy",
        smallImage: "/image/toolsImage/asset2tools-1595857644273.png",
        link: null,
        largeImage: "/image/toolsImage/asset2tools-1595857644274.png"
      }
    },
    {
      toolID: 11,
      Tool: {
        toolID: 11,
        title: "Jira",
        smallImage: "image/toolsImage/Untitled-17%20(3)-1715082494525.png",
        link: null,
        largeImage: "image/toolsImage/Untitled-17%20(3)%20(1)-1715082755901.png"
      }
    },
    {
      toolID: 15,
      Tool: {
        toolID: 15,
        title: "Pandas",
        smallImage: "image/toolsImage/pandas-1601020776153.jpg",
        link: null,
        largeImage: "image/toolsImage/pandas-1601020776154.jpg"
      }
    },
    {
      toolID: 18,
      Tool: {
        toolID: 18,
        title: "nltk",
        smallImage: "image/toolsImage/nltk-1601021248569.png",
        link: null,
        largeImage: "image/toolsImage/nltk-1601021248570.png"
      }
    },
    {
      toolID: 7,
      Tool: {
        toolID: 7,
        title: "Cloud computing",
        smallImage: "image/toolsImage/tool_cc-1596020845393.png",
        link: null,
        largeImage: "image/toolsImage/tool_cc-1596020845394.png"
      }
    },
    {
      toolID: 1,
      Tool: {
        toolID: 1,
        title: "github",
        smallImage: "image/toolsImage/GitHub-logo-1715080153265.png",
        link: null,
        largeImage: "image/toolsImage/GitHub-logo-1715080153265.png"
      }
    },
    {
      toolID: 17,
      Tool: {
        toolID: 17,
        title: "nlp",
        smallImage: "image/toolsImage/Untitled-17%20(2)-1715082512817.png",
        link: null,
        largeImage: "image/toolsImage/Untitled-17%20(2)%20(1)-1715082746282.png"
      }
    },
    {
      toolID: 39,
      Tool: {
        toolID: 39,
        title: "git",
        smallImage: "image/toolsImage/Git-logo-1707482493038.png",
        link: null,
        largeImage: "image/toolsImage/Git-logo-1707482493040.png"
      }
    }
  ],
  sidebarTitles: {
    courseSidebarID: 11,
    courseID: 73,
    overview: "Course Overview",
    curriculum: "Course Curriculum",
    whoShouldDo: "Generative AI Course for Business Applications: Eligibility",
    jobRoles: "Top Job Roles to Explore",
    keySkills: "Top Skills Covered in This Course",
    capstoneProject: "AI engineering Capstone Projects",
    deliveryFormat:
      "Artificial Intelligence online course - Learning Methodologies",
    certification: "Certification Details",
    feeDetails: "Course Fees and Admission",
    careerSupport: "Career Support by AnalytixLabs",
    successStories: "Career stories by AnalytixLabs",
    demoAndBrochure: "Demo & Brochure",
    upcomingBatches: "Upcoming Batches",
    FAQAndBlog: "FAQs & Blogs",
    createdAt: "2021-07-16T11:58:29.000Z",
    updatedAt: "2022-05-20T05:16:49.000Z",
    careerPath: "Career Paths",
    learningBenefits: "Learning Benefits"
  },
  upcomingBatchResult: [
    {
      courseBatchID: 925,
      LMSBatchID: null,
      courseID: 73,
      instructorID: null,
      learningModeID: 2,
      name: "Python for Data Science- Gurgaon '24",
      description: null,
      seat: 4,
      cityID: 4,
      startDate: "2024-05-05T00:00:00.000Z",
      batchTime: "10:00 AM - 1:00 PM",
      isInteractive: true,
      showOnWebsite: 1,
      sortOrder: 0,
      isActive: true,
      isDeleted: false,
      createdBy: 345,
      updatedBy: null,
      modifiedDate: "2024-04-20T09:25:03.000Z",
      modifiedUser: 345,
      createdAt: "2023-03-15T09:12:44.000Z",
      updatedAt: "2024-04-20T09:25:03.000Z",
      City: {
        cityID: 4,
        name: "Gurgaon"
      }
    },
    {
      courseBatchID: 924,
      LMSBatchID: null,
      courseID: 73,
      instructorID: null,
      learningModeID: 2,
      name: "Python for Data Science- Bangalore '24",
      description: null,
      seat: 15,
      cityID: 1,
      startDate: "2024-05-19T00:00:00.000Z",
      batchTime: "2:00 PM - 5:00 PM",
      isInteractive: true,
      showOnWebsite: 1,
      sortOrder: 0,
      isActive: true,
      isDeleted: false,
      createdBy: 345,
      updatedBy: null,
      modifiedDate: "2024-04-16T06:52:48.000Z",
      modifiedUser: 345,
      createdAt: "2023-03-15T09:12:06.000Z",
      updatedAt: "2024-04-16T06:52:48.000Z",
      City: {
        cityID: 1,
        name: "Bangalore"
      }
    }
  ],
  courseIncludedRecord: [
    {
      courseID: 63,
      name: "Data Science using Python",
      slug: "data-science-using-python",
      hoursCount: "570",
      classCount: "43",
      courseType: 1,
      bannerFileName:
        "image/course/iitg-web-banner-1713875251999-1714199901805.webp",
      robotImage: "image/course/thumbnail-1616501891.svg",
      courseDetailsImage:
        "image/course/iitg-web-icon-logo-1714134463298-1714199689299.png",
      CourseTags: [
        {
          tagID: 4,
          Tag: {
            name: "DS360"
          }
        },
        {
          tagID: 1,
          Tag: {
            name: "BA360"
          }
        }
      ],
      learningMode: [
        {
          learningModeID: 1,
          name: "Classroom & Bootcamps",
          image: "image/learningMode/img-1.png",
          description:
            "Experiential learning with in-person mentorship!\r\n\r\n",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        },
        {
          learningModeID: 2,
          name: "Classroom with ILT Blended",
          image: "image/learningMode/img-2.png",
          description:
            "Learn from the best with unrestricted support, wherever you are!\r\n\r\n",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        },
        {
          learningModeID: 3,
          name: "Blended eLearning",
          image: "image/learningMode/img-3.png",
          description: "Learn at your pace with on-demand lectures & Support!",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        }
      ],
      amountINR: 35000,
      discountType: 1,
      discountAmount: 0,
      upcomingBatch: {
        courseID: 63,
        learningModeID: 2,
        startDate: "2024-05-05T00:00:00.000Z"
      }
    },
    {
      courseID: 65,
      name: "Machine Learning using Python",
      slug: "machine-learning-course-certification-training",
      hoursCount: "570",
      classCount: "43",
      courseType: 2,
      bannerFileName:
        "image/course/iitg-web-banner-1713875251999-1714199901805-1714562347744.webp",
      robotImage: "image/course/website-banners-creatives-alabs-07 copy.svg",
      courseDetailsImage:
        "image/course/iitg-web-icon-logo-1714134463298-1714199689299-1714562347747.png",
      CourseTags: [
        {
          tagID: 2,
          Tag: {
            name: "ABDS"
          }
        },
        {
          tagID: 4,
          Tag: {
            name: "DS360"
          }
        },
        {
          tagID: 3,
          Tag: {
            name: "AIML"
          }
        }
      ],
      learningMode: [
        {
          learningModeID: 1,
          name: "Classroom & Bootcamps",
          image: "image/learningMode/img-1.png",
          description:
            "Experiential learning with in-person mentorship!\r\n\r\n",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        },
        {
          learningModeID: 2,
          name: "Classroom with ILT Blended",
          image: "image/learningMode/img-2.png",
          description:
            "Learn from the best with unrestricted support, wherever you are!\r\n\r\n",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        },
        {
          learningModeID: 3,
          name: "Blended eLearning",
          image: "image/learningMode/img-3.png",
          description: "Learn at your pace with on-demand lectures & Support!",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        }
      ],
      amountINR: 20000,
      discountType: 1,
      discountAmount: 0,
      upcomingBatch: {}
    },
    {
      courseID: 68,
      name: "Certified Big Data Engineer",
      slug: "big-data-analytics-hadoop-spark-training-course-online",
      hoursCount: "210",
      classCount: "30",
      courseType: 1,
      bannerFileName:
        "image/course/machine-learning-course-certification-training1-1616494453966.jpg",
      robotImage: "image/course/Thumbnail-1616494453967.svg",
      courseDetailsImage:
        "image/course/certified-big-data-engineer-1657623430377.png",
      CourseTags: [
        {
          tagID: 2,
          Tag: {
            name: "ABDS"
          }
        },
        {
          tagID: 5,
          Tag: {
            name: "AIE"
          }
        }
      ],
      learningMode: [
        {
          learningModeID: 1,
          name: "Classroom & Bootcamps",
          image: "image/learningMode/img-1.png",
          description:
            "Experiential learning with in-person mentorship!\r\n\r\n",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        },
        {
          learningModeID: 2,
          name: "Classroom with ILT Blended",
          image: "image/learningMode/img-2.png",
          description:
            "Learn from the best with unrestricted support, wherever you are!\r\n\r\n",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        },
        {
          learningModeID: 3,
          name: "Blended eLearning",
          image: "image/learningMode/img-3.png",
          description: "Learn at your pace with on-demand lectures & Support!",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        }
      ],
      amountINR: 25000,
      discountType: 1,
      discountAmount: 0,
      upcomingBatch: {}
    }
  ],
  UserReviewsRecord: [],
  courseCurriculumRecord: {
    curriculumDescription: `<p class="counrse-contn-1">Unlike other gen AI courses, our Generative AI for Business Applications Course is practical yet comprehensive. The modules are carefully designed to ensure you learn all the necessary tools and skills, including major soft skills like communication and collaboration. Our course module is a complete package, preparing you for real challenges, one at a time.</p>`,
    CourseCurriculums: [
      {
        courseID: 73,
        courseCurriculumID: 1228,
        name: "Introduction to AI & GenAI Landscape",
        sortOrder: 0,
        CourseCurriculumDetails: [
          {
            courseCurriculumDetailsID: 5518,
            courseCurriculumID: 1228,
            title:
              "Introduction to AI & GenAI Landscape",
            description: `
            <p style="margin:16px 0 8px;">Overview of AI and Machine Learning</p>
              <ul class='pg-lg-ul-r-20'>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Explain the role of ML in AI/Generative AI
                      </span>
                  </li>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Connect NLP fundamentals with advanced Generative AI applications
                      </span>
                  </li>
              </ul>
            <p style="margin:16px 0 8px;">Core Concepts of Generative AI</p>
              <ul class='pg-lg-ul-r-20'>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Difference between generative and discriminative models.
                      </span>
                  </li>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Explore the history and evolution of generative AI
                      </span>
                  </li>
              </ul>
            <p style="margin:16px 0 8px;">Types of Generative Models</p>
              <ul class='pg-lg-ul-r-20'>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Understanding GANs, VAEs, Transformers
                      </span>
                  </li>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Understand the functionality of large language models
                      </span>
                  </li>
              </ul>
            <p style="margin:16px 0 8px;">Practical Demonstrations</p>
              <ul class='pg-lg-ul-r-20'>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Usage of AI tools like ChatGPT and DALL-E for hands-on learning.
                      </span>
                  </li>
              </ul>
            <p style="margin:16px 0 8px;">Technical Foundations of Gen AI</p>
              <ul class='pg-lg-ul-r-20'>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Introduction to Large Language Models (LLMs)
                      </span>
                  </li>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Introduce attention mechanisms and the GPT series.
                      </span>
                  </li>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Implementing Generative AI
                      </span>
                  </li>
              </ul>
            <p style="margin:16px 0 8px;">Prompt Engineering</p>
              <ul class='pg-lg-ul-r-20'>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Define prompt engineering and its significance
                      </span>
                  </li>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        LLMs and the genesis of Prompting
                      </span>
                  </li>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Designing prompts for business use cases using playground templates
                      </span>
                  </li>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Prompting techniques (Prompt templates, precise instructions, chain of thought prompting)
                      </span>
                  </li>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Ideating for prompts (prompt generation by induction, prompt paraphrasing)
                      </span>
                  </li>
              </ul>
            <p style="margin:16px 0 8px;">Leveraging Gen AI for Improving Productivity</p>
            <p style="margin:8px 0 8px;">Tech Stack for Generative AI Applications</p>
              
              `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T05:55:57.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          }
        ]
      },
      {
        courseID: 73,
        courseCurriculumID: 1240,
        name: "Business Applications of Generative AI",
        sortOrder: 2,
        CourseCurriculumDetails: [
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Business Applications of Generative AI",
            description: `
            <p style="margin:16px 0 8px;">Applications of Gen AI in Various Industries</p>
              <ul class='pg-lg-ul-r-20'>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                         Marketing and Content Creation for Advertising
                      </span>
                      <ul class='pg-lg-ul-r-20'>
                        <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                            <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                              Generate personalized content and optimize advertising campaigns.
                            </span>
                        </li>
                      </ul>
                  </li>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                    <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Customer Support Automation
                    </span>
                      <ul class='pg-lg-ul-r-20'>
                        <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                            <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                              Implement AI chatbots and automated ticketing solutions.
                            </span>
                        </li>
                      </ul>
                  </li>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                    <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Product Development and Design
                    </span>
                      <ul class='pg-lg-ul-r-20'>
                        <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                            <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                              Utilize AI for product ideation and rapid prototyping.
                            </span>
                        </li>
                      </ul>
                  </li>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                    <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Financial Services
                    </span>
                      <ul class='pg-lg-ul-r-20'>
                        <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                            <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                              Apply AI in detecting fraud and managing risks
                            </span>
                        </li>
                      </ul>
                  </li>
                </ul>
              <p style="margin:16px 0 8px;">Framework for GenAI Opportunities</p>
                <ul class='pg-lg-ul-r-20'>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                    <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Design Thinking in AI Solution Development
                    </span>
                  </li>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                    <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Prototyping and Validating GenAI Concepts for Business
                    </span>
                  </li>
                </ul>
             `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          }
        ]
      },
      {
        courseID: 73,
        courseCurriculumID: 1240,
        name: "Developing a Generative AI Strategy",
        sortOrder: 2,
        CourseCurriculumDetails: [
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Strategic GenAI Implementation:",
            description: `
            <p style="margin:16px 0 8px;">Strategic GenAI Implementation:</p>
              <ul class='pg-lg-ul-r-20'>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Assess the impact of GenAI on business strategies.
                      </span>
                  </li>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Develop AI-driven business models.
                      </span>
                  </li>
              </ul>
             `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          }
        ]
      },
      {
        courseID: 73,
        courseCurriculumID: 1240,
        name: "Planning and Managing AI Projects",
        sortOrder: 2,
        CourseCurriculumDetails: [
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Planning and Managing AI Projects",
            description: `
            <p style="margin:16px 0 8px;">AI Project Lifecycle:</p>
              <ul class='pg-lg-ul-r-20'>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Understand the stages of AI project management.
                      </span>
                  </li>
              </ul>
            <p style="margin:16px 0 8px;">Scaling AI Initiatives:</p>
              <ul class='pg-lg-ul-r-20'>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Learn strategies for scaling and maintaining AI projects.
                      </span>
                  </li>
              </ul>
            <p style="margin:16px 0 8px;">Evaluating AI Impact:</p>
              <ul class='pg-lg-ul-r-20'>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Measure the ROI and impact of AI projects.
                      </span>
                  </li>
              </ul>
             `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          }
        ]
      },
      {
        courseID: 73,
        courseCurriculumID: 1240,
        name: "The Future of Generative AI",
        sortOrder: 2,
        CourseCurriculumDetails: [
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "The Future of Generative AI",
            description: `
            <p style="margin:16px 0 8px;">Future Applications of AI:</p>
              <ul class='pg-lg-ul-r-20'>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Explore future trends and applications in generative AI.
                      </span>
                  </li>
              </ul>
            <p style="margin:16px 0 8px;">Ethical Considerations:</p>
              <ul class='pg-lg-ul-r-20'>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Discuss ethics, bias, and regulation in AI.
                      </span>
                  </li>
              </ul>
            <p style="margin:16px 0 8px;">Integration with Emerging Technologies:</p>
              <ul class='pg-lg-ul-r-20'>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Understand how generative AI integrates with other technologies.
                      </span>
                  </li>
              </ul>
            <p style="margin:16px 0 8px;">Preparing for Future Innovations:</p>
              <ul class='pg-lg-ul-r-20'>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Equip learners with insights on future advancements in AI.
                      </span>
                  </li>
              </ul>
             `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          }
        ]
      },
      {
        courseID: 73,
        courseCurriculumID: 1240,
        name: "Domain-specific AI Implementation",
        sortOrder: 2,
        CourseCurriculumDetails: [
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Domain-specific AI Implementation",
            description: `
            <p style="margin:16px 0 8px;">Capstone Project</p>
              <ul class='pg-lg-ul-r-20'>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Define, scope, and implement a real-world AI project.
                      </span>
                  </li>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Present and evaluate project outcomes.
                      </span>
                  </li>
              </ul>
             `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          }
        ]
      },
      {
        courseID: 73,
        courseCurriculumID: 1240,
        name: "Leveraging Microsoft AI Solutions (Optional)",
        sortOrder: 2,
        CourseCurriculumDetails: [
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Leveraging Microsoft AI Solutions (Optional)",
            description: `
            <p style="margin:16px 0 8px;">Using Microsoft AI Tools:</p>
              <ul class='pg-lg-ul-r-20'>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Explore how Microsoft AI tools can transform business operations.
                      </span>
                  </li>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Understand the functionalities of Microsoft 365 Copilot.
                      </span>
                  </li>
              </ul>
            <p style="margin:16px 0 8px;">Ensuring Data Privacy and Security:</p>
              <ul class='pg-lg-ul-r-20'>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Manage data security and compliance for AI applications.
                      </span>
                  </li>
              </ul>
            <p style="margin:16px 0 8px;">Effective Use of Copilot:</p>
              <ul class='pg-lg-ul-r-20'>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Learn to craft effective prompts for Microsoft 365 Copilot.
                      </span>
                  </li>
              </ul>
             `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          }
        ]
      },

    ]
  },
  whoShouldDoRecord: {
    whoShouldDo: `<p class="counrse-contn-1 ct-sm-left">Our Generative AI online course for Business Applications is for anyone eager to harness the power of AI for business growth. From data analysts, IT professionals, and business analysts to entrepreneurs - this course is ideal for anyone looking to learn generative AI to solve complex business problems and deliver optimum results. </p>
    <p class="counrse-contn-1 ct-sm-left">

    While the course modules will cover all the basic concepts, we still recommend a basic understanding of statistical concepts and programming languages (Python or R), but not mandatory. Enthusiasm for learning new technologies and a problem-solving mindset are mandated for this course.</p>
    <p class="counrse-contn-1 ct-sm-left">

    Unleash the power of Generative AI for business with our comprehensive certification course. Discover how to create, innovate, and transform your organization with this cutting-edge technology.</p>
    <p class="counrse-contn-1 ct-sm-left">

    We also have subject-specific certification courses that will teach you the technical aspects of data science and AI. Explore relevant courses - 
    (hyperlink courses)</p>

      <ul class="cours-ovr-ul">
        <li class="drc-blcr" style='list-style:none; text-align:left;'>
          <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
            <a href="https://www.analytixlabs.co.in/data-science-specialization-course" class="ct-lg-linkUpdate ct-font-link-16" target="_blank">
            Data Science Certification Course
            </a>
          </p>
        </li>
        <li class="blcr" style='list-style:none; text-align:left;'>
          <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
            <a href="https://www.analytixlabs.co.in/artificial-intelligence-certification-courses-online" class="ct-lg-linkUpdate ct-font-link-16" target="_blank">
            Full-Stack Applied AI Course
            </a>
          </p>
        </li>
        <li class="drc-blcr" style='list-style:none; text-align:left;'>
          <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
            <a href="https://www.analytixlabs.co.in/business-analytics-data-science-course" class="ct-lg-linkUpdate ct-font-link-16" target="_blank">
            Business Analytics Certification Course
            </a>
          </p>
        </li>
        <li class="blcr" style='list-style:none; text-align:left;'>
          <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
            <a href="https://www.analytixlabs.co.in/machine-learning-course-certification-training" class="ct-lg-linkUpdate ct-font-link-16" target="_blank">
              Machine Learning with Python Certification Course
            </a>
          </p>
        </li>
        <li class="drc-blcr" style='list-style:none; text-align:left;'>
          <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
            <a href="https://www.analytixlabs.co.in/pg-in-data-analytics" class="ct-lg-linkUpdate ct-font-link-16" target="_blank">
            Data Analytics Course
            </a>
          </p>
        </li>
      </ul>
      `,
    CourseWhoShouldDos: []
  },
  jobRolesAndSkills: {
    keyDescription:
      "This course empowers managers and leaders with technical expertise and strategic acumen. By mastering technical and soft skills, you will learn to navigate the AI landscape and drive business transformation.",
    jobRoles: [
      {
        jobRoleID: 21,
        JobRole: {
          jobRoleID: 21,
          name: "AI Engineer"
        }
      },
      {
        jobRoleID: 22,
        JobRole: {
          jobRoleID: 22,
          name: "Machine Learning Engineer"
        }
      },
      {
        jobRoleID: 23,
        JobRole: {
          jobRoleID: 23,
          name: "Robotics Specialist"
        }
      },
      {
        jobRoleID: 24,
        JobRole: {
          jobRoleID: 24,
          name: "Computer Vision Engineer"
        }
      },
      {
        jobRoleID: 25,
        JobRole: {
          jobRoleID: 25,
          name: "Data Scientist"
        }
      },
      {
        jobRoleID: 26,
        JobRole: {
          jobRoleID: 26,
          name: "AI-ML Specialist"
        }
      },
      {
        jobRoleID: 27,
        JobRole: {
          jobRoleID: 27,
          name: "Data Science Consultant"
        }
      },
      {
        jobRoleID: 28,
        JobRole: {
          jobRoleID: 28,
          name: "Analytics Consultant"
        }
      }
    ],
    keySkills: [
      {
        keySkillID: 115,
        KeySkill: {
          keySkillID: 115,
          name: "Large-Scale Analytics on Cloud",
          image: null
        }
      },
      {
        keySkillID: 116,
        KeySkill: {
          keySkillID: 116,
          name: "Predictive Modelling using Neural Network",
          image: null
        }
      },
      {
        keySkillID: 117,
        KeySkill: {
          keySkillID: 117,
          name: "Image Processing Using Deep Learning Techniques",
          image: null
        }
      },
      {
        keySkillID: 118,
        KeySkill: {
          keySkillID: 118,
          name: "Natural Language Processing & Generation (Chatbots)",
          image: null
        }
      },
      {
        keySkillID: 119,
        KeySkill: {
          keySkillID: 119,
          name: "Deep Learning Modelling for AI Applications",
          image: null
        }
      },
      {
        keySkillID: 120,
        KeySkill: {
          keySkillID: 120,
          name: "Data Science Methods",
          image: null
        }
      },
      {
        keySkillID: 121,
        KeySkill: {
          keySkillID: 121,
          name: "Supervised Learning Models",
          image: null
        }
      },
      {
        keySkillID: 122,
        KeySkill: {
          keySkillID: 122,
          name: "Unsupervised Learning Models",
          image: null
        }
      }
    ],
    keySkillsHTMLTable: `
    <div class='counrse-contn'>
        <p class="counrse-contn-1 ct-sm-left mt-2 mb-3">
          Completing our Generative AI training for Business Applications certification course will open doors to various exciting roles. 
        </p>
        <ul class="cours-ovr-ul">
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Generative AI Engineer: 
                </span>
              </b>
              Develop, train, and deploy generative AI models for business applications.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Product Manager: 
                </span>
              </b>
              Oversee the development and launch of AI-powered products and services.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Machine Learning Engineer: 
                </span>
              </b>
              Build and maintain the machine learning infrastructure for generative AI systems.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  NLP Engineer: 
                </span>
              </b>
              Focus on natural language processing techniques for AI applications.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Data Scientist: 
                </span>
              </b>
              Utilize generative AI to extract insights from data and drive business decisions.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Business Analyst: 
                </span>
              </b>
              Bridge the gap between business needs and AI solutions.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Strategist: 
                </span>
              </b>
              Develop AI strategies aligned with overall business objectives.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Consultant: 
                </span>
              </b>
              Provide AI expertise to organizations seeking to implement generative AI.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Project Manager: 
                </span>
              </b>
              Manage AI projects from inception to deployment.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Ethics Officer: 
                </span>
              </b>
              Ensure responsible and ethical use of generative AI.
            </p>
          </li>
        </ul>
        </div>

    `,
    keySkillsHTML: `
     <div class='counrse-contn'>
        <p class="counrse-contn-1 ct-sm-left mt-2 mb-3">
          This curriculum positions you for leadership roles that demand a strategic, data-driven approach. You'll be well-prepared for positions like:
        </p>
        <ul class="cours-ovr-ul ct-cours-ovr-ul">
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Strategy Lead: 
                </span>
              </b>
              Develop and implement AI strategies across the organization.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Project Manager: 
                </span>
              </b>
              Oversee AI initiatives from conception to deployment.
            </p>
          </li>

          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Head of Data and Analytics: 
                </span>
              </b>
              Lead data-driven decision-making and AI initiatives.
            </p>
          </li>

          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Digital Transformation Officer: 
                </span>
              </b>
              Drive business transformation leveraging AI and technology.
            </p>
          </li>

          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Business Unit Head: 
                </span>
              </b>
              Lead business units with a focus on AI-driven innovation.
            </p>
          </li>

          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Chief AI Officer (CAIO): 
                </span>
              </b>
              Oversee an organization's AI strategy and implementation.
            </p>
          </li>

          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Ethics Officer: 
                </span>
              </b>
              Ensure ethical and responsible use of AI in the organization.
            </p>
          </li>

          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Product Manager: 
                </span>
              </b>
              Look into developing and launching AI-powered products and services.
            </p>
          </li>

        </ul>
    </div>
    `,
    jobRolesAndSkillsHTML: `
     <div class='counrse-contn'>

      <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
        This course lays the groundwork for your Generative AI learning path, equipping you with the technical expertise and business acumen needed to harness the power of this transformative technology.
      </p>
      <h3 style='color: #013368 ;'>Technical Skills</h3>
        <ul class="cours-ovr-ul ct-cours-ovr-ul">
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI and Machine Learning Fundamentals: 
                </span>
              </b>
              Understanding of core concepts, algorithms, and techniques.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Deep Learning: 
                </span>
              </b>
              Knowledge of neural networks, architectures (CNNs, RNNs, Transformers), and training methodologies.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Natural Language Processing (NLP): 
                </span>
              </b>
              Familiarity with text processing, sentiment analysis, and language models.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Generative Models: 
                </span>
              </b>
              Understanding of GANs, VAEs, and Transformers.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Large Language Models (LLMs): 
                </span>
              </b>
              Knowledge of LLM architecture, functioning, and applications.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Prompt Engineering: 
                </span>
              </b>
              Ability to design and optimize prompts for LLMs.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Python Programming: 
                </span>
              </b>
              Proficiency in Python for data manipulation, model development, and experimentation.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Cloud Platforms: 
                </span>
              </b>
              Familiarity with cloud-based AI tools and platforms (e.g., AWS, GCP, Azure).
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Data Analysis and Visualization: 
                </span>
              </b>
              Data exploration, cleaning, and visualization skills.
            </p>
          </li>
        </ul>
      

      <h3 style='color: #013368 ;'>Soft Skills</h3>

      <ul class="cours-ovr-ul ct-cours-ovr-ul">
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Problem-solving and Critical Thinking: 
                </span>
              </b>
              Ability to analyze complex problems and develop effective solutions.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Creativity and Innovation: 
                </span>
              </b>
              Capacity to generate new ideas and approaches.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Business Acumen: 
                </span>
              </b>
              Understanding of business operations and how AI can drive value.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Communication and Presentation: 
                </span>
              </b>
              Ability to articulate complex technical concepts to non-technical audiences.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Project Management: 
                </span>
              </b>
              Skills in planning, executing, and delivering AI projects.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Teamwork and Collaboration: 
                </span>
              </b>
              Ability to work effectively in cross-functional teams.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Ethical Considerations: 
                </span>
              </b>
              Understanding of AI ethics and responsible AI practices.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Lifelong Learning: 
                </span>
              </b>
              Commitment to staying updated with the latest AI advancements.
            </p>
          </li>
      </ul>
    </div>
    `,
    allScreenSizeSameKeySkill: true
  },
  deliveryFormatRecord: [
    {
      learningModeID: 1,
      LearningMode: {
        learningModeID: 1,
        name: "Classroom & Bootcamps",
        image: "image/learningMode/img-1.png",
        description: "Experiential learning with in-person mentorship!\r\n\r\n"
      }
    },
    {
      learningModeID: 2,
      LearningMode: {
        learningModeID: 2,
        name: "Classroom with ILT Blended",
        image: "image/learningMode/img-2.png",
        description:
          "Learn from the best with unrestricted support, wherever you are!\r\n\r\n"
      }
    },
    {
      learningModeID: 3,
      LearningMode: {
        learningModeID: 3,
        name: "Blended eLearning",
        image: "image/learningMode/img-3.png",
        description: "Learn at your pace with on-demand lectures & Support!"
      }
    }
  ],
  courseCertificationRecord: {
    CourseCertifications: [
      {
        courseID: 73,
        certificationID: 231,
        Certification: {
          certificationID: 231,
          name:
            "The E & ICT Academy at IIT Guwahati, supported by the Ministry of Electronics and Information Technology, Government of India, is collaborating with AnalytixLabs as an industry partner.",
          description: null,
          image: null,
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-09-26T18:26:07.000Z",
          updatedAt: "2023-10-14T08:40:30.000Z"
        }
      },
      {
        courseID: 74,
        certificationID: 232,
        Certification: {
          certificationID: 232,
          name:
            "AnalytixLabs certifications are highly regarded in the industry due to our extensive domain expertise. As India’s leading Data Science institute, we must maintain the integrity of our certification process.",
          description: null,
          image: null,
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-09-26T18:26:07.000Z",
          updatedAt: "2023-10-14T08:40:30.000Z"
        }
      },
      {
        courseID: 75,
        certificationID: 233,
        Certification: {
          certificationID: 233,
          name:
            "Certification will be granted only upon meeting specified requirements within the course timelines, which include case studies, multiple-choice questions (MCQs), vivas, etc. Each candidate will have two attempts to pass each assessment.",
          description: null,
          image: null,
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-09-26T18:26:07.000Z",
          updatedAt: "2023-10-14T08:40:30.000Z"
        }
      },
      {
        courseID: 76,
        certificationID: 234,
        Certification: {
          certificationID: 234,
          name:
            "We aim to provide trainees with essential hands-on experience to prepare them for industry challenges.",
          description: null,
          image: null,
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-09-26T18:26:07.000Z",
          updatedAt: "2023-10-14T08:40:30.000Z"
        }
      },
      {
        courseID: 76,
        certificationID: 234,
        Certification: {
          certificationID: 234,
          name:
            "Certification must be secured within one year of course registration.",
          description: null,
          image: null,
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-09-26T18:26:07.000Z",
          updatedAt: "2023-10-14T08:40:30.000Z"
        }
      }
    ],
    CourseCertificationImages: [
      {
        courseID: 73,
        certificationImage:
          "image/certificateImage/AI_for_Managers_and_Leaders_240827.jpg"
      }
      // {
      //   courseID: 73,
      //   certificationImage:
      //     "image/certificateImage/Advanced_Certification_in_Full_Stack_Applied_AI_1.jpg"
      // }
    ]
  },
  feesRecord: [
    {
      extraText: "(50% Pay after placement)",
      courseLearningModeID: 4199,
      courseID: 73,
      learningModeID: 1,
      offerName: null,
      offerDescription: null,
      offerImage: null,
      amountINR: 30000,
      amountUSD: 950,
      isInstallment: false,
      amountFirstInstallmentINR: null,
      amountSecondInstallmentINR: null,
      amountFirstInstallmentUSD: null,
      amountSecondInstallmentUSD: null,
      discountType: 1,
      isRecommended: false,
      discountAmount: 0,
      discountStartDate: null,
      discountEndDate: null,
      sortOrder: 0,
      isActive: true,
      isDeleted: false,
      createdBy: null,
      updatedBy: null,
      createdAt: "2023-10-31T06:49:55.000Z",
      updatedAt: "2023-10-31T06:49:55.000Z",
      LearningMode: {
        name: "Classroom & Bootcamps"
      }
    },
    {
      extraText: "(50% Pay after placement)",
      courseLearningModeID: 4197,
      courseID: 73,
      learningModeID: 2,
      offerName: null,
      offerDescription: null,
      offerImage: null,
      amountINR: 30000,
      amountUSD: 950,
      isInstallment: false,
      amountFirstInstallmentINR: null,
      amountSecondInstallmentINR: null,
      amountFirstInstallmentUSD: null,
      amountSecondInstallmentUSD: null,
      discountType: 1,
      isRecommended: false,
      discountAmount: 0,
      discountStartDate: null,
      discountEndDate: null,
      sortOrder: 0,
      isActive: true,
      isDeleted: false,
      createdBy: null,
      updatedBy: null,
      createdAt: "2023-10-31T06:49:55.000Z",
      updatedAt: "2023-10-31T06:49:55.000Z",
      LearningMode: {
        name: "Classroom with ILT Blended"
      }
    },
    {
      extraText: "(50% Pay after placement)",
      courseLearningModeID: 4198,
      courseID: 73,
      learningModeID: 3,
      offerName: null,
      offerDescription: null,
      offerImage: null,
      amountINR: 30000,
      amountUSD: 800,
      isInstallment: false,
      amountFirstInstallmentINR: null,
      amountSecondInstallmentINR: null,
      amountFirstInstallmentUSD: null,
      amountSecondInstallmentUSD: null,
      discountType: 1,
      isRecommended: false,
      discountAmount: 0,
      discountStartDate: null,
      discountEndDate: null,
      sortOrder: 0,
      isActive: true,
      isDeleted: false,
      createdBy: null,
      updatedBy: null,
      createdAt: "2023-10-31T06:49:55.000Z",
      updatedAt: "2023-10-31T06:49:55.000Z",
      LearningMode: {
        name: "Blended eLearning"
      }
    }
  ],
  downloadBrochureResult: {
    courseID: 73,
    name: "Artificial Intelligence Engineering",
    brochureFile:
      "image/brochure/AnalytixLabs - AI Engineering Specialization-1626437470670.pdf",
    demoBrochureHTML: `
      <p class="counrse-contn-1 ct-sm-left" ><b>Uncertain about the best generative AI course online?</b> Try our teaching methodology firsthand with a free demo account. Then, gain exclusive access to discounted specialization tracks and job-oriented programs curated by industry experts.</p>
      `
  },
  courseFaqRecord: {
    faqRecord: [
      {
        FaqID: 1016,
        description: `<p>While not strictly mandatory, a foundational understanding of statistics, programming, and machine learning will significantly enhance your generative AI education. A grasp of statistical concepts will aid in data analysis and model evaluation. </p><p>

        Proficiency in programming, particularly Python, is beneficial for hands-on projects and implementing AI solutions. Basic knowledge of machine learning principles will provide a solid base for understanding generative models. </p><p>

        However, the course is structured to accommodate learners with varying levels of expertise, and supplementary resources will be provided to support those with limited background in these areas.
        </p>

          `,
        title:
          "What are the prerequisites for this course? (e.g., a basic understanding of statistics, programming, and machine learning)?",
        sortOrder: 0,
        Course: {
          faqDescription: "FAQs - Frequently Asked Questions"
        }
      },
      {
        FaqID: 1018,
        description: `<p>Our Gen AI for Business certification course will delve deep into the core generative AI models that have revolutionized the field. This includes:</p>
          <ul class='pg-lg-ul-r-20'>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                <b>Generative Adversarial Networks (GANs):</b> Understanding how GANs create realistic synthetic data through adversarial training.
              </p>
            </li>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                <b>Variational Autoencoders (VAEs):</b> Exploring VAEs as a probabilistic approach to generating new data instances.
              </p>
            </li>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                <b>Transformers:</b> This section focuses on transformers' architecture and capabilities, particularly their applications in natural language processing and image generation.
              </p>
            </li>
          </ul>
          <p>
            Given their transformative impact on various industries, a special emphasis will be placed on <b>Large Language Models (LLMs).</b> You will gain insights into their architecture, training, and applications.
          </p>
          `,
        title:
          "What specific generative AI models will be covered? (e.g., GANs, VAEs, Transformers)",
        sortOrder: 1,
        Course: {
          faqDescription: "FAQs - Frequently Asked Questions"
        }
      },
      {
        FaqID: 1019,
        description: `<p>This  Generative AI training course for Business Applications course offers a learning experience that is comprehensive and flexible. The 10-week program is structured to balance self-paced learning with intensive live interaction.</p>
        <p><b>Self-Paced Learning (10 Hours/Week)</b></p>
        <p>The course incorporates 10 hours of weekly self-paced learning to maximize your learning efficiency. This component includes:</p>
          <ul class='pg-lg-ul-r-20'>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                <b>Pre-recorded video lectures:</b> Expert-led video tutorials covering fundamental concepts, theoretical underpinnings, and practical demonstrations.
              </p>
            </li>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                <b>Interactive modules:</b> Engaging online exercises and quizzes to reinforce learning and assess understanding.
              </p>
            </li>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                <b>Reading materials:</b> Supplementary articles, research papers, and industry reports for in-depth exploration.
              </p>
            </li>
          </ul>
          <p><b>Live Interactive Sessions (40 Hours/Week)</b></p>
          <p>This Gen AI course offers 40 hours of weekly live classes to foster real-time engagement and knowledge sharing. These sessions are designed to:</p>
          <ul class='pg-lg-ul-r-20'>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                <b>Deepen understanding:</b> Through interactive discussions and Q&A sessions, clarify doubts and enhance comprehension.
              </p>
            </li>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                <b>Practical application:</b> Conduct hands-on exercises, case studies, and project-based learning under expert guidance.
              </p>
            </li>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                <b>Industry insights:</b> Share real-world examples and best practices from industry experts.
              </p>
            </li>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                <b>Networking opportunities:</b> Interact with fellow learners and build professional connections.
              </p>
            </li>
          </ul>
          <p>
            This blended learning approach ensures participants can learn independently while benefiting from structured guidance and interactive elements in live classes.</p><p>
            
            Our Generative AI course for business applications course caters to diverse learning styles by combining self-paced and live learning, ensuring maximum knowledge retention.
          </p>
          `,
        title:
          "How is this generative AI online course structured? (e.g., live sessions, pre-recorded videos, hands-on projects)",
        sortOrder: 1,
        Course: {
          faqDescription: "FAQs - Frequently Asked Questions"
        }
      },
      {
        FaqID: 1020,
        description: `<p><b>Yes, hands-on practice and projects are a cornerstone of this course.</b></p>
        <p>To ensure you gain practical experience in applying generative AI concepts, the curriculum includes:</p>
        <ul class='pg-lg-ul-r-20'>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                <b>In-class projects:</b> Collaborative group projects to simulate real-world challenges.
              </p>
            </li>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                <b>Hands-on exercises:</b> Practical assignments to reinforce learning and build proficiency.
              </p>
            </li>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                <b>Capstone project:</b> An individual or group project to apply acquired knowledge to a complex business problem.
              </p>
            </li>
        </ul>
        <p>
          These hands-on opportunities will allow you to experiment with different generative AI models, develop your problem-solving skills, and create a portfolio of your work.
        </p>
        
        `,
        title:
          "Does this course offer opportunities for hands-on practice and projects?",
        sortOrder: 1,
        Course: {
          faqDescription: "FAQs - Frequently Asked Questions"
        }
      },
      {
        FaqID: 1021,
        description: `
          <ul class='pg-lg-ul-r-20'>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                <b>Expanding your skill set:</b> Mastering generative AI techniques adds a powerful tool to your data science arsenal.
              </p>
            </li>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                <b>Increasing your value:</b> Your ability to create synthetic data, generate insights, and develop innovative solutions will make you a valuable asset to your organization.
              </p>
            </li>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                <b>Opening new career opportunities:</b> Generative AI drives demand for roles like AI product manager, AI consultant, and AI engineer, offering new career paths.
              </p>
            </li>
          </ul>
          `,
        title:
          "How can generative AI skills enhance my career as a data professional?",
        sortOrder: 1,
        Course: {
          faqDescription: "FAQs - Frequently Asked Questions"
        }
      },
      {
        FaqID: 1022,
        description: `<p>After completing this course, you can position yourself in various roles in business and artificial intelligence. Here are some potential job titles:</p>
        <ul class='pg-lg-ul-r-20'>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                <b>AI Product Manager:</b> Taking the lead in developing and launching AI-powered products.
              </p>
            </li>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                <b>AI Consultant:</b> Providing AI expertise to organizations seeking to implement generative AI solutions.
              </p>
            </li>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                <b>AI Engineer:</b> Developing and implementing generative AI models for specific business applications.
              </p>
            </li>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                <b>AI Business Analyst:</b> Bridging the gap between business needs and AI capabilities.
              </p>
            </li>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                <b>Data Scientist with Generative AI Focus:</b> Leveraging generative AI techniques for advanced data analysis and insights.
              </p>
            </li>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                <b>Machine Learning Engineer with Generative AI Specialization:</b> Focusing on developing and deploying generative models.
              </p>
            </li>
          </ul>
        
        `,
        title:
          "What job roles to explore after completing this certification course in Gen AI for business?",
        sortOrder: 1,
        Course: {
          faqDescription: "FAQs - Frequently Asked Questions"
        }
      },
      {
        FaqID: 1022,
        description: `<p><b>Absolutely!</b> Building a strong portfolio of generative AI projects is integral to the course.</p><p>
        You will have ample opportunities to work on various projects, from conceptualizing to implementation. These projects will cover a range of applications, allowing you to explore different aspects of generative AI.</p><p>
        Not only will this hands-on experience solidify your learning, but it will also provide you with tangible projects to showcase your skills to potential employers.
        </p>
        
        `,
        title: "Will I be able to build a portfolio of generative AI projects?",
        sortOrder: 1,
        Course: {
          faqDescription: "FAQs - Frequently Asked Questions"
        }
      },
      {
        FaqID: 1022,
        description: `<p>The Generative AI Course for Business Applications course is meticulously aligned with the rapidly evolving landscape of industry trends and job market demands.</p><p>
        Here's how:
        </p>
          <ul class='pg-lg-ul-r-20'>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                Focus on Business Applications
              </p>
            </li>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                Hands-on Experience
              </p>
            </li>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                Curriculum Alignment
              </p>
            </li>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                Industry Expert Involvement
              </p>
            </li>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                Skill-Based Learning
              </p>
            </li>
          </ul>
        `,
        title: "Will I be able to build a portfolio of generative AI projects?",
        sortOrder: 1,
        Course: {
          faqDescription: "FAQs - Frequently Asked Questions"
        }
      }
    ],
    courseID: 73,
    description: "FAQs - Frequently Asked Questions"
  },
  successStories: [
    {
      userReviewsId: 102,
      courseID: 73,
      reviewText:
        "I am ineffable to express my journey through AnalytixLabs, Gurgaon .  Since we all know the faculties are the backbone of any institute to make it reach to world class level and AnalytixLabs has very experienced faculties who not only teach you but also supervise you in your further academic career . The institute has best infrastructure in terms of study material , faculties , classrooms etc . Student will get chance to work on live projects . You will get excellent training in analytic field . The whole training period is just wonderful . To be precise , AnalytixLabs is one of the INDIA's best analytics  institute .",
      User: {
        userID: 107,
        email: "gmohit280@gmail.com",
        Student: {
          userID: 107,
          studentID: 87,
          name: "Mohit Jain",
          profileImage: "image/studentImage/Mohit_Jain.jpg",
          designation: "Data Scientist",
          organization: "J.P. Morgan Chase & Co.",
          organizationImage: null,
          linkedinUrl: "https://in.linkedin.com/pub/mohit-jain/3a/b83/973"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 92,
      courseID: 73,
      reviewText:
        "I went for a combo program @ AnalytixLabs - Business Analytics (SAS+BA) + Big Data Analytics (R+Hadoop). The program was superbly well structured and faculty had great command on their subject. While I had opted for classroom training, but the online facility came in as a boon and I attended all classes from home without feeling any difference. The assignments for each module ensured that we worked hands on and gained quality training. The practical examples and Q&amp;A ensured the classes were not just interactive but also lively. I had a great experience @ Analytixlabs and would recommend everyone who is looking for professional training in analytics.",
      User: {
        userID: 97,
        email: "deepika.goel@hotmail.com",
        Student: {
          userID: 97,
          studentID: 77,
          name: "Deepika Goel, VP - Genpact Bangalore",
          profileImage: "image/studentImage/Deepika_Goel.jpg",
          designation: "Director",
          organization: "Marsh & McLennan Companies",
          organizationImage: null,
          linkedinUrl: "https://www.linkedin.com/in/deepikagoel9811347436/"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 138,
      courseID: 73,
      reviewText:
        "The rapid pace at which the institutes are mushrooming all over India and Facebook newsfeed being inundated with thousands of options for analytics training, there are only handful of training institutes or rather say only couple of places where they ???prepare??? you to foray into rapidly growing analytics vertical. I have been working into legacy systems for past 5 years and was quite apprehensive if I will ever break into data science successfully. While I was working on my options of training institutes, a brief ???counseling??? by Mouli sir and Sumeet sir obviated the further process of evaluating my options. And I can safely say that settling in for AnalytixLabs was a safe and wise decision. The reason they have been bellwether among all training institutes is due to the fact that they have been emphasizing more on algorithms and underlying mathematical and statistical concepts which is bedrock for this track and not just trammeling their trainees with list of syntax of statistical tools. Fortunately with proper guidance and encouragement from faculty, I managed to switch over to analytics from traditional IT background. BIG thanks!",
      User: {
        userID: 143,
        email: "sumitasthana14@gmail.com ",
        Student: {
          userID: 143,
          studentID: 123,
          name: "Sumit Asthana",
          profileImage: "image/studentImage/2d50efc.jpg",
          designation: "Assistant Manager",
          organization: "Senior Data Scientist",
          organizationImage: null,
          linkedinUrl: "https://in.linkedin.com/in/thatsumit"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 165,
      courseID: 73,
      reviewText:
        "First of all Big Thanks to Analytix Labs. Its an Awesome institute to the one who wants to shine up his/her career in Data Science &amp; Analytics. As the name suggest's, its not just a learning institute but also a play ground to play with Case study based modules crafted by experts based on real life business scenarios. Though I discontinued from the Sessions due to personal reasons, The Team helped me like an AnalytiX Labs Alumni when I came back to Institute after two long years. The esteemed help from the Mentors varied from providing Mock Exams, Doubts Clarification sessions, Case Studies, Resume Building and Mock Interview Sessions. I am really grateful to the Analytix Labs Team for providing all the help in an need hour. The extended support and Mock exams helped me to achieve Base SAS Certification (BP064209V9) with 97%. I'll definitely highly recommend this institute to everyone without hesitation.",
      User: {
        userID: 170,
        email: "viswanadhsogana@gmail.com",
        Student: {
          userID: 170,
          studentID: 150,
          name: "Viswanadh Sogana",
          profileImage: "image/studentImage/Sogna.jpg",
          designation: "Accenture",
          organization: "Senior Analyst",
          organizationImage: null,
          linkedinUrl: "https://in.linkedin.com/in/viswanadhsogana"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 130,
      courseID: 73,
      reviewText:
        "AnalytixLabs stands out from the crowd of vast analytics courses mainly because of its practical and conceptual approach. The 'intent' and the 'content' was way too awesome, to say the least. Excellent course, brilliant co-founders with immense knowledge on the subject matter. Just The Best Learning Centre till date.",
      User: {
        userID: 135,
        email: "atul_kapur@outlook.com",
        Student: {
          userID: 135,
          studentID: 115,
          name: "Atul Kapur",
          profileImage: "image/studentImage/Atul_Kapur1.jpg",
          designation: "",
          organization: "RBS Chennai",
          organizationImage: null,
          linkedinUrl: "linkedin.com/in/atulkap"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 112,
      courseID: 73,
      reviewText:
        "I underwent 2 trainings at AnalytixLabs: SAS+Business Analytics and Big Data Analyst. After these 2 big courses, I am confident enough to never go wrong when I say that the quality of training here is truly tough to find anywhere else. When you are really concerned about taking the next big career step, you undoubtedly need - not just a trainer, not just a mentor and not just a career counsellor. You need it all, and that's what AnalytixLabs has given me. During these times, when institutes bring forward low-experienced trainers to make money out of the &quot;Analytics&quot; buzzword, the highly-experienced faculty members at AnalytixLabs provided such an individual attention that I felt like going nowhere else for any career advice or technical help at different points of time during my stint to switch job to analytics. And yes, finally I have succeeded to get a great one for myself! Thanks AnalytixLabs! You are a big hope for all such people like me who are looking for that one hand to take us along the right career path. Keep doing this great work and I am sure you will grow high with these endeavours!!",
      User: {
        userID: 117,
        email: "neha.analytics303@gmail.com",
        Student: {
          userID: 117,
          studentID: 97,
          name: "Neha Luthra",
          profileImage: "image/studentImage/Neha_Luthra.jpg",
          designation: "Assistant Manager",
          organization: "The Smartcube Pvt ltd",
          organizationImage: null,
          linkedinUrl: "https://in.linkedin.com/in/neha-luthra-4406a497"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 105,
      courseID: 73,
      reviewText:
        "AnalytixLab is one of the best institute we can prefer, especially for big data analytics perspective which i am familiar. ALabs has dedicated, and real time experienced faculty in the industry on various analytics domains, they are also available at any time if we need any clarification on career or any technical quires. One thing which i observed on my association with them was they would complete the full syllabus irrespective of the time constraint, other institutes are bounded to their predefined time, and try to rush up the syllabus to catch up the next batches",
      User: {
        userID: 110,
        email: "sumendar@gmail.com",
        Student: {
          userID: 110,
          studentID: 90,
          name: "Sumendar Karupakala",
          profileImage: "image/studentImage/Sumendar_Karupakala.jpg",
          designation: "Certified Big Data Analyst",
          organization: "TrueBees Technologies",
          organizationImage: null,
          linkedinUrl: "https://in.linkedin.com/in/sumendar"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 117,
      courseID: 73,
      reviewText:
        "I took three months Big Data training  from Analytix labs. Before joining this course, I had so many questions and doubts that will this course be worthy enough but later I found myself lucky to join this program. All trainers are amazing and always ready to help. Live examples and Case Studies are given which helps in understanding the concept and hands on practice.Not only they help in quality in depth knowledge transfer but also tend to give right direction to your career.",
      User: {
        userID: 122,
        email: "surbhi.sultania@gmail.com",
        Student: {
          userID: 122,
          studentID: 102,
          name: "Surbhi Sultania",
          profileImage: "image/studentImage/Surbhi_Sultania.jpg",
          designation:
            "Assistant Manager - Consumer Research and Insights Specialist, Primary Quantitative Research",
          organization: "The Smartcube Pvt ltd",
          organizationImage: null,
          linkedinUrl: "https://in.linkedin.com/in/surbhi-sultania-0486b24a"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 125,
      courseID: 73,
      reviewText:
        "I sincerely appreciate the learning I got at Analytixlabs. Faculties here are very experienced and their dedication towards teaching is commendable. Sumeet and Chandra, both are very cooperative and provide all the counseling and support a person could ask for. My internship at the Analytixlabs has given me professional experience also helped me in achieving my goals. I would recommend Analytixlabs to everyone as they are THE BEST!! I wish AnalytixLabs all the best and a BIG THANK YOU.",
      User: {
        userID: 130,
        email: "kirti14dayal@gmail.com",
        Student: {
          userID: 130,
          studentID: 110,
          name: "Kirti Dayal",
          profileImage: "image/studentImage/3a0462f.jpg",
          designation: "Sr. Analyst",
          organization: "AbsolutData Analytics",
          organizationImage: null,
          linkedinUrl: "https://in.linkedin.com/in/kirtidayal"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 120,
      courseID: 73,
      reviewText:
        "AnalytixLabs is the best place for all sorts of Analytics training be it R, Hadoop, SAS, Excel or others. They have a very meticulous approach towards the curriculum development, training, peer-faculty interaction, placements as well as career guidance. I myself have had an awesome experience with AnalytixLabs and would rate them the best in the field.",
      User: {
        userID: 125,
        email: "tarun.bhavnani@gmail.com",
        Student: {
          userID: 125,
          studentID: 105,
          name: "Tarun Bhavnani",
          profileImage: "image/studentImage/default.png",
          designation: "AVP, Data Science",
          organization: "SMECorner",
          organizationImage: null,
          linkedinUrl: "https://www.linkedin.com/in/tarunbhavnani/"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    }
  ],
  careerSupport: [
    {
      courseCareerSupportID: 611,
      courseID: 73,
      careerSupportID: 615,
      sortOrder: 1,
      createdAt: "2020-09-28T09:27:47.000Z",
      updatedAt: "2020-10-01T10:34:24.000Z",
      CareerSupport: {
        careerSupportID: 615,
        title: "Pay After Placement Model",
        image: "image/course/Analytixlabs-icons-svg-36.svg",
        description:
          "We align our success with yours. Our innovative pay-after-placement model requires only a 50% upfront course fee. The remaining balance is conveniently structured as EMIs, payable solely after you secure a job meeting our minimum CTC threshold.",
        isActive: true,
        isDeleted: false,
        createdBy: null,
        updatedBy: null,
        createdAt: "2020-09-28T09:27:47.000Z",
        updatedAt: "2023-10-31T06:49:55.000Z"
      }
    },
    {
      courseCareerSupportID: 613,
      courseID: 73,
      careerSupportID: 617,
      sortOrder: 2,
      createdAt: "2020-09-28T09:27:47.000Z",
      updatedAt: "2020-10-01T10:34:24.000Z",
      CareerSupport: {
        careerSupportID: 617,
        title: "Placement Readiness Program",
        image: "image/course/Analytixlabs-icons-svg-34.svg",
        description:
          "Our intensive 2-month program is meticulously crafted to bridge the gap between academia and industry. Through technical skill enhancement, soft skill coaching, and rigorous interview simulations, we equip you with the arsenal to excel in high-stakes recruitment processes.",
        isActive: true,
        isDeleted: false,
        createdBy: null,
        updatedBy: null,
        createdAt: "2020-09-28T09:27:47.000Z",
        updatedAt: "2023-10-31T06:49:55.000Z"
      }
    },
    {
      courseCareerSupportID: 614,
      courseID: 73,
      careerSupportID: 618,
      sortOrder: 3,
      createdAt: "2020-09-28T09:27:47.000Z",
      updatedAt: "2020-10-01T10:34:24.000Z",
      CareerSupport: {
        careerSupportID: 618,
        title: "Flexible Opt-Out Options",
        image: "image/course/Analytixlabs-icons-svg-33.svg",
        description:
          "Before commencing the Placement Readiness Program (PRP), certified students may elect to forgo the Pay After Placement option. This is suitable for learners prioritizing skill enhancement or certificate acquisition who feel sufficiently prepared for independent job seeking. While basic placement support remains accessible, these students will receive a waiver on the remaining 50% program fee.",
        isActive: true,
        isDeleted: false,
        createdBy: null,
        updatedBy: null,
        createdAt: "2020-09-28T09:27:47.000Z",
        updatedAt: "2023-10-31T06:49:55.000Z"
      }
    },
    {
      courseCareerSupportID: 612,
      courseID: 73,
      careerSupportID: 616,
      sortOrder: 4,
      createdAt: "2020-09-28T09:27:47.000Z",
      updatedAt: "2020-10-01T10:34:24.000Z",
      CareerSupport: {
        careerSupportID: 616,
        title: "End-to-end Support",
        image: "image/course/Analytixlabs-icons-svg-35.svg",
        description:
          "Our support extends beyond PRP completion. Graduates consistently leverage their acquired skills to secure multiple interview opportunities and fulfilling careers. Our alumni network demonstrates our program's effectiveness, with many securing roles in top-tier companies.",
        isActive: true,
        isDeleted: false,
        createdBy: null,
        updatedBy: null,
        createdAt: "2020-09-28T09:27:47.000Z",
        updatedAt: "2023-10-31T06:49:55.000Z"
      }
    }
  ],
  courseDetails: {
    courseID: 73,
    courseFullName: "Generative AI course",
    name: "Generative AI course",
    slug: "generative-ai-course",
    shortDescription:
      "An Industry Relevant AI & ML Course to Launch Your Tech Career!",
    longDescription: `
      <p class="counrse-contn-1 ct-sm-left">
        Generative artificial intelligence (Gen. AI) is reshaping businesses, and how. This new-age smart AI is a self-learning model that learns by training on extensive public data with machine learning techniques. The penetration of AI in how businesses function is at its highest peak, with almost a <a href="https://www.statista.com/forecasts/1474143/global-ai-market-size" target="_blank">50 billion</a> jump in the market value of AI from 2023-2024. Companies need professionals who know how to leverage the power of generative AI and implement it for business-driven decision-making. While demand is high, the shortage of skilled professionals is alarming. Our Generative AI Course for business applications is designed to bridge this gap and give you a competitive advantage.
      </p>



        <h3 class="date-ttl mb-2 ct-capitalize mt-4 ">About This Course</h3>
        <p class="counrse-contn-1 ct-sm-left">The "Generative AI Certification for Business Applications" course is designed to provide a comprehensive understanding and practical knowledge of generative artificial intelligence (Generative AI or GenAI) and its applications in various business contexts. </p><p class="counrse-contn-1 ct-sm-left">

        The curriculum revolves around understanding Generative AI, Business Applications of Gen AI, Developing GenAI strategy, Planning and Managing GenAI projects, Future trends of GenAI, and mastering Prompt Engineering. You will gain hands-on experience with cutting-edge generative tools, including ChatGPT, Llama, Dall-E, Gemini, Midjourney, Stable Diffusion, and Microsoft OpenAI.</p><p class="counrse-contn-1 ct-sm-left">

        Our Generative AI course is designed to equip you with the knowledge and skills to be at the forefront of this revolution. You'll learn how to:
        </p>
          <ul class="cours-ovr-ul">
            <li class="drc-blcr" style='list-style:none; text-align:left;'>
              <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
                Develop innovative solutions to complex business challenges
              </p>
            </li>
            <li class="drc-blcr" style='list-style:none; text-align:left;'>
              <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
                Automate routine tasks and optimize workflows
              </p>
            </li>
            <li class="drc-blcr" style='list-style:none; text-align:left;'>
              <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
                Unlock hidden insights within your data
              </p>
            </li>
            <li class="drc-blcr" style='list-style:none; text-align:left;'>
              <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
                Create competitive advantage through data-driven decision-making
              </p>
            </li>
          </ul>

        
        <p class="counrse-contn-1 ct-sm-left">
          Enroll in our Generative AI course today and position yourself for success in the future of business.
        </p>
        
        <h3 class="date-ttl mb-2 ct-capitalize mt-4 ct-sm-left">Course Objectives</h3>  
        <p class="counrse-contn-1 ct-sm-left">
          By the end of this course, you will be able to
        </p>
          <ul class="cours-ovr-ul">
            <li class="drc-blcr" style='list-style:none; text-align:left;'>
              <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
                Master the fundamentals to learn Generative AI and apply it across industries
              </p>
            </li>
            <li class="drc-blcr" style='list-style:none; text-align:left;'>
              <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
                Develop practical skills in using Generative AI tools and platforms
              </p>
            </li>
            <li class="drc-blcr" style='list-style:none; text-align:left;'>
              <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
                Build and deploy Generative AI models to solve real-world business problems
              </p>
            </li>
            <li class="drc-blcr" style='list-style:none; text-align:left;'>
              <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
                Evaluate the ethical and societal implications of Generative AI
              </p>
            </li>
          </ul>


        <h3 class="date-ttl mb-2 ct-capitalize mt-4 ct-sm-left">Why Learn with AnalytixLabs?</h3>
        <p class="counrse-contn-1 ct-sm-left">
          AnalytixLabs is at the forefront of data science and AI education. Our expert instructors and seasoned industry veterans deliver cutting-edge curriculum designed to equip you with in-demand skills. Learn by doing with real-world projects and emerge job-ready to tackle complex business challenges.</p><p class="counrse-contn-1 ct-sm-left">

          AnalytixLabs is bridging the opportunity-to-skill gap while taking on real-world challenges head-on. Our Generative AI Certification for Business Applications course adheres to current market needs and is designed to stay relevant in the coming days. This is because it addresses the three main challenges -
        </p>
        <ul class="cours-ovr-ul">
          <li class="drc-blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              Global Competitiveness: Mastering Generative AI will position you to lead businesses on a global stage.
            </p>
          </li>
          <li class="drc-blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              Addresses Local Challenges: You will be prepared to tackle unique challenges such as healthcare, agriculture, education, and infrastructure.
            </p>
          </li>
          <li class="drc-blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              Skill Shortage: There's a growing demand for AI talent in India, making it a lucrative career path. Experts like you will reap maximum benefits with the right skills and an agile approach to deliver results.
            </p>
          </li>
        </ul>
      `,
    hoursCount: "500",
    classCount: "46",
    classHours: "+ 55 hrs e-learning",
    projectCount: "44",
    ratingValue: 4.73,
    bestRating: 5,
    worstRating: 2,
    ratingCount: 2680,
    metaTitle: "Artificial Intelligence Courses: Job-oriented AI ML Training",
    metaDescription:
      "Launch your AI & ML career: Master in-demand skills with online and offline courses & certifications in India. Compare fees & get future-proof!",
    metaKeywords: "",
    selfStudyHoursCount: "460 (8-10 hrs/ week)",
    classCountSubline: "",
    hoursPerClassCount: "63 x 3 = 189 hrs",
    readinessProgramClassCount: "8 Weeks",
    readinessProgramSubline: "(Post Certification)",
    theme: "Default",
    isScholarship: 1,
    CourseLearningModes: {
      amountINR: 48000,
      maxAmountINR: 55000
    },
    taxPercentage: "18",
    LMSCourse: "ckeq05bmi00bgtqjl1i4l0h4e"
  },
  courseCapstoneRecord: {
    courseID: 73,
    capstoneProjects: null,
    capstoneProjectsDescription:
      "Our artificial intelligence courses in India include multiple case studies and assignments for self-study and hands-on skills, in addition to multiple case studies.",
    CourseCapstoneProjects: [
      {
        capstoneProjectID: 3070,
        CapstoneProject: {
          capstoneProjectname:
            "Image classification for inventory management (AI DL)"
        }
      },
      {
        capstoneProjectID: 3071,
        CapstoneProject: {
          capstoneProjectname:
            "Machine Language Translation (French to English & Hindi to English)"
        }
      },
      {
        capstoneProjectID: 3072,
        CapstoneProject: {
          capstoneProjectname: "Stock price forecasting (Deep Learning)"
        }
      },
      {
        capstoneProjectID: 3073,
        CapstoneProject: {
          capstoneProjectname: "Image classification project (AI DL)"
        }
      },
      {
        capstoneProjectID: 3074,
        CapstoneProject: {
          capstoneProjectname: "Credit Card fraud detection (Deep Learning)"
        }
      },
      {
        capstoneProjectID: 3075,
        CapstoneProject: {
          capstoneProjectname: "Automated image captioning (AI DL)"
        }
      },
      {
        capstoneProjectID: 3076,
        CapstoneProject: {
          capstoneProjectname: "Age detection using images (AI DL)"
        }
      },
      {
        capstoneProjectID: 3077,
        CapstoneProject: {
          capstoneProjectname: "Toxic comments classification (Deep Learning)"
        }
      },
      {
        capstoneProjectID: 3078,
        CapstoneProject: {
          capstoneProjectname:
            "Image classification & localization (Using YOLO)"
        }
      },
      {
        capstoneProjectID: 3079,
        CapstoneProject: {
          capstoneProjectname:
            "Network intrusion detection (Supervised machine learning)"
        }
      },
      {
        capstoneProjectID: 3080,
        CapstoneProject: {
          capstoneProjectname: "Online job posting analysis (Text mining & NLP)"
        }
      },
      {
        capstoneProjectID: 3081,
        CapstoneProject: {
          capstoneProjectname:
            "Banking customer cluster (Unsupervised machine learning)"
        }
      },
      {
        capstoneProjectID: 3082,
        CapstoneProject: {
          capstoneProjectname:
            "Retail chain sales forecasting (multivariate time series)"
        }
      },
      {
        capstoneProjectID: 3083,
        CapstoneProject: {
          capstoneProjectname:
            "Predicting credit card spend (regression methods)"
        }
      },
      {
        capstoneProjectID: 3084,
        CapstoneProject: {
          capstoneProjectname: "E-commerce recommendation engine (MBA)"
        }
      },
      {
        capstoneProjectID: 3085,
        CapstoneProject: {
          capstoneProjectname: "Chatbot creation (Seq2seq Deep Learning)"
        }
      },
      {
        capstoneProjectID: 3086,
        CapstoneProject: {
          capstoneProjectname: "Bank reviews analysis (Text mining & NLP)"
        }
      }
    ],
    CourseAssignments: [
      {
        courseAssignmentID: 1893,
        Assignment: {
          name: "10 short exercises on NumPy & Pandas"
        }
      },
      {
        courseAssignmentID: 1894,
        Assignment: {
          name:
            "4 case studies on Pandas for data munging, descriptive & visual analysis"
        }
      },
      {
        courseAssignmentID: 1895,
        Assignment: {
          name: "3 Basic exercises (Excel, SQL & Tableau)"
        }
      },
      {
        courseAssignmentID: 1896,
        Assignment: {
          name: "1 Assignment on basic Python"
        }
      },
      {
        courseAssignmentID: 1897,
        Assignment: {
          name: "2 exercises for statistical analysis"
        }
      }
    ]
  },
  similarCourseRecords: [
    {
      courseID: 60,
      name: "Data Science using R",
      shortDescription:
        "Best R training for industry relevant Advanced Analytics and Machine Learning skills!",
      robotImage: "image/course/thumbnail-1616501648.svg",
      courseDetailsImage: "image/course/data-science-using-R-1657623295981.png",
      slug: "data-science-r-analytics-training"
    },
    {
      courseID: 63,
      name: "Data Science using Python",
      shortDescription:
        "Best Python Data Science Course with Industry-Certified Excellence!",
      robotImage: "image/course/thumbnail-1616501891.svg",
      courseDetailsImage:
        "image/course/iitg-web-icon-logo-1714134463298-1714199689299.png",
      slug: "data-science-using-python"
    },
    {
      courseID: 64,
      name: "Business Analytics 360",
      shortDescription:
        "Dynamic Business Analytics Course with Job-Focused Certification!",
      robotImage: "image/course/dva-banners-creatives-alabs-04 copy.svg",
      courseDetailsImage: "image/course/iitg-web-icon-logo-1714199891639.png",
      slug: "business-analytics-data-science-course"
    }
  ],
  paymentKeys: {
    payumoneyKey: "qsxM7i",
    payumoneySalt: "A5v0ZQIJ",
    accountType: "nonLLP"
  },
  callUs: {
    number: "9555219007",
    country_code: "+91",
    start_time: "10:00:00",
    end_time: "19:00:00"
  },
  landingCallUs: {
    number: "9555525908",
    country_code: "+91",
    start_time: "10:00:00",
    end_time: "19:00:00"
  },
  careerPath: {
    CareerPathHtml: `
      <p class="counrse-contn-1 ct-sm-left">The field of Generative AI is rapidly evolving, offering a multitude of career paths. The path chosen depends on individual interests, skills, and industry focus. </p>
        <ul class="cours-ovr-ul">
          <li class="blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                  <span style='color: #013368 ;'>
                    AI Business Analyst: 
                  </span>
              </b>The career progression often involves starting as a Business Analyst focusing on data and analytics. You can then move to a Senior AI Business Analyst or AI Product Owner or transition into roles like Head of Business Intelligence.
            </p>
          </li>
          <li class="drc-blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                  <span style='color: #013368 ;'>
                    AI Strategist: 
                  </span>
              </b>Typically starts as a Business Analyst or Consultant with a strong interest in AI. Career progression involves becoming a Head of AI Strategy, Chief AI Officer, or a strategic consultant in AI.
            </p>
          </li>
          <li class="blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                  <span style='color: #013368 ;'>
                    AI Consultant: 
                  </span>
              </b>Often starts as a Data Scientist or Business Analyst with consulting experience. Career progression involves becoming a Senior AI Consultant, AI Practice Lead, or starting your own AI consulting firm.
            </p>
          </li>
          <li class="drc-blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                  <span style='color: #013368 ;'>
                    AI Project Manager: 
                  </span>
              </b>This position typically starts as a Project Manager with experience in IT or data projects. Career progression involves becoming a Program Manager for AI initiatives, a Portfolio Manager, or an AI Transformation Leader.
            </p>
          </li>
          <li class="blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                  <span style='color: #013368 ;'>
                    AI Ethics Officer: 
                  </span>
              </b>This position often starts with a Data Privacy Officer, Legal Counsel, or Risk Manager interested in AI ethics. Career progression involves becoming a Chief Ethics Officer or a leading expert in AI ethics.
            </p>
          </li>
        </ul> 
    `
  },
  learningBenefits: {
    learningBenefitsHTML: `
    <div>
    <p class="counrse-contn-1 ct-sm-left">Engage in an intuitive and hands-on learning experience. Work on relevant projects under the guidance of industry experts. Experience our no-code approach to AI for managers course. Skip the coding or programming struggles and focus on learning to deliver results with AI. </p>
    <ol class="ct-number-ol-date">
          <li class='mb-4'>
            <h3 style='font-size:20px; color: #013368; text-align: left;'>
              Democratize AI for Strategic Decision Making
            </h3>
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4 ct-ft-weight-small">
              By understanding the core concepts of AI without the need for coding, you will become an active participant in AI-driven decision-making.
            </p>
          </li>
          <li class='mb-4'>
            <h3 style='font-size:20px; color: #013368; text-align: left;'>
              Enhance Operational Efficiency and Productivity
            </h3>
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4 ct-ft-weight-small">
              The course will equip you with the knowledge to streamline operations, automate routine tasks, and optimize resource allocation using AI tools.
            </p>
          </li>
          <li class='mb-4'>
            <h3 style='font-size:20px; color: #013368; text-align: left;'>
              Cultivate a Data-Driven Culture
            </h3>
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4 ct-ft-weight-small">
              Understanding how AI leverages data is crucial for fostering a data-driven culture. As Managers and leaders, you will learn to harness data insights to make evidence-based decisions, identify trends, and anticipate market changes.
            </p>
          </li>
          <li class='mb-4'>
            <h3 style='font-size:20px; color: #013368; text-align: left;'>
              Develop an Agile Business Mindset
            </h3>
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4 ct-ft-weight-small">
              The course will emphasize the role of AI in driving business agility. You will learn to adapt to changing market conditions, experiment with new AI-powered strategies, and create a more responsive organization.
            </p>
          </li>
          <li class='mb-4'>
            <h3 style='font-size:20px; color: #013368; text-align: left;'>
              Build a Competitive Advantage
            </h3>
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4 ct-ft-weight-small">
              Managers and leaders can position their organizations at the forefront of innovation by mastering AI. With this certification course, you will learn to identify new business opportunities, develop AI-powered products and services, and have a competitive edge in the market.
            </p>
          </li>
    </ol>
    `
  }
};

export const GenerativeAICourseCareerStory = `<p class="counrse-contn-1 ct-sm-left">Numerous success stories from our program showcase remarkable achievements by graduates in their respective fields. These individuals have effectively translated their learning into tangible accomplishments, from impactful projects to securing high-level positions in leading organizations.</p>
<p class="counrse-contn-1 ct-sm-left">

The underlying theme in these success stories is the practical application of knowledge acquired through experiential learning, a core focus of our course. Experiential learning prioritizes hands-on experiences, real-world projects, and practical applications.</p>
<p class="counrse-contn-1 ct-sm-left">

Experiential Learning fosters active engagement, critical thinking, and reflection and equips learners with practical skills and real-world insights beyond conventional classroom instruction. Active participation in activities, simulations, projects, or internships enables a deep comprehension of concepts, develops advanced problem-solving abilities, and establishes a solid, applicable knowledge base.</p>
<p class="counrse-contn-1 ct-sm-left">

This approach empowers learners to absorb and engage with information, fostering a profound understanding and setting the stage for meaningful career accomplishments.
</p>
`;

export const feeDetailsGenerativeAICourse =
  "Contrary to popular belief, our Generative AI for Business certification course offers exceptional value. Fees are competitively priced and aligned with industry standards, making AI education accessible to all.";

export const feeDetailsGenerativeAICourseSecondLine =
  "Invest in your future with AnalytixLabs, where mastery begins at just INR xxx + taxes.";

export const EMITextGenerativeAICourse = "INR 30,000/- plus taxes*";


export const CareerSupportGenerativeAICourseHTML = `
<p class="counrse-contn-1 ct-sm-left">
AnalytixLabs is dedicated to propelling your career in the dynamic AI and Data Science landscape. Since 2011, we've empowered thousands with industry-aligned skills, as evidenced by our impressive client roster.
</p>
<p class="counrse-contn-1 ct-sm-left">
Our comprehensive placement program and unwavering support are designed to secure you a coveted role. We are so confident in your success that we offer a "Pay After Placement" program.
</p>
`