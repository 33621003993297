export const AIForManagersLeadersData = {
  dummy: false,
  bannerRecord: {
    courseID: 73,
    name: "AI for Managers and Leaders Certification Course",
    bannerType: null,
    robotImage: "image/course/iitg-web-icon-logo-17144720123276.webp",
    courseDetailsImage: "image/course/iitg-web-icon-logo-17144720123276.webp",
    introURL: "",
    bannerFileName: "image/course/iitg-web-banner-17141983594556.webp",
    hoursCount: "16",
    classCount: "63"
  },
  toolsRecord: [
    {
      toolID: 3,
      Tool: {
        toolID: 3,
        title: "Python",
        smallImage: "image/toolsImage/asset12tools-1596020742031.png",
        link: null,
        largeImage: "image/toolsImage/tool-lg1-1596020742035.png"
      }
    },
    {
      toolID: 3,
      Tool: {
        toolID: 3,
        title: "NumPy",
        smallImage: "/image/toolsImage/asset2tools-1595857644273.png",
        link: null,
        largeImage: "/image/toolsImage/asset2tools-1595857644274.png"
      }
    },
    {
      toolID: 11,
      Tool: {
        toolID: 11,
        title: "Jira",
        smallImage: "image/toolsImage/Untitled-17%20(3)-1715082494525.png",
        link: null,
        largeImage: "image/toolsImage/Untitled-17%20(3)%20(1)-1715082755901.png"
      }
    },
    {
      toolID: 15,
      Tool: {
        toolID: 15,
        title: "Pandas",
        smallImage: "image/toolsImage/pandas-1601020776153.jpg",
        link: null,
        largeImage: "image/toolsImage/pandas-1601020776154.jpg"
      }
    },
    {
      toolID: 18,
      Tool: {
        toolID: 18,
        title: "nltk",
        smallImage: "image/toolsImage/nltk-1601021248569.png",
        link: null,
        largeImage: "image/toolsImage/nltk-1601021248570.png"
      }
    },
    {
      toolID: 7,
      Tool: {
        toolID: 7,
        title: "Cloud computing",
        smallImage: "image/toolsImage/tool_cc-1596020845393.png",
        link: null,
        largeImage: "image/toolsImage/tool_cc-1596020845394.png"
      }
    },
    {
      toolID: 1,
      Tool: {
        toolID: 1,
        title: "github",
        smallImage: "image/toolsImage/GitHub-logo-1715080153265.png",
        link: null,
        largeImage: "image/toolsImage/GitHub-logo-1715080153265.png"
      }
    },
    {
      toolID: 17,
      Tool: {
        toolID: 17,
        title: "nlp",
        smallImage: "image/toolsImage/Untitled-17%20(2)-1715082512817.png",
        link: null,
        largeImage: "image/toolsImage/Untitled-17%20(2)%20(1)-1715082746282.png"
      }
    },
    {
      toolID: 39,
      Tool: {
        toolID: 39,
        title: "git",
        smallImage: "image/toolsImage/Git-logo-1707482493038.png",
        link: null,
        largeImage: "image/toolsImage/Git-logo-1707482493040.png"
      }
    }
  ],
  sidebarTitles: {
    courseSidebarID: 11,
    courseID: 73,
    overview: "Course Overview",
    curriculum: "Curriculum for Artificial Intelligence For Managers and Leaders",
    whoShouldDo: "AI for Managers Course Eligibility",
    jobRoles: "Top Job Roles to Explore",
    keySkills: "Top Skills You Will Learn",
    capstoneProject: "AI engineering Capstone Projects",
    deliveryFormat:
      "Artificial Intelligence online course - Learning Methodologies",
    certification: "Certification Details",
    feeDetails: "Learning Methodologies & Course Fees",
    careerSupport: "Career Support by AnalytixLabs",
    successStories: "Career stories by AnalytixLabs",
    demoAndBrochure: "Demo & Brochure",
    upcomingBatches:
      "Upcoming Batches",
    FAQAndBlog: "FAQs & Blogs",
    createdAt: "2021-07-16T11:58:29.000Z",
    updatedAt: "2022-05-20T05:16:49.000Z",
    careerPath: 'Career Path',
    learningBenefits: 'Learning Benefits'
  },
  upcomingBatchResult: [
    {
      courseBatchID: 925,
      LMSBatchID: null,
      courseID: 73,
      instructorID: null,
      learningModeID: 2,
      name: "Python for Data Science- Gurgaon '24",
      description: null,
      seat: 4,
      cityID: 4,
      startDate: "2024-05-05T00:00:00.000Z",
      batchTime: "10:00 AM - 1:00 PM",
      isInteractive: true,
      showOnWebsite: 1,
      sortOrder: 0,
      isActive: true,
      isDeleted: false,
      createdBy: 345,
      updatedBy: null,
      modifiedDate: "2024-04-20T09:25:03.000Z",
      modifiedUser: 345,
      createdAt: "2023-03-15T09:12:44.000Z",
      updatedAt: "2024-04-20T09:25:03.000Z",
      City: {
        cityID: 4,
        name: "Gurgaon"
      }
    },
    {
      courseBatchID: 924,
      LMSBatchID: null,
      courseID: 73,
      instructorID: null,
      learningModeID: 2,
      name: "Python for Data Science- Bangalore '24",
      description: null,
      seat: 15,
      cityID: 1,
      startDate: "2024-05-19T00:00:00.000Z",
      batchTime: "2:00 PM - 5:00 PM",
      isInteractive: true,
      showOnWebsite: 1,
      sortOrder: 0,
      isActive: true,
      isDeleted: false,
      createdBy: 345,
      updatedBy: null,
      modifiedDate: "2024-04-16T06:52:48.000Z",
      modifiedUser: 345,
      createdAt: "2023-03-15T09:12:06.000Z",
      updatedAt: "2024-04-16T06:52:48.000Z",
      City: {
        cityID: 1,
        name: "Bangalore"
      }
    }
  ],
  courseIncludedRecord: [
    {
      courseID: 63,
      name: "Data Science using Python",
      slug: "data-science-using-python",
      hoursCount: "570",
      classCount: "43",
      courseType: 1,
      bannerFileName:
        "image/course/iitg-web-banner-1713875251999-1714199901805.webp",
      robotImage: "image/course/thumbnail-1616501891.svg",
      courseDetailsImage:
        "image/course/iitg-web-icon-logo-1714134463298-1714199689299.png",
      CourseTags: [
        {
          tagID: 4,
          Tag: {
            name: "DS360"
          }
        },
        {
          tagID: 1,
          Tag: {
            name: "BA360"
          }
        }
      ],
      learningMode: [
        {
          learningModeID: 1,
          name: "Classroom & Bootcamps",
          image: "image/learningMode/img-1.png",
          description:
            "Experiential learning with in-person mentorship!\r\n\r\n",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        },
        {
          learningModeID: 2,
          name: "Classroom with ILT Blended",
          image: "image/learningMode/img-2.png",
          description:
            "Learn from the best with unrestricted support, wherever you are!\r\n\r\n",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        },
        {
          learningModeID: 3,
          name: "Blended eLearning",
          image: "image/learningMode/img-3.png",
          description: "Learn at your pace with on-demand lectures & Support!",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        }
      ],
      amountINR: 35000,
      discountType: 1,
      discountAmount: 0,
      upcomingBatch: {
        courseID: 63,
        learningModeID: 2,
        startDate: "2024-05-05T00:00:00.000Z"
      }
    },
    {
      courseID: 65,
      name: "Machine Learning using Python",
      slug: "machine-learning-course-certification-training",
      hoursCount: "570",
      classCount: "43",
      courseType: 2,
      bannerFileName:
        "image/course/iitg-web-banner-1713875251999-1714199901805-1714562347744.webp",
      robotImage: "image/course/website-banners-creatives-alabs-07 copy.svg",
      courseDetailsImage:
        "image/course/iitg-web-icon-logo-1714134463298-1714199689299-1714562347747.png",
      CourseTags: [
        {
          tagID: 2,
          Tag: {
            name: "ABDS"
          }
        },
        {
          tagID: 4,
          Tag: {
            name: "DS360"
          }
        },
        {
          tagID: 3,
          Tag: {
            name: "AIML"
          }
        }
      ],
      learningMode: [
        {
          learningModeID: 1,
          name: "Classroom & Bootcamps",
          image: "image/learningMode/img-1.png",
          description:
            "Experiential learning with in-person mentorship!\r\n\r\n",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        },
        {
          learningModeID: 2,
          name: "Classroom with ILT Blended",
          image: "image/learningMode/img-2.png",
          description:
            "Learn from the best with unrestricted support, wherever you are!\r\n\r\n",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        },
        {
          learningModeID: 3,
          name: "Blended eLearning",
          image: "image/learningMode/img-3.png",
          description: "Learn at your pace with on-demand lectures & Support!",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        }
      ],
      amountINR: 20000,
      discountType: 1,
      discountAmount: 0,
      upcomingBatch: {}
    },
    {
      courseID: 68,
      name: "Certified Big Data Engineer",
      slug: "big-data-analytics-hadoop-spark-training-course-online",
      hoursCount: "210",
      classCount: "30",
      courseType: 1,
      bannerFileName:
        "image/course/machine-learning-course-certification-training1-1616494453966.jpg",
      robotImage: "image/course/Thumbnail-1616494453967.svg",
      courseDetailsImage:
        "image/course/certified-big-data-engineer-1657623430377.png",
      CourseTags: [
        {
          tagID: 2,
          Tag: {
            name: "ABDS"
          }
        },
        {
          tagID: 5,
          Tag: {
            name: "AIE"
          }
        }
      ],
      learningMode: [
        {
          learningModeID: 1,
          name: "Classroom & Bootcamps",
          image: "image/learningMode/img-1.png",
          description:
            "Experiential learning with in-person mentorship!\r\n\r\n",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        },
        {
          learningModeID: 2,
          name: "Classroom with ILT Blended",
          image: "image/learningMode/img-2.png",
          description:
            "Learn from the best with unrestricted support, wherever you are!\r\n\r\n",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        },
        {
          learningModeID: 3,
          name: "Blended eLearning",
          image: "image/learningMode/img-3.png",
          description: "Learn at your pace with on-demand lectures & Support!",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        }
      ],
      amountINR: 25000,
      discountType: 1,
      discountAmount: 0,
      upcomingBatch: {}
    }
  ],
  UserReviewsRecord: [],
  courseCurriculumRecord: {
    curriculumDescription: `<p class="counrse-contn-1">Learn artificial intelligence for management to automate daily mundane tasks. AI for management is like having an intelligent staff who can anticipate what you need to know, what business problems may arise, how to handle those challenges, and how exactly you can enable your team to achieve common business goals. </p>
    <p class="counrse-contn-1">
      Putting this idea into practice, the Artificial Intelligence for Managers and Leaders course at AnalytixLabs has five distinct modules: 
    </p>`,
    CourseCurriculums: [
      {
        courseID: 73,
        courseCurriculumID: 1228,
        name: "Demystifying AI and Market Landscape",
        sortOrder: 0,
        CourseCurriculumDetails: [
          {
            courseCurriculumDetailsID: 5518,
            courseCurriculumID: 1228,
            title: "Demystifying AI and Market Landscape",
            description: `<ul>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        Introduction to AI: Defining AI, its evolution, and different types (Machine Learning, Deep Learning, etc.)
                      </span>
                  </li>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        AI vs. Human Intelligence: Understanding the capabilities and limitations of AI systems.
                      </span>
                  </li>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        The Value Proposition of AI for Businesses: Exploring how AI can improve efficiency, optimize processes, and create new opportunities.
                      </span>
                  </li>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        AI Market Landscape & Trends: Analyzing the current AI landscape, key players, and emerging industry trends.
                      </span>
                  </li>
                  <li style="font-weight: 400;" data-mce-style="font-weight: 400;">
                      <span style="font-weight: 400;" data-mce-style="font-weight: 400;">
                        How Industry is Shifting Towards AI: Examining how industries adopt AI and reshape their business models.
                      </span>
                  </li>
              </ul>`,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T05:55:57.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          }
        ]
      },
      {
        courseID: 73,
        courseCurriculumID: 1240,
        name: "Understanding AI Applications and Use Cases",
        sortOrder: 2,
        CourseCurriculumDetails: [
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Understanding AI Applications and Use Cases",
            description: `
             <ul>
              <li>Key Application Areas of AI: In-depth exploration of major AI applications, including Computer Vision, Large Language models, Natural Language Processing, and Reinforcement Learning.</li>
              <li>Successful Applications of AI (Case Studies): Learning from real-world implementations of AI across different industries, illustrating the impact and value proposition.</li>
              <li>AI in Industry Segments: Analyzing how AI is transforming specific industries like Banking, Healthcare, Retail, Digital, Transportation, Oil & Gas, Smart Cities, and Manufacturing, with practical examples.</li>
              <li>AI-Enabled Business Functions: Understanding the potential of AI to improve various business functions such as Customer Experience, Marketing, Human Resources, and Finance.</li>
            </ul>
             `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          }
        ]
      },
      {
        courseID: 73,
        courseCurriculumID: 1240,
        name: "AI Strategy and Leadership",
        sortOrder: 2,
        CourseCurriculumDetails: [
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "AI Strategy and Leadership",
            description: `
             <ul>
              <li>How AI is Invoking Shifts in Business Value Chains: Recognizing how AI transforms business operations and creates new value propositions.</li>
              <li>AI Monetization Models: Consulting, Platforms As-a-Service, Solutions, etc.</li>
              <li>AI Strategy Framework (AI Strategy House): Learning a structured approach to develop AI strategies aligned with business goals.</li>
              <li>Measuring AI ROI and Project Evaluation: Defining and tracking key metrics to assess the effectiveness of AI initiatives.</li>
              <li>AI Maturity Stages: Evaluating your organization's current AI readiness and identifying areas for improvement.</li>
              <li>Building an AI Center of Excellence (CoE): Understanding the purpose and structure of an AI CoE to foster innovation and excellence in AI practices.</li>
              <li>Defining a comprehensive framework for building and managing a successful AI CoE.</li>
              <li>AI CoE Framework: 7 Pillar Approach.
                <p>
                  <ul class='pg-lg-ul-r-20'>
                    <li>Benchmark</li>
                    <li>Structure</li>
                    <li>Roadmap</li>
                    <li>Competency</li>
                    <li>Solution Ecosystem</li>
                    <li>Talent</li>
                    <li>Governance</li>
                  </ul>
                </p>
              </li>
            </ul>
             `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          }
        ]
      },
      {
        courseID: 73,
        courseCurriculumID: 1240,
        name: "Problem Solving with AI",
        sortOrder: 2,
        CourseCurriculumDetails: [
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Problem Solving with AI",
            description: `
             <ul>
              <li>Playbook for Problem Solving @ Scale with AI: Learning a structured approach to identify and solve complex business problems using AI.</li>
              <li>Problem-Solving Framework: Augment Intelligence, Automate & Learn, and Incorporate Human Behavior.</li>
              <li>AI Model Frameworks: Exploring different AI model frameworks (e.g., TensorFlow, PyTorch) commonly used for developing AI solutions.</li>
              <li>Data Science in Action (Problem Solving & Practical Implementation): Hands-on exercises involving applying AI techniques to solve real-world business problems (Case studies and simulations).</li>
              <li>Types of AI Problems - How can you check if the problem is qualified as an AI problem?</li>
              <li>AI Use Cases for Different Industries: Identifying and analyzing relevant AI use cases specific to participants' industries.</li>
            </ul>
             `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          }
        ]
      },
      {
        courseID: 73,
        courseCurriculumID: 1240,
        name: "Building Blocks for AI Implementation",
        sortOrder: 2,
        CourseCurriculumDetails: [
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Building Blocks for AI Implementation",
            description: `
             <ul>
                <li>Hardware Requirements: Storage & Computing</li>
                <li>Software Tools for AI: Exploring various software tools and platforms commonly used in AI development.</li>
                <li>Data Strategy & Engineering: Data Collection, Storage, Processing, Data Pipelines</li>
                <li>Data Science Capabilities & Cloud Transformation: Understanding the role of data science and cloud infrastructure in supporting AI initiatives.</li>
                <li>Cloud Computing: PaaS, SaaS, IaaS Offerings, Cloud Transformation</li>
                <li>Data Science Techniques: Traditional, Machine Learning, Deep Learning, Reinforcement and Genetic Algorithms</li>
                <li>AI Engineering: DevOps, MLOps, DataOps</li>
                <li>AI Tools: ChatGPT, Perplexity, Gemini, Midjourney, Llama, DALL-E3, Zapier, etc.</li>
                <li>Key Characteristics of an AI Solution/Product</li>
             </ul>
             `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          }
        ]
      }
    ]
  },
  whoShouldDoRecord: {
    whoShouldDo: `<p class="counrse-contn-1 ct-sm-left">This AI management course is designed for professionals and requires a minimum of years of experience in any domain or industry. It covers implementing AI to handle business risks, create solutions to complex business challenges, and drive AI and data science projects.</p>
    <p class="counrse-contn-1 ct-sm-left">
    If this interests you, then this course is for you. </p>
    <p class="counrse-contn-1 ct-sm-left">
    Explore the fascinating synergy between data science and AI with our executive AI for Data Science Program, powered in collaboration with E&ICT Academy, IIT Guwahati. </p>
    <p class="counrse-contn-1 ct-sm-left">
    We also have subject-specific certification courses that will teach you the technical aspects of data science and AI. Explore relevant courses - 
    </p>
      <ul class="cours-ovr-ul">
        <li class="drc-blcr" style='list-style:none; text-align:left;'>
          <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
            <a href="https://www.analytixlabs.co.in/data-science-specialization-course" class="ct-lg-linkUpdate ct-font-link-16" target="_blank">
              Data Science Certification Course
            </a>
          </p>
        </li>
        <li class="blcr" style='list-style:none; text-align:left;'>
          <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
            <a href="https://www.analytixlabs.co.in/artificial-intelligence-certification-courses-online" class="ct-lg-linkUpdate ct-font-link-16" target="_blank">
              Full-Stack Applied AI Course
            </a>
          </p>
        </li>
        <li class="drc-blcr" style='list-style:none; text-align:left;'>
          <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
            <a href="https://www.analytixlabs.co.in/business-analytics-data-science-course" class="ct-lg-linkUpdate ct-font-link-16" target="_blank">
              Business Analytics Certification Course
            </a>
          </p>
        </li>
        <li class="blcr" style='list-style:none; text-align:left;'>
          <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
            <a href="https://www.analytixlabs.co.in/pg-in-data-analytics" class="ct-lg-linkUpdate ct-font-link-16" target="_blank">
              Data Analytics Course
            </a>
          </p>
        </li>
      </ul>
      `,
    CourseWhoShouldDos: []
  },
  jobRolesAndSkills: {
    keyDescription:
      "This course empowers managers and leaders with technical expertise and strategic acumen. By mastering technical and soft skills, you will learn to navigate the AI landscape and drive business transformation.",
    jobRoles: [
      {
        jobRoleID: 21,
        JobRole: {
          jobRoleID: 21,
          name: "AI Engineer"
        }
      },
      {
        jobRoleID: 22,
        JobRole: {
          jobRoleID: 22,
          name: "Machine Learning Engineer"
        }
      },
      {
        jobRoleID: 23,
        JobRole: {
          jobRoleID: 23,
          name: "Robotics Specialist"
        }
      },
      {
        jobRoleID: 24,
        JobRole: {
          jobRoleID: 24,
          name: "Computer Vision Engineer"
        }
      },
      {
        jobRoleID: 25,
        JobRole: {
          jobRoleID: 25,
          name: "Data Scientist"
        }
      },
      {
        jobRoleID: 26,
        JobRole: {
          jobRoleID: 26,
          name: "AI-ML Specialist"
        }
      },
      {
        jobRoleID: 27,
        JobRole: {
          jobRoleID: 27,
          name: "Data Science Consultant"
        }
      },
      {
        jobRoleID: 28,
        JobRole: {
          jobRoleID: 28,
          name: "Analytics Consultant"
        }
      }
    ],
    keySkills: [
      {
        keySkillID: 115,
        KeySkill: {
          keySkillID: 115,
          name: "Large-Scale Analytics on Cloud",
          image: null
        }
      },
      {
        keySkillID: 116,
        KeySkill: {
          keySkillID: 116,
          name: "Predictive Modelling using Neural Network",
          image: null
        }
      },
      {
        keySkillID: 117,
        KeySkill: {
          keySkillID: 117,
          name: "Image Processing Using Deep Learning Techniques",
          image: null
        }
      },
      {
        keySkillID: 118,
        KeySkill: {
          keySkillID: 118,
          name: "Natural Language Processing & Generation (Chatbots)",
          image: null
        }
      },
      {
        keySkillID: 119,
        KeySkill: {
          keySkillID: 119,
          name: "Deep Learning Modelling for AI Applications",
          image: null
        }
      },
      {
        keySkillID: 120,
        KeySkill: {
          keySkillID: 120,
          name: "Data Science Methods",
          image: null
        }
      },
      {
        keySkillID: 121,
        KeySkill: {
          keySkillID: 121,
          name: "Supervised Learning Models",
          image: null
        }
      },
      {
        keySkillID: 122,
        KeySkill: {
          keySkillID: 122,
          name: "Unsupervised Learning Models",
          image: null
        }
      }
    ],
    keySkillsHTMLTable: `
    <div class='counrse-contn'>
        <p class="counrse-contn-1 ct-sm-left mt-2 mb-3">
          This curriculum positions you for leadership roles that demand a strategic, data-driven approach. You'll be well-prepared for positions like:
        </p>
        <ul class="cours-ovr-ul">
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Strategy Lead: 
                </span>
              </b>
              Develop and implement AI strategies across the organization.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Project Manager: 
                </span>
              </b>
              Oversee AI initiatives from conception to deployment.
            </p>
          </li>

          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Head of Data and Analytics: 
                </span>
              </b>
              Lead data-driven decision-making and AI initiatives.
            </p>
          </li>

          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Digital Transformation Officer: 
                </span>
              </b>
              Drive business transformation leveraging AI and technology.
            </p>
          </li>

          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Business Unit Head: 
                </span>
              </b>
              Lead business units with a focus on AI-driven innovation.
            </p>
          </li>

          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Chief AI Officer (CAIO): 
                </span>
              </b>
              Oversee an organization's AI strategy and implementation.
            </p>
          </li>

          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Ethics Officer: 
                </span>
              </b>
              Ensure ethical and responsible use of AI in the organization.
            </p>
          </li>

          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Product Manager: 
                </span>
              </b>
              Look into developing and launching AI-powered products and services.
            </p>
          </li>

        </ul>
        </div>

    `,
    keySkillsHTML:`
     <div class='counrse-contn'>
        <p class="counrse-contn-1 ct-sm-left mt-2 mb-3">
          This curriculum positions you for leadership roles that demand a strategic, data-driven approach. You'll be well-prepared for positions like:
        </p>
        <ul class="cours-ovr-ul ct-cours-ovr-ul">
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Strategy Lead: 
                </span>
              </b>
              Develop and implement AI strategies across the organization.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Project Manager: 
                </span>
              </b>
              Oversee AI initiatives from conception to deployment.
            </p>
          </li>

          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Head of Data and Analytics: 
                </span>
              </b>
              Lead data-driven decision-making and AI initiatives.
            </p>
          </li>

          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Digital Transformation Officer: 
                </span>
              </b>
              Drive business transformation leveraging AI and technology.
            </p>
          </li>

          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Business Unit Head: 
                </span>
              </b>
              Lead business units with a focus on AI-driven innovation.
            </p>
          </li>

          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Chief AI Officer (CAIO): 
                </span>
              </b>
              Oversee an organization's AI strategy and implementation.
            </p>
          </li>

          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Ethics Officer: 
                </span>
              </b>
              Ensure ethical and responsible use of AI in the organization.
            </p>
          </li>

          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Product Manager: 
                </span>
              </b>
              Look into developing and launching AI-powered products and services.
            </p>
          </li>

        </ul>
    </div>
    `,
    jobRolesAndSkillsHTML:`
     <div class='counrse-contn'>

      <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
        This course empowers managers and leaders with technical expertise and strategic acumen. By mastering technical and soft skills, you will learn to navigate the AI landscape and drive business transformation.
      </p>
      <h3 style='color: #013368 ;'>Technical Skills</h3>
        <ul class="cours-ovr-ul ct-cours-ovr-ul">
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Fundamentals: 
                </span>
              </b>
              Understanding of AI concepts, types, and applications.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Data Science: 
                </span>
              </b>
              Proficiency in data collection, processing, and analysis.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Tools: 
                </span>
              </b>
              Familiarity with AI software, platforms, and cloud services.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Model Development: 
                </span>
              </b>
              Knowledge of AI model frameworks and development processes.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Engineering: 
                </span>
              </b>
              Understanding DevOps, MLOps, and DataOps for AI implementation.
            </p>
          </li>

        </ul>
      

      <h3 style='color: #013368 ;'>Soft Skills</h3>

      <ul class="cours-ovr-ul ct-cours-ovr-ul">
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Strategic Thinking: 
                </span>
              </b>
              Ability to develop AI strategies aligned with business goals.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Leadership: 
                </span>
              </b>
              Skills in building and managing AI teams and centers of excellence.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Problem Solving: 
                </span>
              </b>
              Capacity to identify and solve complex business problems using AI.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Decision Making: 
                </span>
              </b>
              Ability to make data-driven decisions and evaluate AI initiatives.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Communication: 
                </span>
              </b>
              Effective communication of AI concepts and value to stakeholders.
            </p>
          </li>
      </ul>
    </div>
    `,
    allScreenSizeSameKeySkill : true,
    
  },
  deliveryFormatRecord: [
    {
      learningModeID: 1,
      LearningMode: {
        learningModeID: 1,
        name: "Classroom & Bootcamps",
        image: "image/learningMode/img-1.png",
        description: "Experiential learning with in-person mentorship!\r\n\r\n"
      }
    },
    {
      learningModeID: 2,
      LearningMode: {
        learningModeID: 2,
        name: "Classroom with ILT Blended",
        image: "image/learningMode/img-2.png",
        description:
          "Learn from the best with unrestricted support, wherever you are!\r\n\r\n"
      }
    },
    {
      learningModeID: 3,
      LearningMode: {
        learningModeID: 3,
        name: "Blended eLearning",
        image: "image/learningMode/img-3.png",
        description: "Learn at your pace with on-demand lectures & Support!"
      }
    }
  ],
  courseCertificationRecord: {
    CourseCertifications: [
      {
        courseID: 73,
        certificationID: 231,
        Certification: {
          certificationID: 231,
          name:
            "AnalytixLabs certifications are highly regarded in the industry due to our extensive domain expertise. As India’s leading Data Science institute, we must maintain the integrity of our certification process.",
          description: null,
          image: null,
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-09-26T18:26:07.000Z",
          updatedAt: "2023-10-14T08:40:30.000Z"
        }
      },
      {
        courseID: 74,
        certificationID: 232,
        Certification: {
          certificationID: 232,
          name:
            "Certification will be granted only upon meeting specified requirements within the course timelines, which include case studies, multiple-choice questions (MCQs), vivas, etc. Each candidate will have two attempts to pass each assessment.",
          description: null,
          image: null,
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-09-26T18:26:07.000Z",
          updatedAt: "2023-10-14T08:40:30.000Z"
        }
      },
      {
        courseID: 75,
        certificationID: 233,
        Certification: {
          certificationID: 233,
          name:
            "We aim to provide trainees with essential hands-on experience to prepare them for industry challenges.",
          description: null,
          image: null,
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-09-26T18:26:07.000Z",
          updatedAt: "2023-10-14T08:40:30.000Z"
        }
      },
      {
        courseID: 76,
        certificationID: 234,
        Certification: {
          certificationID: 234,
          name:
            "Certification must be secured within one year of course registration.",
          description: null,
          image: null,
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-09-26T18:26:07.000Z",
          updatedAt: "2023-10-14T08:40:30.000Z"
        }
      }
    ],
    CourseCertificationImages: [
      {
        courseID: 73,
        certificationImage:
          "image/certificateImage/AI_for_Managers_and_Leaders_240827.jpg"
      },
      // {
      //   courseID: 73,
      //   certificationImage:
      //     "image/certificateImage/Advanced_Certification_in_Full_Stack_Applied_AI_1.jpg"
      // }
    ]
  },
  feesRecord: [
    // {
    //   extraText: "(50% Pay after placement)",
    //   courseLearningModeID: 4199,
    //   courseID: 73,
    //   learningModeID: 1,
    //   offerName: null,
    //   offerDescription: null,
    //   offerImage: null,
    //   amountINR: 30000,
    //   amountUSD: 950,
    //   isInstallment: false,
    //   amountFirstInstallmentINR: null,
    //   amountSecondInstallmentINR: null,
    //   amountFirstInstallmentUSD: null,
    //   amountSecondInstallmentUSD: null,
    //   discountType: 1,
    //   isRecommended: false,
    //   discountAmount: 0,
    //   discountStartDate: null,
    //   discountEndDate: null,
    //   sortOrder: 0,
    //   isActive: true,
    //   isDeleted: false,
    //   createdBy: null,
    //   updatedBy: null,
    //   createdAt: "2023-10-31T06:49:55.000Z",
    //   updatedAt: "2023-10-31T06:49:55.000Z",
    //   LearningMode: {
    //     name: "Classroom & Bootcamps"
    //   }
    // },
    {
      extraText: "(50% Pay after placement)",
      courseLearningModeID: 4197,
      courseID: 73,
      learningModeID: 2,
      offerName: null,
      offerDescription: null,
      offerImage: null,
      amountINR: 30000,
      amountUSD: 950,
      isInstallment: false,
      amountFirstInstallmentINR: null,
      amountSecondInstallmentINR: null,
      amountFirstInstallmentUSD: null,
      amountSecondInstallmentUSD: null,
      discountType: 1,
      isRecommended: false,
      discountAmount: 0,
      discountStartDate: null,
      discountEndDate: null,
      sortOrder: 0,
      isActive: true,
      isDeleted: false,
      createdBy: null,
      updatedBy: null,
      createdAt: "2023-10-31T06:49:55.000Z",
      updatedAt: "2023-10-31T06:49:55.000Z",
      LearningMode: {
        name: "Classroom with ILT Blended"
      }
    },
    // {
    //   extraText: "(50% Pay after placement)",
    //   courseLearningModeID: 4198,
    //   courseID: 73,
    //   learningModeID: 3,
    //   offerName: null,
    //   offerDescription: null,
    //   offerImage: null,
    //   amountINR: 30000,
    //   amountUSD: 800,
    //   isInstallment: false,
    //   amountFirstInstallmentINR: null,
    //   amountSecondInstallmentINR: null,
    //   amountFirstInstallmentUSD: null,
    //   amountSecondInstallmentUSD: null,
    //   discountType: 1,
    //   isRecommended: false,
    //   discountAmount: 0,
    //   discountStartDate: null,
    //   discountEndDate: null,
    //   sortOrder: 0,
    //   isActive: true,
    //   isDeleted: false,
    //   createdBy: null,
    //   updatedBy: null,
    //   createdAt: "2023-10-31T06:49:55.000Z",
    //   updatedAt: "2023-10-31T06:49:55.000Z",
    //   LearningMode: {
    //     name: "Blended eLearning"
    //   }
    // }
  ],
  downloadBrochureResult: {
    courseID: 73,
    name: "Artificial Intelligence Engineering",
    brochureFile:
      "image/brochure/AnalytixLabs - AI Engineering Specialization-1626437470670.pdf",
      demoBrochureHTML: `
      <p class="counrse-contn-1 ct-sm-left" ><b>Uncertain about the best course?</b> Experience our teaching methodology firsthand with a free demo account. Gain exclusive access to discounted specialization tracks and job-oriented programs curated by industry experts.</p>
      `,
  },
  courseFaqRecord: {
    faqRecord: [
      {
        FaqID: 1016,
        description: `<p>Yes, the course will cover technical aspects of AI, but to a certain extent.</p><p>
          While the primary focus is on the strategic and business applications of AI, the curriculum includes modules that touch upon:
          </p>
          <ul class='pg-lg-ul-r-20'>
            <li><p class="counrse-contn-1 ct-sm-left"><b>AI fundamentals:</b> Different types of AI, how AI works, and its capabilities.</p>
            </li>
            <li><p class="counrse-contn-1 ct-sm-left"><b>AI tools and platforms:</b> Exposure to various software tools used in AI development.</p></li>
            <li><p class="counrse-contn-1 ct-sm-left"><b>Data science:</b> Understanding the role of data in AI and basic data science techniques.</p></li>
            <li><p class="counrse-contn-1 ct-sm-left"><b>AI model frameworks:</b> Overview of popular AI model frameworks.</p></li>
          </ul>
          <p>However, the course is <b>not designed</b> to provide in-depth technical training in AI programming or algorithm development. It is more focused on equipping managers and leaders with the knowledge to understand, evaluate, and leverage AI for business advantage.</p>

          `,
        title: "Will the course cover the technical aspects of AI?",
        sortOrder: 0,
        Course: {
          faqDescription: "FAQs - Frequently Asked Questions"
        }
      },
      {
        FaqID: 1018,
        description: `<p>The course provides a strong foundation for becoming an AI manager. It focuses on AI's strategic and business aspects, which are crucial for this role. However, you'll likely need to complement your learning with practical experience and additional skills to transition into an AI management position.</p>
        <p>
        Here's a potential path:
        </p>
          <ul class='pg-lg-ul-r-20'>
            <li><p class="counrse-contn-1 ct-sm-left">Gain Practical Experience</p>
            </li>
            <li><p class="counrse-contn-1 ct-sm-left">Develop Leadership Skills</p></li>
            <li><p class="counrse-contn-1 ct-sm-left">Build a Strong Network</p></li>
            <li><p class="counrse-contn-1 ct-sm-left">Deepen Your Technical Understanding</p></li>
            <li><p class="counrse-contn-1 ct-sm-left">Showcase Your Expertise</p></li>
          </ul>
          <p>
          <b>Remember:</b> Becoming an AI manager is a journey that requires continuous learning and adaptation. The course provides a valuable starting point, but practical experience and leadership development are equally important.
          </p>
          `,
        title: "How do I become an AI Manager?",
        sortOrder: 1,
        Course: {
          faqDescription: "FAQs - Frequently Asked Questions"
        }
      },
      {
        FaqID: 1019,
        description: `<p>Here are some potential job roles you could explore after completing the AI for Managers and Leaders course:</p>
          <ul class='pg-lg-ul-r-20'>
            <li><p class="counrse-contn-1 ct-sm-left"><b>AI Strategy Consultant:</b> Leverage your understanding of AI to advise organizations on developing and implementing AI strategies.</p>
            </li>
            <li><p class="counrse-contn-1 ct-sm-left"><b>AI Project Manager:</b> Oversee AI projects from inception to completion, ensuring alignment with business objectives.</p></li>
            <li><p class="counrse-contn-1 ct-sm-left"><b>Digital Transformation Manager:</b> Drive digital transformation initiatives within an organization, leveraging AI as a key enabler.</p></li>
            <li><p class="counrse-contn-1 ct-sm-left"><b>Business Intelligence Analyst:</b> Utilize AI to analyze data and provide insights to inform business decisions.</p></li>
            <li><p class="counrse-contn-1 ct-sm-left"><b>Data Analytics Manager:</b> Lead data analytics teams and projects, incorporating AI technologies.</p></li>
            <li><p class="counrse-contn-1 ct-sm-left"><b>AI Ethics Officer:</b> Ensure responsible and ethical use of AI within an organization.</p></li>
          </ul>
          <p>Remember, these roles often require additional experience and certifications. However, the course provides a solid foundation for pursuing these career paths.</p>
          `,
        title:
          "What job roles can I pursue after completing this course?",
        sortOrder: 1,
        Course: {
          faqDescription: "FAQs - Frequently Asked Questions"
        }
      },
      {
        FaqID: 1020,
        description: `<p>Here are some courses you can consider taking after completing the AI for Managers and Leaders course:</p>
        <ul class='pg-lg-ul-r-20'>
            <li><p class="counrse-contn-1 ct-sm-left"><b>Business Analytics Course</b> (475 hours, 6 months): This course will provide a strong data analysis and visualization foundation, essential skills for any AI manager.</p></li>
            <li><p class="counrse-contn-1 ct-sm-left"><b>Data Visualization & Analytics</b> (120 hours, 12 classes): This shorter course can help you develop the skills to communicate AI initiatives' results to stakeholders effectively.</p></li>
        </ul>
        <p>
          Our other ongoing courses, such as the Data Science Course, Full Stack Applied AI Course, and Python Machine Learning Course, are more technical and may be more suitable for those who want to become AI engineers or data scientists.</p><p>
          If you want to focus on the business side of AI, then the Business Analytics Course or Data Visualization & Analytics course would be a good option. </p><p>
          Talk to our learning expert for assistance choosing the right course based on your interests, background, location, professional experience, and budget.

        </p>
        
        `,
        title:
          "What courses can I do after completing this course?",
        sortOrder: 1,
        Course: {
          faqDescription: "FAQs - Frequently Asked Questions"
        }
      },
      {
        FaqID: 1021,
        description: `<p><b>NO.</b> Coding is optional for the AI for Managers and Leaders course. The curriculum equips managers and leaders with a strategic understanding of AI rather than diving deep into technical implementation. </p><p>
        While the course touches on AI tools and platforms, the emphasis is on understanding how AI can solve business problems, drive innovation, and create value. </p><p>
        You will learn to think critically about AI's potential, develop AI strategies, and lead AI initiatives without writing code. The goal is to empower managers and leaders in making informed decisions about AI investments and effectively collaborate with technical teams.
          </p>
          `,
        title: "Do I need to know coding for this course?",
        sortOrder: 1,
        Course: {
          faqDescription: "FAQs - Frequently Asked Questions"
        }
      },
      {
        FaqID: 1022,
        description: `<p>Market studies show a need for more skilled AI managers capable of steering evolving AI initiatives. Our artificial intelligence for managers course is uniquely positioned to bridge this gap and is one of the best AI courses for managers and leaders.</p>
        <ul class='pg-lg-ul-r-20'>
            <li><p class="counrse-contn-1 ct-sm-left">The course emphasizes practical application. Focusing on problem-solving, case studies, and industry-specific examples ensures learners can directly apply course knowledge to real-world challenges. This is crucial for Indian businesses facing rapid changes.</p></li>
            <li><p class="counrse-contn-1 ct-sm-left">It focuses on AI strategy and leadership and is particularly relevant to the Indian context. With increasing competition, Indian organizations need leaders who can navigate the complexities of AI, build AI-driven cultures, and achieve sustainable competitive advantage. This course empowers managers to take on such roles.</p></li>
        </u>
        
        `,
        title:
          "Which AI course is best for managers?",
        sortOrder: 1,
        Course: {
          faqDescription: "FAQs - Frequently Asked Questions"
        }
      }
    ],
    courseID: 73,
    description: "FAQs - Frequently Asked Questions"
  },
  successStories: [
    {
      userReviewsId: 102,
      courseID: 73,
      reviewText:
        "I am ineffable to express my journey through AnalytixLabs, Gurgaon .  Since we all know the faculties are the backbone of any institute to make it reach to world class level and AnalytixLabs has very experienced faculties who not only teach you but also supervise you in your further academic career . The institute has best infrastructure in terms of study material , faculties , classrooms etc . Student will get chance to work on live projects . You will get excellent training in analytic field . The whole training period is just wonderful . To be precise , AnalytixLabs is one of the INDIA's best analytics  institute .",
      User: {
        userID: 107,
        email: "gmohit280@gmail.com",
        Student: {
          userID: 107,
          studentID: 87,
          name: "Mohit Jain",
          profileImage: "image/studentImage/Mohit_Jain.jpg",
          designation: "Data Scientist",
          organization: "J.P. Morgan Chase & Co.",
          organizationImage: null,
          linkedinUrl: "https://in.linkedin.com/pub/mohit-jain/3a/b83/973"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 92,
      courseID: 73,
      reviewText:
        "I went for a combo program @ AnalytixLabs - Business Analytics (SAS+BA) + Big Data Analytics (R+Hadoop). The program was superbly well structured and faculty had great command on their subject. While I had opted for classroom training, but the online facility came in as a boon and I attended all classes from home without feeling any difference. The assignments for each module ensured that we worked hands on and gained quality training. The practical examples and Q&amp;A ensured the classes were not just interactive but also lively. I had a great experience @ Analytixlabs and would recommend everyone who is looking for professional training in analytics.",
      User: {
        userID: 97,
        email: "deepika.goel@hotmail.com",
        Student: {
          userID: 97,
          studentID: 77,
          name: "Deepika Goel, VP - Genpact Bangalore",
          profileImage: "image/studentImage/Deepika_Goel.jpg",
          designation: "Director",
          organization: "Marsh & McLennan Companies",
          organizationImage: null,
          linkedinUrl: "https://www.linkedin.com/in/deepikagoel9811347436/"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 138,
      courseID: 73,
      reviewText:
        "The rapid pace at which the institutes are mushrooming all over India and Facebook newsfeed being inundated with thousands of options for analytics training, there are only handful of training institutes or rather say only couple of places where they ???prepare??? you to foray into rapidly growing analytics vertical. I have been working into legacy systems for past 5 years and was quite apprehensive if I will ever break into data science successfully. While I was working on my options of training institutes, a brief ???counseling??? by Mouli sir and Sumeet sir obviated the further process of evaluating my options. And I can safely say that settling in for AnalytixLabs was a safe and wise decision. The reason they have been bellwether among all training institutes is due to the fact that they have been emphasizing more on algorithms and underlying mathematical and statistical concepts which is bedrock for this track and not just trammeling their trainees with list of syntax of statistical tools. Fortunately with proper guidance and encouragement from faculty, I managed to switch over to analytics from traditional IT background. BIG thanks!",
      User: {
        userID: 143,
        email: "sumitasthana14@gmail.com ",
        Student: {
          userID: 143,
          studentID: 123,
          name: "Sumit Asthana",
          profileImage: "image/studentImage/2d50efc.jpg",
          designation: "Assistant Manager",
          organization: "Senior Data Scientist",
          organizationImage: null,
          linkedinUrl: "https://in.linkedin.com/in/thatsumit"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 165,
      courseID: 73,
      reviewText:
        "First of all Big Thanks to Analytix Labs. Its an Awesome institute to the one who wants to shine up his/her career in Data Science &amp; Analytics. As the name suggest's, its not just a learning institute but also a play ground to play with Case study based modules crafted by experts based on real life business scenarios. Though I discontinued from the Sessions due to personal reasons, The Team helped me like an AnalytiX Labs Alumni when I came back to Institute after two long years. The esteemed help from the Mentors varied from providing Mock Exams, Doubts Clarification sessions, Case Studies, Resume Building and Mock Interview Sessions. I am really grateful to the Analytix Labs Team for providing all the help in an need hour. The extended support and Mock exams helped me to achieve Base SAS Certification (BP064209V9) with 97%. I'll definitely highly recommend this institute to everyone without hesitation.",
      User: {
        userID: 170,
        email: "viswanadhsogana@gmail.com",
        Student: {
          userID: 170,
          studentID: 150,
          name: "Viswanadh Sogana",
          profileImage: "image/studentImage/Sogna.jpg",
          designation: "Accenture",
          organization: "Senior Analyst",
          organizationImage: null,
          linkedinUrl: "https://in.linkedin.com/in/viswanadhsogana"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 130,
      courseID: 73,
      reviewText:
        "AnalytixLabs stands out from the crowd of vast analytics courses mainly because of its practical and conceptual approach. The 'intent' and the 'content' was way too awesome, to say the least. Excellent course, brilliant co-founders with immense knowledge on the subject matter. Just The Best Learning Centre till date.",
      User: {
        userID: 135,
        email: "atul_kapur@outlook.com",
        Student: {
          userID: 135,
          studentID: 115,
          name: "Atul Kapur",
          profileImage: "image/studentImage/Atul_Kapur1.jpg",
          designation: "",
          organization: "RBS Chennai",
          organizationImage: null,
          linkedinUrl: "linkedin.com/in/atulkap"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 112,
      courseID: 73,
      reviewText:
        "I underwent 2 trainings at AnalytixLabs: SAS+Business Analytics and Big Data Analyst. After these 2 big courses, I am confident enough to never go wrong when I say that the quality of training here is truly tough to find anywhere else. When you are really concerned about taking the next big career step, you undoubtedly need - not just a trainer, not just a mentor and not just a career counsellor. You need it all, and that's what AnalytixLabs has given me. During these times, when institutes bring forward low-experienced trainers to make money out of the &quot;Analytics&quot; buzzword, the highly-experienced faculty members at AnalytixLabs provided such an individual attention that I felt like going nowhere else for any career advice or technical help at different points of time during my stint to switch job to analytics. And yes, finally I have succeeded to get a great one for myself! Thanks AnalytixLabs! You are a big hope for all such people like me who are looking for that one hand to take us along the right career path. Keep doing this great work and I am sure you will grow high with these endeavours!!",
      User: {
        userID: 117,
        email: "neha.analytics303@gmail.com",
        Student: {
          userID: 117,
          studentID: 97,
          name: "Neha Luthra",
          profileImage: "image/studentImage/Neha_Luthra.jpg",
          designation: "Assistant Manager",
          organization: "The Smartcube Pvt ltd",
          organizationImage: null,
          linkedinUrl: "https://in.linkedin.com/in/neha-luthra-4406a497"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 105,
      courseID: 73,
      reviewText:
        "AnalytixLab is one of the best institute we can prefer, especially for big data analytics perspective which i am familiar. ALabs has dedicated, and real time experienced faculty in the industry on various analytics domains, they are also available at any time if we need any clarification on career or any technical quires. One thing which i observed on my association with them was they would complete the full syllabus irrespective of the time constraint, other institutes are bounded to their predefined time, and try to rush up the syllabus to catch up the next batches",
      User: {
        userID: 110,
        email: "sumendar@gmail.com",
        Student: {
          userID: 110,
          studentID: 90,
          name: "Sumendar Karupakala",
          profileImage: "image/studentImage/Sumendar_Karupakala.jpg",
          designation: "Certified Big Data Analyst",
          organization: "TrueBees Technologies",
          organizationImage: null,
          linkedinUrl: "https://in.linkedin.com/in/sumendar"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 117,
      courseID: 73,
      reviewText:
        "I took three months Big Data training  from Analytix labs. Before joining this course, I had so many questions and doubts that will this course be worthy enough but later I found myself lucky to join this program. All trainers are amazing and always ready to help. Live examples and Case Studies are given which helps in understanding the concept and hands on practice.Not only they help in quality in depth knowledge transfer but also tend to give right direction to your career.",
      User: {
        userID: 122,
        email: "surbhi.sultania@gmail.com",
        Student: {
          userID: 122,
          studentID: 102,
          name: "Surbhi Sultania",
          profileImage: "image/studentImage/Surbhi_Sultania.jpg",
          designation:
            "Assistant Manager - Consumer Research and Insights Specialist, Primary Quantitative Research",
          organization: "The Smartcube Pvt ltd",
          organizationImage: null,
          linkedinUrl: "https://in.linkedin.com/in/surbhi-sultania-0486b24a"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 125,
      courseID: 73,
      reviewText:
        "I sincerely appreciate the learning I got at Analytixlabs. Faculties here are very experienced and their dedication towards teaching is commendable. Sumeet and Chandra, both are very cooperative and provide all the counseling and support a person could ask for. My internship at the Analytixlabs has given me professional experience also helped me in achieving my goals. I would recommend Analytixlabs to everyone as they are THE BEST!! I wish AnalytixLabs all the best and a BIG THANK YOU.",
      User: {
        userID: 130,
        email: "kirti14dayal@gmail.com",
        Student: {
          userID: 130,
          studentID: 110,
          name: "Kirti Dayal",
          profileImage: "image/studentImage/3a0462f.jpg",
          designation: "Sr. Analyst",
          organization: "AbsolutData Analytics",
          organizationImage: null,
          linkedinUrl: "https://in.linkedin.com/in/kirtidayal"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 120,
      courseID: 73,
      reviewText:
        "AnalytixLabs is the best place for all sorts of Analytics training be it R, Hadoop, SAS, Excel or others. They have a very meticulous approach towards the curriculum development, training, peer-faculty interaction, placements as well as career guidance. I myself have had an awesome experience with AnalytixLabs and would rate them the best in the field.",
      User: {
        userID: 125,
        email: "tarun.bhavnani@gmail.com",
        Student: {
          userID: 125,
          studentID: 105,
          name: "Tarun Bhavnani",
          profileImage: "image/studentImage/default.png",
          designation: "AVP, Data Science",
          organization: "SMECorner",
          organizationImage: null,
          linkedinUrl: "https://www.linkedin.com/in/tarunbhavnani/"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    }
  ],
  careerSupport: [
    {
      courseCareerSupportID: 611,
      courseID: 73,
      careerSupportID: 615,
      sortOrder: 1,
      createdAt: "2020-09-28T09:27:47.000Z",
      updatedAt: "2020-10-01T10:34:24.000Z",
      CareerSupport: {
        careerSupportID: 615,
        title: "Profile Building",
        image: "image/course/Analytixlabs-icons-svg-36.svg",
        description:
          "Leveraging your educational background and experience, our seasoned professionals will provide personalized assistance in crafting your CV and optimizing your online profiles.",
        isActive: true,
        isDeleted: false,
        createdBy: null,
        updatedBy: null,
        createdAt: "2020-09-28T09:27:47.000Z",
        updatedAt: "2023-10-31T06:49:55.000Z"
      }
    },
    {
      courseCareerSupportID: 613,
      courseID: 73,
      careerSupportID: 617,
      sortOrder: 2,
      createdAt: "2020-09-28T09:27:47.000Z",
      updatedAt: "2020-10-01T10:34:24.000Z",
      CareerSupport: {
        careerSupportID: 617,
        title: "Interview Preparation",
        image: "image/course/Analytixlabs-icons-svg-34.svg",
        description:
          "If needed, we offer one-on-one interview preparation sessions and practice interviews to help you feel confident and prepared.",
        isActive: true,
        isDeleted: false,
        createdBy: null,
        updatedBy: null,
        createdAt: "2020-09-28T09:27:47.000Z",
        updatedAt: "2023-10-31T06:49:55.000Z"
      }
    },
    {
      courseCareerSupportID: 614,
      courseID: 73,
      careerSupportID: 618,
      sortOrder: 3,
      createdAt: "2020-09-28T09:27:47.000Z",
      updatedAt: "2020-10-01T10:34:24.000Z",
      CareerSupport: {
        careerSupportID: 618,
        title: "Job Referrals",
        image: "image/course/Analytixlabs-icons-svg-33.svg",
        description:
          "We regularly receive job openings from organizations, clients, HR consultants, and our extensive network of AnalytixLabs alumni across diverse industries, providing ample job referral opportunities.",
        isActive: true,
        isDeleted: false,
        createdBy: null,
        updatedBy: null,
        createdAt: "2020-09-28T09:27:47.000Z",
        updatedAt: "2023-10-31T06:49:55.000Z"
      }
    },
    {
      courseCareerSupportID: 612,
      courseID: 73,
      careerSupportID: 616,
      sortOrder: 4,
      createdAt: "2020-09-28T09:27:47.000Z",
      updatedAt: "2020-10-01T10:34:24.000Z",
      CareerSupport: {
        careerSupportID: 616,
        title: "Continuous Support",
        image: "image/course/Analytixlabs-icons-svg-35.svg",
        description:
          "After you complete training, we will continue offering ongoing support as long as you need it. Many of our students benefit from numerous interview calls and rewarding career prospects attributed to the skills acquired during the course.",
        isActive: true,
        isDeleted: false,
        createdBy: null,
        updatedBy: null,
        createdAt: "2020-09-28T09:27:47.000Z",
        updatedAt: "2023-10-31T06:49:55.000Z"
      }
    }
  ],
  courseDetails: {
    courseID: 73,
    courseFullName: "AI for Managers and Leaders Certification Course",
    name: "AI for Managers and Leaders Certification Course",
    slug: "artificial-intelligence-for-managers-leaders",
    shortDescription:
      "An Industry Relevant AI & ML Course to Launch Your Tech Career!",
    longDescription: `
      <p class="counrse-contn-1 ct-sm-left">
        AI is reshaping the managerial landscape. With a staggering <a href="https://www.statista.com/topics/10548/artificial-intelligence-ai-adoption-risks-and-challenges/" target="_blank">85%</a> of businesses expected to adopt AI by 2025 (source: Statista), the demand for AI-savvy leaders is soaring. Despite the steep growth curve, reports show that almost <a href="https://www.ibm.com/blog/new-ibm-study-reveals-how-ai-is-changing-work-and-what-hr-leaders-should-do-about-it/" target="_blank">77% of professionals</a> consider AI a threat to their existing job roles.</p><p class="counrse-contn-1 ct-sm-left">

        AI is slated to experience a <a href="https://www.forbes.com/advisor/in/business/ai-statistics/" target="_blank">37% annual growth between 2023 and 2030</a>. Are you ready to seize this opportunity? </p><p class="counrse-contn-1 ct-sm-left">
        Our Artificial Intelligence for Managers and Leaders certification course empowers you with the practical skills to navigate this transformative era. Gain the competitive edge and position yourself at the forefront of innovation. </p><p class="counrse-contn-1 ct-sm-left">

        The Artificial Intelligence for Managers and Leaders certification course will equip you with practical AI skills. It's high time we recognized the increasing relevance of artificial intelligence for managers and leaders in today's business landscape. This course will ensure you are well informed and equipped to lead a team ready for the future. </p><p class="counrse-contn-1 ct-sm-left">

        Our AI for Leaders and Managers course is a comprehensive program built on core AI management concepts and their diverse applications across multiple industries and domains. You will learn to put AI in the center to identify and implement business opportunities with maximum optimization. </p><p class="counrse-contn-1 ct-sm-left">

        From building agile strategies to orchestrating AI business models and improved decision-making, this course is a have-it-all for existing and aspiring leaders and managers. Learn to lead and communicate with AI and deliver results that become milestones.</p>



        <h3 class="date-ttl mb-2 ct-capitalize mt-4 ">About Artificial Intelligence for Managers and Leaders</h3>
        <p class="counrse-contn-1 ct-sm-left">Artificial Intelligence is taking center stage for managers and leaders, and how. With most professionals opting for remote or hybrid work modes, AI has become an aid for leaders and managers to optimize and streamline work that defies geographical barriers. </p><p class="counrse-contn-1 ct-sm-left">

        As a manager or leader, your job will need you to step up for your team, know what they are doing, enable them to perform better, handle roadblocks head-on, and be the face of your team for stakeholders and profit shareholders. </p><p class="counrse-contn-1 ct-sm-left">

        As a result, you must have managerial skills that are agile, smart, and relevant. Our artificial intelligence for managers and leaders course accommodates all these requirements. Leverage AI to be the leader everyone wants to work with.
        </p>



        <h3 class="date-ttl mb-2 ct-capitalize mt-4 ct-sm-left">Course Objectives: </h3>
        <ul class="cours-ovr-ul">
          <li class="drc-blcr" style='list-style:none; text-align:left;'>
          <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">Understand global AI trends, their implications, and applications, and identify the growing opportunities for AI in business. </p>
          </li>
          <li class="blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">Leverage AI for strategic decision-making. Drive business decisions that will reshape enterprise strategy and the impact of AI on CXO roles.</p>
          </li>
          <li class="drc-blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">Develop AI-driven methodologies and structured frameworks to solve complex business problems across industries and business functions. </p>
          </li>
          <li class="blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">Learn the best AI practices, how to implement them, and trace them back to understand their impact. Acquire AI for development, implementation, and management, and conduct AI maturity assessment to create milestones along the journey.</p>
          </li>
        </ul>


        <h3 class="date-ttl mb-2 ct-capitalize mt-4 ct-sm-left">Why Learn with AnalytixLabs: </h3>
        <p class="counrse-contn-1 ct-sm-left">The Indian Government's initiatives, such as Digital India and Make in India, emphasize technology adoption, and AI is a key component. This AI management course will prepare you to lead this digital journey.</p><p class="counrse-contn-1 ct-sm-left">
        Our dedicated AI for Managers course caters to India's large and diverse business landscape. From IT giants to traditional industries, the course provides a holistic view of AI applications, enabling managers to identify opportunities in their specific domains.</p>
      `,
    hoursCount: "500",
    classCount: "46",
    classHours: "+ 55 hrs e-learning",
    projectCount: "44",
    ratingValue: 4.73,
    bestRating: 5,
    worstRating: 2,
    ratingCount: 2680,
    metaTitle: "Artificial Intelligence Courses: Job-oriented AI ML Training",
    metaDescription:
      "Launch your AI & ML career: Master in-demand skills with online and offline courses & certifications in India. Compare fees & get future-proof!",
    metaKeywords: "",
    selfStudyHoursCount: "460 (8-10 hrs/ week)",
    classCountSubline: "",
    hoursPerClassCount: "63 x 3 = 189 hrs",
    readinessProgramClassCount: "8 Weeks",
    readinessProgramSubline: "(Post Certification)",
    theme: "Default",
    isScholarship: 1,
    CourseLearningModes: {
      amountINR: 48000,
      maxAmountINR: 55000
    },
    taxPercentage: "18",
    LMSCourse: "ckeq05bmi00bgtqjl1i4l0h4e"
  },
  courseCapstoneRecord: {
    courseID: 73,
    capstoneProjects: null,
    capstoneProjectsDescription:
      "Our artificial intelligence courses in India include multiple case studies and assignments for self-study and hands-on skills, in addition to multiple case studies.",
    CourseCapstoneProjects: [
      {
        capstoneProjectID: 3070,
        CapstoneProject: {
          capstoneProjectname:
            "Image classification for inventory management (AI DL)"
        }
      },
      {
        capstoneProjectID: 3071,
        CapstoneProject: {
          capstoneProjectname:
            "Machine Language Translation (French to English & Hindi to English)"
        }
      },
      {
        capstoneProjectID: 3072,
        CapstoneProject: {
          capstoneProjectname: "Stock price forecasting (Deep Learning)"
        }
      },
      {
        capstoneProjectID: 3073,
        CapstoneProject: {
          capstoneProjectname: "Image classification project (AI DL)"
        }
      },
      {
        capstoneProjectID: 3074,
        CapstoneProject: {
          capstoneProjectname: "Credit Card fraud detection (Deep Learning)"
        }
      },
      {
        capstoneProjectID: 3075,
        CapstoneProject: {
          capstoneProjectname: "Automated image captioning (AI DL)"
        }
      },
      {
        capstoneProjectID: 3076,
        CapstoneProject: {
          capstoneProjectname: "Age detection using images (AI DL)"
        }
      },
      {
        capstoneProjectID: 3077,
        CapstoneProject: {
          capstoneProjectname: "Toxic comments classification (Deep Learning)"
        }
      },
      {
        capstoneProjectID: 3078,
        CapstoneProject: {
          capstoneProjectname:
            "Image classification & localization (Using YOLO)"
        }
      },
      {
        capstoneProjectID: 3079,
        CapstoneProject: {
          capstoneProjectname:
            "Network intrusion detection (Supervised machine learning)"
        }
      },
      {
        capstoneProjectID: 3080,
        CapstoneProject: {
          capstoneProjectname: "Online job posting analysis (Text mining & NLP)"
        }
      },
      {
        capstoneProjectID: 3081,
        CapstoneProject: {
          capstoneProjectname:
            "Banking customer cluster (Unsupervised machine learning)"
        }
      },
      {
        capstoneProjectID: 3082,
        CapstoneProject: {
          capstoneProjectname:
            "Retail chain sales forecasting (multivariate time series)"
        }
      },
      {
        capstoneProjectID: 3083,
        CapstoneProject: {
          capstoneProjectname:
            "Predicting credit card spend (regression methods)"
        }
      },
      {
        capstoneProjectID: 3084,
        CapstoneProject: {
          capstoneProjectname: "E-commerce recommendation engine (MBA)"
        }
      },
      {
        capstoneProjectID: 3085,
        CapstoneProject: {
          capstoneProjectname: "Chatbot creation (Seq2seq Deep Learning)"
        }
      },
      {
        capstoneProjectID: 3086,
        CapstoneProject: {
          capstoneProjectname: "Bank reviews analysis (Text mining & NLP)"
        }
      }
    ],
    CourseAssignments: [
      {
        courseAssignmentID: 1893,
        Assignment: {
          name: "10 short exercises on NumPy & Pandas"
        }
      },
      {
        courseAssignmentID: 1894,
        Assignment: {
          name:
            "4 case studies on Pandas for data munging, descriptive & visual analysis"
        }
      },
      {
        courseAssignmentID: 1895,
        Assignment: {
          name: "3 Basic exercises (Excel, SQL & Tableau)"
        }
      },
      {
        courseAssignmentID: 1896,
        Assignment: {
          name: "1 Assignment on basic Python"
        }
      },
      {
        courseAssignmentID: 1897,
        Assignment: {
          name: "2 exercises for statistical analysis"
        }
      }
    ]
  },
  similarCourseRecords: [
    {
      courseID: 60,
      name: "Data Science using R",
      shortDescription:
        "Best R training for industry relevant Advanced Analytics and Machine Learning skills!",
      robotImage: "image/course/thumbnail-1616501648.svg",
      courseDetailsImage: "image/course/data-science-using-R-1657623295981.png",
      slug: "data-science-r-analytics-training"
    },
    {
      courseID: 63,
      name: "Data Science using Python",
      shortDescription:
        "Best Python Data Science Course with Industry-Certified Excellence!",
      robotImage: "image/course/thumbnail-1616501891.svg",
      courseDetailsImage:
        "image/course/iitg-web-icon-logo-1714134463298-1714199689299.png",
      slug: "data-science-using-python"
    },
    {
      courseID: 64,
      name: "Business Analytics 360",
      shortDescription:
        "Dynamic Business Analytics Course with Job-Focused Certification!",
      robotImage: "image/course/dva-banners-creatives-alabs-04 copy.svg",
      courseDetailsImage: "image/course/iitg-web-icon-logo-1714199891639.png",
      slug: "business-analytics-data-science-course"
    }
  ],
  paymentKeys: {
    payumoneyKey: "qsxM7i",
    payumoneySalt: "A5v0ZQIJ",
    accountType: "nonLLP"
  },
  callUs: {
    number: "9555219007",
    country_code: "+91",
    start_time: "10:00:00",
    end_time: "19:00:00"
  },
  landingCallUs: {
    number: "9555525908",
    country_code: "+91",
    start_time: "10:00:00",
    end_time: "19:00:00"
  },
  careerPath: {
    CareerPathHtml: `
      <p class="counrse-contn-1 ct-sm-left">Career paths are dynamic and vary based on individual interests, strengths, and opportunities. </p>
      <p class="counrse-contn-1 ct-sm-left"><b>Note:</b> These career paths are not mutually exclusive. Many professionals combine elements from different paths to create their unique career trajectories. </p>
      <h3 class="date-ttl mb-2 ct-capitalize mt-4 ct-sm-left">Career Considerations: </h3>
        <ul class="cours-ovr-ul">
          <li class="drc-blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                <span style='color: #013368 ;'>
                  Industry Specialization:
                </span>
              </b> Consider focusing on a specific industry (e.g., healthcare, finance, retail) to deepen your expertise.
            </p>
          </li>
          <li class="blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                <span style='color: #013368 ;'>
                  Entrepreneurship:
                </span>
              </b> Leverage your AI knowledge to start your AI-focused venture.
            </p>
          </li>
          <li class="drc-blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                  <span style='color: #013368 ;'>
                    Academic Pursuits:
                  </span>
              </b> Pursue advanced degrees or research opportunities in AI to contribute to the field. [Explore our Executive AI in Data Science Course or opt for our Post-Graduate Programs in Data Science and Analytics].
            </p>
          </li>
        </ul>



        <h3 class="date-ttl mb-2 ct-capitalize mt-4 ct-sm-left">Career Path for an AI Specialist</h3>  
        <ul class="cours-ovr-ul">
          <li class="drc-blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                <span style='color: #013368 ;'>
                  Data Analyst/Scientist: 
                </span>
              </b>
              Begin by developing a strong foundation in data analysis and machine learning.
            </p>
          </li>
          <li class="blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                <span style='color: #013368 ;'>
                  AI Engineer: 
                </span>
              </b>
              Transition into building and deploying AI models, focusing on technical implementation.
            </p>
          </li>
          <li class="drc-blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                <span style='color: #013368 ;'>
                  AI Architect: 
                </span>
              </b>
              Design and develop complex AI systems, becoming a technical leader.
            </p>
          </li>
          <li class="blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                <span style='color: #013368 ;'>
                  Chief Technology Officer (CTO): 
                </span>
              </b>
              Lead the overall technology strategy, including AI initiatives.
            </p>
          </li>
        </ul>


        <h3 class="date-ttl mb-2 ct-capitalize mt-4 ct-sm-left">Career Path for a Business Leader</h3>  
        <ul class="cours-ovr-ul">
          <li class="drc-blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                <span style='color: #013368 ;'>
                  Manager/Director of a Business Unit: 
                </span>
              </b>
              Apply AI knowledge to optimize operations and drive growth within your department.
            </p>
          </li>
          <li class="blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                <span style='color: #013368 ;'>
                  Head of Digital Transformation: 
                </span>
              </b>
              Lead enterprise-wide digital transformation initiatives, leveraging AI as a key enabler.
            </p>
          </li>
          <li class="drc-blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                <span style='color: #013368 ;'>
                  Chief Digital Officer (CDO): 
                </span>
              </b>
              Oversee the digital strategy and operations, including AI initiatives.
            </p>
          </li>
          <li class="blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                <span style='color: #013368 ;'>
                  Chief Executive Officer (CEO): 
                </span>
              </b>
              Ultimately, the CEO will lead the entire organization, using AI to drive innovation and competitive advantage.
            </p>
          </li>
        </ul>



        <h3 class="date-ttl mb-2 ct-capitalize mt-4 ct-sm-left">Career Path for an AI Strategy Expert</h3>  
        <ul class="cours-ovr-ul">
          <li class="drc-blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                <span style='color: #013368 ;'>
                  AI Strategy Consultant: 
                </span>
              </b>
              Advise organizations on developing and implementing AI strategies.
            </p>
          </li>
          <li class="blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                <span style='color: #013368 ;'>
                  AI Strategy Manager: 
                </span>
              </b>
              Lead AI strategy development within an organization.
            </p>
          </li>
          <li class="drc-blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                <span style='color: #013368 ;'>
                  AI Program Manager: 
                </span>
              </b>
              Oversee the execution of AI initiatives, ensuring alignment with business objectives.
            </p>
          </li>
          <li class="blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                <span style='color: #013368 ;'>
                  AI Center of Excellence (CoE) Leader: 
                </span>
              </b>
              To foster innovation and knowledge sharing, build and lead an AI CoE.
            </p>
          </li>
        </ul>
    
    `
  },
  learningBenefits: {
    learningBenefitsHTML: `
    <div>
    <p class="counrse-contn-1 ct-sm-left">Engage in an intuitive and hands-on learning experience. Work on relevant projects under the guidance of industry experts. Experience our no-code approach to AI for managers course. Skip the coding or programming struggles and focus on learning to deliver results with AI. </p>
    <ol class="ct-number-ol-date">
          <li class='mb-4'>
            <h3 style='font-size:20px; color: #013368; text-align: left;'>
              Democratize AI for Strategic Decision Making
            </h3>
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4 ct-ft-weight-small">
              By understanding the core concepts of AI without the need for coding, you will become an active participant in AI-driven decision-making.
            </p>
          </li>
          <li class='mb-4'>
            <h3 style='font-size:20px; color: #013368; text-align: left;'>
              Enhance Operational Efficiency and Productivity
            </h3>
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4 ct-ft-weight-small">
              The course will equip you with the knowledge to streamline operations, automate routine tasks, and optimize resource allocation using AI tools.
            </p>
          </li>
          <li class='mb-4'>
            <h3 style='font-size:20px; color: #013368; text-align: left;'>
              Cultivate a Data-Driven Culture
            </h3>
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4 ct-ft-weight-small">
              Understanding how AI leverages data is crucial for fostering a data-driven culture. As Managers and leaders, you will learn to harness data insights to make evidence-based decisions, identify trends, and anticipate market changes.
            </p>
          </li>
          <li class='mb-4'>
            <h3 style='font-size:20px; color: #013368; text-align: left;'>
              Develop an Agile Business Mindset
            </h3>
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4 ct-ft-weight-small">
              The course will emphasize the role of AI in driving business agility. You will learn to adapt to changing market conditions, experiment with new AI-powered strategies, and create a more responsive organization.
            </p>
          </li>
          <li class='mb-4'>
            <h3 style='font-size:20px; color: #013368; text-align: left;'>
              Build a Competitive Advantage
            </h3>
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4 ct-ft-weight-small">
              Managers and leaders can position their organizations at the forefront of innovation by mastering AI. With this certification course, you will learn to identify new business opportunities, develop AI-powered products and services, and have a competitive edge in the market.
            </p>
          </li>
    </ol>
    `,
  }
};

export const AIEngineeringCoursePlacementSupprot = [
  "The Advanced Certification in Full Stack Applied AI program features comprehensive Placement Assistance, where a dedicated team of experienced professionals customizes support based on your educational background and work experience, if any.",
  "Job opportunities are sourced from various channels, including direct organizations, recruiters, and our extensive AnalytixLabs Alumni network. We are committed to providing sustained assistance for as long as you need it.",
  "While many of our students receive multiple interview calls and improved career prospects, success ultimately depends on the earnest and sufficient efforts of the candidates and the institute's support.",
  "Achieving success in this program requires candidates' dedication to completing the certification and meeting all specified requirements within the designated timeframe, including participation in the Placement Readiness Program (PRP)."
];

export const AIForManagersLeadersCareerStory = `<p class="counrse-contn-1 ct-sm-left">Numerous success stories from our program showcase remarkable achievements by graduates in their respective fields. These individuals have effectively translated their learning into tangible accomplishments, from impactful projects to securing high-level positions in leading organizations.</p>
<p class="counrse-contn-1 ct-sm-left">
The underlying theme in these success stories is the practical application of knowledge acquired through experiential learning, a core focus of our course. Experiential learning prioritizes hands-on experiences, real-world projects, and practical applications.</p>
<p class="counrse-contn-1 ct-sm-left">
Experiential Learning fosters active engagement, critical thinking, and reflection and equips learners with practical skills and real-world insights beyond conventional classroom instruction. Active participation in activities, simulations, projects, or internships enables a deep comprehension of concepts, develops advanced problem-solving abilities, and establishes a solid, applicable knowledge base.</p>
<p class="counrse-contn-1 ct-sm-left">
This approach empowers learners to absorb and engage with information, fostering a profound understanding and setting the stage for meaningful career accomplishments.
</p>`;


export const feeDetailsALEnginneringCoursesData = `AI course fees are often perceived as high, but our artificial intelligence engineering course fees are reasonable and in line with industry norms, ensuring accessibility for all. With AnalytixLabs, you can learn and master the skill set, with the course fee starting from just 60,000 INR + taxes.`;

export const AIEngineeringCoursesUpcomingBatches = `Our artificial intelligence and machine learning courses have multiple slots to select your suitable date. Choose a date and timing of your choice, and get your learning started.
`;


export const feeDetailsALForManagersLeaders = "Contrary to popular belief, our Artificial Intelligence for Managers and Leaders course offers exceptional value. Fees are competitively priced and aligned with industry standards, making AI education accessible to all."

export const feeDetailsALForManagersLeadersSecondLine = "Invest in your future with AnalytixLabs, where mastery begins at just INR 30,000 + taxes."

export const EMITextALForManagersLeaders = "INR 30,000/- plus taxes*"