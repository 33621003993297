import React, { memo, useState, useEffect } from 'react'
import { Route, withRouter, Link, useHistory } from 'react-router-dom'
import ScrollToTop from './ScrollToTop'
import HtmlHead from './HtmlHead'
import Header from 'components/Header/Header'
import { useUserContext } from 'context/UserContext'
import SearchBar from '../components/SearchBar'
import {
  checkExtraUrl,
  courseLgPagesSlug,
  courseSlugOfLgPage,
  getSlugAfterFromUrlByIndex,
  getSlugFromUrlByIndex,
  lgPages,
  selectedLgPages,
} from '../constants'
import {telusPageSlug} from 'data/courseStaticPage'
// import SearchBarMb from "../components/SearchBar/searchBarMb";

const AppRoute = memo(
  ({
    component: Component,
    layout: Layout,
    privateRoute = 'false',
    ...rest
  }) => {
    const { location } = useHistory()
    const history = useHistory()
    let [isserachbar, setIsSearchBar] = useState('')
    const [isDiploma] = useState(
      false
    )
    const { isAuthenticated } = useUserContext()
    useEffect(() => {
      let format = /[!@#$%^&*()_+/]+/
      if (format.test(getSlugFromUrlByIndex(3, true)?.charAt(0))) {
        history.push('/404')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated])

    if (privateRoute === 'true' && !isAuthenticated) {
      return (
        <ScrollToTop>
          <Layout>
            <header className="top-bg">
              <HtmlHead {...rest} />
              <div className="container">
                <Header />
              </div>
            </header>
            <section className="term-modules free_resources we_offers">
              <div className="container-3">
                <div className="row mt-5">
                  <div className="col-md-10 offset-md-1">
                    <h1 className="font-48 pt-4 pb-4">
                      <span className="yl-botbod">Thank You</span> for your
                      interest!
                    </h1>
                    <div className="thank_you">
                      <div className="row">
                        <div className="col-md-12 full_shadow_cover text-center animated slideInUp">
                          <h5 className="mb-4">
                            You have to be signed in to access this page. If you
                            have an account you can sign in{' '}
                            <Link
                              to={{
                                pathname: '/signin',
                                state: { redirectTo: location.pathname },
                              }}
                            >
                              here
                            </Link>
                            . Otherwise you can register{' '}
                            <Link to="/signup">here</Link>.
                          </h5>
                          <div className="col-md-12 mt-5">
                            <div className="arro-blk text-center">
                              <a
                                href="/"
                                className="call-usbtn ctc-btn text-center dskt-show d-inline-block px-5 m-0"
                              >
                                Return Home
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Layout>
        </ScrollToTop>
      )
    }

    let onClickSearchBar = () => {
      setIsSearchBar('showSearchclass')
    }

    const backSearchBar = () => {
      setIsSearchBar('')
    }
    return (
      <Route
        {...rest}
        render={(props) => (
          <ScrollToTop>
            <Layout>
              {
              (courseSlugOfLgPage.includes(getSlugFromUrlByIndex(3, true)) && courseLgPagesSlug.includes(getSlugFromUrlByIndex(4, true)))  ||
              lgPages.includes(getSlugFromUrlByIndex(4, true)) ||
              selectedLgPages.includes(getSlugFromUrlByIndex(4, true)) ||
              getSlugFromUrlByIndex(4, true) === 'cd' ||
              (checkExtraUrl &&
                lgPages.includes(getSlugAfterFromUrlByIndex(4, true))) ||
              selectedLgPages.includes(getSlugAfterFromUrlByIndex(2)) || telusPageSlug.includes(history.location.pathname) ? null : (
                <header className="top-bg" id="header">
                  {!rest.dynamicMeta ? <HtmlHead {...rest} /> : null}
                  <div className="container">
                    {isDiploma ? (
                      <Header onClickSearchBar={onClickSearchBar} isDiploma />
                    ) : (
                      <Header onClickSearchBar={onClickSearchBar} />
                    )}
                    {isserachbar ? (
                      window?.innerWidth && window.innerWidth < 767 ? (
                        // <SearchBarMb isserachbar={isserachbar} backSearchBar={backSearchBar} />
                        <div />
                      ) : (
                        <SearchBar
                          isserachbar={isserachbar}
                          backSearchBar={backSearchBar}
                        />
                      )
                    ) : (
                      ''
                    )}
                  </div>
                </header>
              )}
              <Component {...props} />
            </Layout>
          </ScrollToTop>
        )}
      />
    )
  },
)

export default withRouter(AppRoute)
